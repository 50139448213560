.custom-solutions
  position: relative

  &__title
    margin-bottom: 40px
    text-align: center

  &-title__line
    +screen-max(640px)
      display: block

  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 20px

    +screen-max(1280px)
      gap: 12px

    +screen-max(768px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(640px)
      grid-template-columns: repeat(1, 1fr)
      gap: 16px
      margin-bottom: 0

    .column
      margin-bottom: 0
      height: auto

  .custom-solution
    position: relative
    display: flex
    flex-direction: column
    height: 100%
    padding: 32px 20px 20px
    font-size: 14px
    border-radius: 16px
    border-top: 6px solid $theme-primary-color
    box-shadow: 0px 8px 16px 0px $box-shadow-color
    z-index: 1
    overflow: hidden

    &::before
      content: ""
      z-index: -1
      position: absolute
      top: 0
      right: 0
      width: 166px
      height: 144px
      border-radius: 166px
      background: linear-gradient(135deg, #DFF5FA 0.01%, #58BADE 100%)
      filter: blur(75px)
      transform: translate3d(0, 0, 0)

    &__name
      color: $ui-primary-color
      margin-bottom: 24px
      font-size: 28px
      font-weight: 700

      +screen-max(640px)
        margin-bottom: 16px
        font-size: 24px

    &__desc
      margin-bottom: 8px
      font-size: 16px

    &__list-title
      margin-bottom: 8px
      font-size: 16px

    &__list
      flex: 1

    &__item
      padding-left: 24px
      display: flex
      align-items: center
      margin-bottom: 8px
      position: relative
      font-size: 16px

      &:last-child
        margin-bottom: 16px

      &:before
        content: ""
        position: absolute
        left: 0
        top: 0
        bottom: 0
        width: 20px
        background: url('~check-list-ico.svg') no-repeat center
        background-size: contain
