.reviews
  padding: 56px 0

  &__list
    display: flex
    align-items: center

    +screen-max(600px)
      flex-direction: column
      gap: 50px

.review
  display: flex
  flex-direction: column
  align-items: center
  flex: 1

  &--reward
    +screen-max(600px)
      order: -1

    .review__img
      +screen-max(600px)
        max-width: 180px

  &__img
    width: 100%

    +screen-max(600px)
      max-width: 177px

  &-rating
    margin-bottom: 8px
    display: flex
    gap: 4px

    &__ico
      width: 24px
      height: 24px

      +screen-max(1024px)
        width: 13px
        height: 13px

      +screen-max(600px)
        width: 18px
        height: 18px

  &-text
    font-size: 16px
    line-height: 1.5
    text-align: center
    letter-spacing: 0.05em

    +screen-max(1024px)
      font-size: 12px

    +screen-max(600px)
      font-size: 18px

    &__grade
      font-weight: 600
      font-size: 24px
      line-height: 1

      +screen-max(1024px)
        font-size: 14px

      +screen-max(600px)
        font-size: 20px
