.img-block
  &__wrapper
    display: flex
    align-items: center
    gap: 24px 55px

    +screen-max(1023px)
      flex-direction: column

  &__content
    flex: 1

    +screen-max(1023px)
      text-align: center

  &__title
    margin-bottom: 16px

  .img-block__text
    font-size: 16px
    margin-bottom: 28px
    color: $text-tertiary-color
  
  &__img
    margin-top: 35px
    width: 100%
    flex: 1
    display: flex
    justify-content: center
    position: relative

    &--small
      flex: none
      width: auto
      max-width: 400px

      +screen-min(1200px)
        padding: 0 40px 0 80px
        max-width: 511px

  &__ico
    position: absolute
    width: 22%
    left: 10%
    top: -10%
    background: #ffffff
    border: .5px solid $border-color
    box-shadow: 0px 2px 8px 0px $box-shadow-color
    border-radius: 20%

