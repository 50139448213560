.roles
  position: relative

  &__toggles
    position: relative
    display: flex
    padding-bottom: 2px
    overflow: hidden

    +screen-max(1023px)
      padding: 0 64px 2px
      display: block
      white-space: nowrap
      overflow-x: auto
      text-align: center

      &::-webkit-scrollbar
        display: none

      &-wrap
        width: calc(100% + 128px)
        margin-left: -64px
        overflow: hidden

    +screen-max(700px)
      padding: 0 16px 2px

      &-wrap
        width: calc(100% + 32px)
        margin-left: -16px
        overflow: hidden

  &-toggle
    flex: 1
    position: relative
    margin-right: 16px

    &:last-child
      margin-right: 0

      .roles-toggle__content
        &::before
          right: 0

    +screen-max(1023px)
      display: inline-block
      width: 190px
      min-width: 190px

    &--large
      +screen-max(1023px)
        width: 240px
        min-width: 240px


    &__input
      position: absolute
      top: 0
      left: 0
      width: 0
      height: 0
      clip: rect(0,0,0,0)

      &:checked
        + .roles-toggle__content
          &::after
            content: ""
            position: absolute
            bottom: -1px
            left: 0
            right: 0
            height: 3px
            background-color: $ui-primary-color
            border-radius: 5px

          &:hover
            background-color: #ffffff

          .roles-toggle__icon
            filter: invert(55%) sepia(61%) saturate(5873%) hue-rotate(167deg) brightness(96%) contrast(101%)

            &--red
              filter: invert(48%) sepia(15%) saturate(2804%) hue-rotate(321deg) brightness(90%) contrast(92%)

            &--green
              filter: invert(53%) sepia(5%) saturate(3598%) hue-rotate(77deg) brightness(110%) contrast(90%)

            &--cyan
              filter: invert(44%) sepia(22%) saturate(2933%) hue-rotate(158deg) brightness(101%) contrast(101%)

            &--purple
              filter: invert(54%) sepia(76%) saturate(2193%) hue-rotate(232deg) brightness(81%) contrast(85%)

          .roles-toggle__title
            color: $text-color

    &__content
      position: relative
      padding: 20px 12px
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      gap: 4px
      cursor: pointer

      &:hover
        background-color: $theme-light-color

      &::before
        content: ""
        position: absolute
        bottom: 0
        left: 0
        right: -16px
        height: 1px
        background-color: $border-color
    
    &__icon
      width: 28px
      filter: invert(68%) sepia(9%) saturate(600%) hue-rotate(152deg) brightness(88%) contrast(88%)

    &__title
      color: $text-disabled-color
      text-align: center
      font-size: 18px
      font-weight: 600
      line-height: 1.3

  &-tab
    padding: 16px 0
    display: flex
    gap: 24px 80px
    justify-content: space-between
    position: absolute
    opacity: 0
    z-index: -1

    +screen-max(1023px)
      flex-direction: column-reverse
      align-items: center

    &--center
      align-items: center

    &--dashboards
      flex-direction: row-reverse

      +screen-max(1023px)
        flex-direction: column-reverse

      .roles-tab__image
        max-width: 580px
        width: 50%

        +screen-max(1023px)
          padding: 0
          width: 100%
          max-width: 100%

        picture
          display: flex
          width: 100%

        img
          width: 100%

      .roles-tab__title
        margin-bottom: 16px
        font-size: 24px

      .roles-tab__text
        margin-bottom: 16px
        font-size: 14px
        color: $text-tertiary-color

      .roles-tab__link
        margin-top: 0
    
    &.active
      position: static
      opacity: 1
      z-index: 1

    &__content
      flex: 1
      padding-top: 60px
      padding-left: 32px

      +screen-max(1023px)
        padding-top: 0
        padding-left: 16px

      +screen-max(700px)
        padding-left: 0

    &__title
      margin-bottom: 16px
      
      +screen-max(700px)
        margin-bottom: 8px

    &__text
      margin-bottom: 24px

      +screen-max(700px)
        margin-bottom: 16px
        font-size: 16px

    &__list
      display: flex
      flex-direction: column
      gap: 16px

      &--small
        .roles-tab__item
          padding-left: 32px
          
          &::before
            width: 24px
            height: 24px

    &__item
      position: relative
      padding-left: 48px
      font-size: 16px
      line-height: 1.5
      
      &::before
        content: ""
        position: absolute
        top: 50%
        left: 0
        width: 40px
        height: 40px
        background-image: url('~icons/check-icon.svg')
        background-size: contain
        background-repeat: no-repeat
        transform: translateY(-50%)

      strong
        font-weight: 600

    &__link
      margin-top: 35px

    &__image
      max-width: 488px
      width: 100%
      margin-left: 0px
      border-radius: 6px
      overflow: hidden

      +screen-max(1023px)
        max-width: 421px
        padding: 0 10%

