.arrows-controller
  display: flex
  gap: 8px
  align-items: center
  flex-shrink: 0

  &__item
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    border-radius: 12px
    background-color: $ui-primary-color
    cursor: pointer
    transition: .2s background-color ease-in-out
    user-select: none

    &:hover
      background-color: $theme-primary-color

    &.swiper-button-disabled
      background: #ffffff
      border: 1px solid $border-color
      cursor: default
      svg
        fill: $text-color

    svg
      fill: #ffffff
      transition: .2s stroke ease-in-out
