\:root
  --ss-primary-color:#5897fb
  --ss-bg-color:#ffffff
  --ss-font-color:#4d4d4d
  --ss-font-placeholder-color:#8d8d8d
  --ss-disabled-color:#dcdee2
  --ss-border-color: $border-color
  --ss-highlight-color:transparent
  --ss-success-color:#00b755
  --ss-error-color:#dc3545
  --ss-focus-color: transparent
  --ss-main-height:48px
  --ss-content-height:300px
  --ss-spacing-l:7px
  --ss-spacing-m:5px
  --ss-spacing-s:3px
  --ss-animation-timing:0s
  --ss-border-radius:12px

@keyframes ss-valueIn
  0%
    transform: scale(0)
    opacity: 0

  100%
    transform: scale(1)
    opacity: 1

@keyframes ss-valueOut
  0%
    transform: scale(1)
    opacity: 1

  100%
    transform: scale(0)
    opacity: 0

.ss-hide
  display: none !important

.ss-main
  display: flex
  flex-direction: row
  position: relative
  user-select: none
  color: $text-color
  min-height: var(--ss-main-height)
  width: 100%
  padding: 10px 16px
  cursor: pointer
  border: 1px solid $border-color
  border-radius: var(--ss-border-radius)
  background-color: var(--ss-bg-color)
  outline: 0
  box-sizing: border-box
  transition: background-color var(--ss-animation-timing)
  overflow: hidden

  &:focus
    box-shadow: 0 0 5px var(--ss-focus-color)

  &.ss-disabled
    background-color: var(--ss-disabled-color)
    cursor: not-allowed

    .ss-values
      .ss-disabled
        color: var(--ss-font-color)

      .ss-value .ss-value-delete
        cursor: not-allowed

  &.ss-open-above
    border-color: $ui-primary-color

    .ss-arrow
      transform: rotate(180deg)

  &.ss-open-below
    border-color: $ui-primary-color

    .ss-arrow
      transform: rotate(180deg)

  .ss-values
    display: inline-flex
    flex-wrap: wrap
    gap: var(--ss-spacing-m)
    flex: 1 1 100%

    .ss-placeholder
      display: flex
      padding: var(--ss-spacing-s) var(--ss-spacing-m) var(--ss-spacing-s) var(--ss-spacing-m)
      margin: auto 0px auto 0px
      line-height: 1em
      align-items: center
      width: 100%
      color: var(--ss-font-placeholder-color)
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    .ss-max
      display: flex
      user-select: none
      align-items: center
      width: fit-content
      font-size: 12px
      color: var(--ss-bg-color)
      line-height: 1
      padding: var(--ss-spacing-s) var(--ss-spacing-m)
      background-color: var(--ss-primary-color)
      border-radius: var(--ss-border-radius)

    .ss-single
      display: flex
      margin: auto 0px auto var(--ss-spacing-s)

    .ss-value
      display: flex
      user-select: none
      align-items: center
      width: fit-content
      animation-name: ss-valueIn
      animation-duration: var(--ss-animation-timing)
      animation-timing-function: ease-out
      animation-fill-mode: both
      padding: 0px 4px 0px 8px
      min-height: 26px
      background: $theme-light-color
      border: 1px solid $border-color
      border-radius: 8px


      &.ss-value-out
        animation-name: ss-valueOut
        animation-duration: var(--ss-animation-timing)
        animation-timing-function: ease-out

      .ss-value-text
        font-size: 16px
        color: $text-color
        line-height: 1

      .ss-value-delete
        display: flex
        align-items: center
        height: 8px
        width: 8px
        padding: var(--ss-spacing-s) var(--ss-spacing-m)
        cursor: pointer
        box-sizing: content-box

        svg
          height: 8px
          width: 8px

          path
            fill: none
            stroke: $text-color
            stroke-width: 18
            stroke-linecap: round
            stroke-linejoin: round

  .ss-deselect
    flex: 0 1 auto
    display: none
    align-items: center
    justify-content: center
    width: fit-content
    height: auto
    margin: 0 var(--ss-spacing-m) 0 var(--ss-spacing-m)

    svg
      width: 8px
      height: 8px

      path
        fill: none
        stroke: var(--ss-font-color)
        stroke-width: 20
        stroke-linecap: round
        stroke-linejoin: round

  .ss-arrow
    position: relative
    flex: 0 1 auto
    display: flex
    align-items: center
    justify-content: flex-end
    width: 24px
    height: auto
    filter: invert(6%) sepia(54%) saturate(2668%) hue-rotate(227deg) brightness(85%) contrast(109%)
    margin: 0
    transition: 0.25s ease-in-out
    background: url('~icons/chevron-down.svg')
    background-repeat: no-repeat
    background-position: center

    path
      display: none

.ss-content
  position: absolute
  display: flex
  height: auto
  flex-direction: column
  width: auto
  max-height: var(--ss-content-height)
  box-sizing: border-box
  border: solid 1px $ui-primary-color
  background-color: var(--ss-bg-color)
  transition: transform var(--ss-animation-timing), opacity var(--ss-animation-timing)
  opacity: 0
  transform: scaleY(0)
  transform-origin: center top
  overflow: hidden
  z-index: 10000

  &.ss-main--single
    .ss-search
      display: none

    .ss-list
      .ss-search
        padding: 8px 20px

      .ss-option
        padding: 8px 20px 

        &::before,
        &::after
          display: none

      .ss-optgroup 
        .ss-option
          padding-left: 36px

  &.ss-relative
    position: relative
    height: 100%

  &.ss-open-above
    flex-direction: column-reverse
    opacity: 1
    transform: scaleY(1) translateY(-4px)
    transform-origin: center bottom
    border-radius: var(--ss-border-radius)

  &.ss-open-below
    opacity: 1
    transform: scaleY(1) translateY(4px)
    transform-origin: center top
    border-radius: var(--ss-border-radius)

  .ss-search
    flex: 0 1 auto
    display: flex
    flex-direction: row
    padding: var(--ss-spacing-l) var(--ss-spacing-l) var(--ss-spacing-m) var(--ss-spacing-l)

    input
      display: inline-flex
      font-size: inherit
      line-height: inherit
      flex: 1 1 auto
      width: 100%
      min-width: 0px
      background-color: var(--ss-bg-color)
      outline: 0
      text-align: left
      box-sizing: border-box
      border: 1px solid $border-color
      border-radius: 12px
      height: 38px
      padding: 0 16px
      flex: 1
      width: 100%
      
      &:hover
        border-color: $border-color

      &::placeholder
        font-weight: normal
        color: $theme-grey-color

      &:focus
        border-color: $border-color
        outline: none

      &::-ms-clear 
      &::-ms-reveal 
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration 
        display: none 
        width: 0 
        height: 0 

    .ss-addable
      display: inline-flex
      justify-content: center
      align-items: center
      cursor: pointer
      flex: 0 0 auto
      height: auto
      margin: 0 0 0 var(--ss-spacing-m)
      border: 1px solid $border-color
      border-radius: var(--ss-border-radius)

      svg
        display: flex
        align-items: center
        justify-content: flex-end
        flex: 0 1 auto
        width: 12px
        height: 12px
        margin: auto var(--ss-spacing-m) auto var(--ss-spacing-m)

        path
          fill: none
          stroke: var(--ss-font-color)
          stroke-width: 18
          stroke-linecap: round
          stroke-linejoin: round

  .ss-list
    flex: 1 1 auto
    height: auto
    overflow-x: hidden
    overflow-y: auto

    .ss-error
      color: var(--ss-error-color)
      padding: var(--ss-spacing-l)

    .ss-searching
      color: var(--ss-font-color)
      padding: var(--ss-spacing-l)

    .ss-optgroup
      &.ss-close .ss-option
        display: none !important

      .ss-optgroup-label
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 8px 20px

        .ss-optgroup-label-text
          flex: 1 1 auto
          font-weight: bold
          color: $text-color

        &:has(.ss-arrow)
          cursor: pointer

        .ss-optgroup-actions
          flex: 0 1 auto
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center
          gap: var(--ss-spacing-m)

          .ss-selectall
            flex: 0 0 auto
            display: flex
            flex-direction: row
            cursor: pointer

            &:hover
              opacity: .5

            &.ss-selected svg path
              stroke: var(--ss-error-color)

            span
              flex: 0 1 auto
              display: flex
              align-items: center
              justify-content: center
              font-size: 60%
              text-align: center
              padding: 0 var(--ss-spacing-s) 0 0

            svg
              flex: 0 1 auto
              width: 13px
              height: 13px

              path
                fill: none
                stroke: var(--ss-success-color)
                stroke-linecap: round
                stroke-linejoin: round

              &:first-child
                stroke-width: 5

              &:last-child
                stroke-width: 11

          .ss-closable
            flex: 0 1 auto
            display: flex
            flex-direction: row
            cursor: pointer

            .ss-arrow
              flex: 1 1 auto
              width: 10px
              height: 10px

              path
                fill: none
                stroke: var(--ss-font-color)
                stroke-width: 18
                stroke-linecap: round
                stroke-linejoin: round
                transition-timing-function: ease-out
                transition: var(--ss-animation-timing)

      .ss-option
        padding-left: 68px
        
        &::before
          left: 36px

        &::after
          left: 41px

    .ss-option
      position: relative
      display: flex
      padding: 8px 16px 8px 42px
      color: $text-color
      cursor: pointer
      user-select: none

      &:hover
        background-color: $theme-light-color

      &::before
        content: ""
        position: absolute
        top: 50%
        left: 14px
        width: 20px
        min-width: 20px
        height: 20px
        border: 1px solid $ui-primary-color
        border-radius: 4px
        cursor: pointer
        box-sizing: border-box
        transform: translateY(-50%)
        transition: all 0.2s ease-in

      &::after
        content: ""
        position: absolute
        top: 50%
        left: 19px
        width: 10px
        height: 8px
        background-image: url('~checkbox-icon.svg')
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(189deg) brightness(103%) contrast(103%)
        background-size: contain
        transform: translateY(-50%) rotate(-145deg)
        transition: all .2s ease-in
        opacity: 0

      &.ss-highlighted, &:not(.ss-disabled).ss-selected
        &::before
          background-color: $ui-primary-color
      
        &::after
          transform: translateY(-50%) rotate(0)
          opacity: 1
        

      &.ss-disabled
        cursor: not-allowed
        background-color: var(--ss-disabled-color)

        &:hover
          color: var(--ss-font-color)

      .ss-search-highlight
        background-color: var(--ss-highlight-color)

/*# sourceMappingURL=slimselect.css.map
