.mobile-off
  @media screen and (max-width: 480px)
    display: none !important

.desktop-off
  @media screen and (min-width: 480px)
    display: none !important

.mobile-on
  @media screen and (min-width: 481px)
    display: none !important

.phablet-on
  @media screen and (min-width: 769px)
    display: none !important

.text-right
  text-align: right

.text-left
  text-align: left

.float-right
  float: right

.float-left
  float: left

.text-center
  text-align: center

.hidden
  display: none

.visually-hidden
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0 0 0 0)
  border: 0

.block-center
  display: block
  margin: 0 auto

.width-limiter
  max-width: 65%
  +screen-max(420px)
    max-width: none

.full-width
  width: 100% !important

.center-vertically
  display: flex
  align-items: center

.nowrap
  white-space: nowrap

.ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.list-style-inside
  list-style: inside

$icon-sizes: 20, 32

@each $size in $icon-sizes
  .icon#{$size}
    height: #{$size}px
    width: #{$size}px
