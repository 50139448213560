.integrations-promo
  &.section
    padding: 72px 0
  
  &-title
    max-width: 616px
    margin: 0 auto 20px
    text-align: center

  &-text
    max-width: 616px
    margin: 0 auto
    text-align: center

  &-list
    display: flex
    max-width: 600px
    margin: 32px auto

  &-datasource
    display: flex
    align-items: center
    padding: 10px
    margin-right: 24px
    background-color: #fff
    border-radius: 5px
    box-shadow: 0 2px 8px #e1ebf1

    &:last-child
      margin-right: 0

  &-link
    font-size: 12px
    font-weight: 600
    text-align: center
    text-decoration: none
    text-transform: uppercase

.integrations-promo
  +screen-max(1024px)
    &.section
      padding: 40px 0

  +screen-max(768px)
    &-title
      font-size: 24px

    &-text
      font-size: 16px

  +screen-max(550px)
    &-list
      flex-wrap: wrap
      justify-content: center
      margin: 16px auto

    &-datasource
      padding: 12px
      margin: 8px

      img
        width: 56px
        height: 56px
