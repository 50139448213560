.integrations-page
  background-color: #fff
  
  .features-list
    padding-top: 68px
    padding-bottom: 0

    &.light
      background-color: #fff

    .features-item
      &:first-child
        padding: 0

    .features-item img
      width: 100%

    .features-item__text
      margin-bottom: 32px

  .features-list-benefits
    display: grid
    grid-template-columns: 1fr
    row-gap: 16px

  .features-list-benefits li
    position: relative
    padding-left: 24px

    &::before
      position: absolute
      top: 3px
      left: 0
      width: 16px
      height: 16px
      content: ''
      background-image: url('~integrations/blue-checkmark.svg')
      background-repeat: no-repeat
      background-size: 100% 100%
  
  .features-list-border
    margin: 0
    border: 0
    border-bottom: 1px solid $theme-primary-color
    opacity: 0.2

  +screen-min(768px)
    .features-list
      padding-top: 124px

      .features-item
        padding: 0

        &:first-child
          padding-bottom: 124px

        &:nth-child(odd)
          flex-direction: row

        &:nth-child(even)
          flex-direction: row-reverse

      .features-item__text
        margin-bottom: 48px

    .features-list-benefits
      grid-template-columns: 1fr 1fr
      row-gap: 24px

    .features-list-border
      padding-top: 124px
