.popular-integrations
  .container
    overflow: hidden

  &__title
    margin-bottom: 40px
    text-align: center

  &__pagination
    display: none

    +screen-max(1023px)
      display: flex

  &__row
    display: grid
    grid-template-columns: repeat(4, 1fr)

    +screen-max(1023px)
      gap: 0

.popular-integration
  text-decoration: none
  border: 1px solid $border-color
  display: flex
  flex-direction: column
  padding: 24px
  gap: 12px
  background: #FFFFFF
  box-shadow: 0px 2px 8px $box-shadow-color
  border-radius: 24px

  &__wrapper
    padding: 12px

    +screen-max(1023px)
      padding: 8px

  &:hover
    border: 1px solid $ui-primary-color

  &__from-to
    display: flex
    align-items: center
    gap: 8px

  &__icon
    display: flex
    border: 1px solid $border-color
    border-radius: 12px
    width: 64px
    min-width: 64px
    height: 64px
    background: #FFFFFF

  &__arrow
    filter: invert(39%) sepia(95%) saturate(1246%) hue-rotate(164deg) brightness(93%) contrast(102%)

  &__text
    color: $text-color
    font-size: 18px
    font-weight: 600
    line-height: 1.3
