.case-card
  position: relative
  height: 100%
  display: block
  color: $text-color
  background-color: #fff
  text-decoration: none
  border-radius: 5px
  box-shadow: 0px 2px 8px rgba(40, 40, 40, 0.14)
  overflow: hidden

  &::before
    position: absolute
    content: ''
    top: 0
    left: 0
    right: 0
    bottom: 0 
    border: 1px solid
    border-color: transparent
    border-radius: 5px
    transition: .2s border-color ease-in-out

  &:hover
    &::before
      border-color: $theme-primary-color
    .case-card__text
      color: $text-color

  &__header
    display: flex
    justify-content: center
    align-items: center
    min-height: 168px
    +screen-max(768px)
      min-height: 96px

    &-logo
      max-width: 256px
      max-height: 87px
      +screen-max(768px)
        max-width: 144px
        max-height: 43px
        height: 43px

      &--m-large
        +screen-max(768px)
          max-height: 50px
          height: 50px

      &-i
        width: 100%
        height: 100%
        object-fit: cover

        +screen-max(768px)
          object-fit: contain
  
  &__content
    padding: 24px 24px 9px 24px

  &__title
    margin-bottom: 12px
    transition: .2s color ease-in-out
