.dashboard
  padding: 16px
  border: 1px solid $border-color
  box-shadow: 0px 2px 8px $box-shadow-color
  border-radius: 24px
  text-decoration: none
  color: $text-color
  display: flex
  flex-direction: column
  transition: all 0.2s ease-in-out

  &:nth-child(4n - 3),
  &--cyan
    .dashboard__img-box
      background: linear-gradient(135deg, #DFF5FA 0.01%, #58BADE 100%)

    .dashboard__img
      box-shadow: -4px 0px 16px 0px $box-shadow-color
 
  &:nth-child(4n - 2),
  &--green
    .dashboard__img-box
      background: linear-gradient(315deg, #93D49D 0%, #D5EFD9 100%)

    .dashboard__img
      box-shadow: -4px 0px 16px 0px rgba(100, 178, 111, 0.12)

  &:nth-child(4n - 1),
  &--purple
    .dashboard__img-box
      background: linear-gradient(315deg, #B97FDC 0%, #E9D7F3 100%)

    .dashboard__img
      box-shadow: -4px 0px 16px 0px rgba(105, 49, 139, 0.12)

  &:nth-child(4n),
  &--red
    .dashboard__img-box
      background: linear-gradient(315deg, #E49292 0%, #EFD1CC 100%)

    .dashboard__img
      box-shadow: -4px 0px 16px 0px rgba(105, 49, 139, 0.12)

  +screen-max(1024px)
    flex: 0 100%

    // .dashboard__text-wrap
    //   height: auto

    //   .dashboard__text
    //     display: block
    //     position: static
    //     padding: 0
    //     border: none

    //     &:hover
    //       padding: 0
    //       border: none
    //       min-height: auto

  +screen-max(550px)
    padding: 16px

  &:hover
    color: inherit
    border-color: $ui-primary-color

    .dashboard__text
      border-color: $ui-primary-color

  .dashboard__img-box
    position: relative
    overflow: hidden
    margin-bottom: 24px
    border-radius: 16px
    padding-bottom: 51%

    +screen-max(1024px)
      padding-bottom: 43%

    +screen-max(550px)
      padding-bottom: 61%
      margin-bottom: 16px

    .dashboard__img
      position: absolute
      top: 24px
      left: 24px
      width: 100%
      border-radius: 5px 5px 0 0

      +screen-max(1024px)
        top: auto
        bottom: 0
        left: 50%
        transform: translateX(-50%)
        width: 78%

      +screen-max(550px)
        width: 150%
        max-width: 150%
        top: 24px
        left: 24px
        bottom: auto
        transform: none

  &--hidden
    display: none

  &__content
    flex: 1
    display: flex
    flex-direction: column

  &__title
    margin-bottom: 8px
    font-size: 16px
    font-weight: 600

  .dashboard__text
    margin-bottom: 0
    font-size: 16px
    color: $text-secondary-color
      
    &-wrap
      margin-bottom: 24px
      position: relative

      +screen-min(1023px)
        height: 77px

      +screen-max(550px)
        margin-bottom: 16px

    +screen-min(1023px)
      display: -webkit-box
      -webkit-line-clamp: 3
      -webkit-box-orient: vertical
      overflow: hidden
      text-overflow: ellipsis

    +screen-max(550px)
      font-size: 14px

  &-sources
    flex: 1
    display: flex
    align-items: flex-end
    gap: 24px

    +screen-max(550px)
      gap: 12px

    &__title
      font-size: 16px
      font-weight: 500

      +screen-max(550px)
        font-weight: 400
        font-size: 14px
        color: $text-secondary-color

    &__list
      display: flex
      flex-wrap: wrap
      gap: 0

    &__ico
      width: 24px

