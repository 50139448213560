$green-plan-color: #5baa68;
$orange-plan-color: #f5a623;
$red-plan-color: #de5946;
$blue-plan-color: #00749c;
$blue-vivid-plan-color: #17B3E5;
$lilac-plan-color: #68328a;
$dark-plan-color: #605E5D;

$billing-plan-colors: (
  "free": $green-plan-color,
  "lite_2023": $orange-plan-color,
  "squad": $blue-plan-color,
  "professional": $red-plan-color,
  "business": $lilac-plan-color,
  "free_2021": $green-plan-color,
  "professional_2021": $red-plan-color,
  "squad_2021": $blue-plan-color,
  "business_2021": $lilac-plan-color,
  "free_2024": $dark-plan-color,
  "personal_2022": $red-plan-color,
  "squad_2022": $green-plan-color,
  "business_2022": $blue-vivid-plan-color,
  "personal_v2_2022": $orange-plan-color,
  "starter_2022": $red-plan-color,
  "squad_v2_2022": $green-plan-color,
  "business_v2_2022": $blue-vivid-plan-color,
  "custom": $lilac-plan-color
)
