.highlights
  &--title-center
    .highlights__title,
    .highlights__text
      text-align: center

  &--3-columns
    .highlights__row
      display: grid
      grid-template-columns: repeat(3, 1fr)

      +screen-max(1024px)
        grid-template-columns: repeat(2, 1fr)

      +screen-max(768px)
        grid-template-columns: repeat(1, 1fr)

  &__title
    color: $accent-blue-dark-color

  &__text
    margin-top: 16px
    margin-bottom: 0

    +screen-max(550px)
      margin-top: 8px

  &__row
    margin-top: 40px
    display: flex
    flex-wrap: wrap
    gap: 24px

    +screen-max(550px)
      margin-top: 24px
      gap: 16px
    
  .highlight
    padding: 24px
    display: flex
    flex: 1
    flex-direction: column
    border-left: 5px solid
    background: #FFFFFF
    box-shadow: 0px 2px 8px $box-shadow-color
    border-radius: 24px

    +screen-max(550px)
      padding: 16px

    &--green
      border-color: $accent-green-color

      .highlight__num
        color: $accent-green-color

    &--red
      border-color: $accent-red-color

      .highlight__num
        color: $accent-red-color

    &--cyan
      border-color: $accent-cyan-color

      .highlight__num
        color: $accent-cyan-color

    &--purple
      border-color: $accent-purple-color

      .highlight__num
        color: $accent-purple-color

    +screen-max(1024px)
      flex: 0 1 calc(50% - 12px)

    +screen-max(550px)
      flex: 0 1 100%

    &__num
      font-size: 32px
      font-weight: 500
      line-height: 1
      margin-bottom: 12px
      font-family: 'Archivo', sans-serif

      +screen-max(550px)
        font-size: 24px

      span
        font-size: 72px

        +screen-max(550px)
          font-size: 56px

    .highlight__title
      font-weight: 700

    .highlight__text
      margin-bottom: 0
      font-size: 16px
      line-height: 1.75
      color: $text-tertiary-color
