.data-filter-section
  border-radius: 0px 0px 120px 120px
  background: $theme-light-color

  &--dashboards
    .data-filter
      &::before
        display: none

    .dashboards__filter
      background: $theme-light-color

    .dashboards-search__input
      border-radius: 12px

    .dashboard
      background: #ffffff

    .data-filter-section__title
      color: $text-color
    
    .dashboards__list
      padding-left: 0
      padding-right: 0

    .search
      +screen-max(800px)
        display: none

    &.light
      .dashboards__filter
        background: #ffffff

  &.light
    border-radius: 0

  +screen-max(550px)
    border-radius: 0px 0px 32px 32px

  &__title
    color: $accent-blue-dark-color
    font-size: 40px
    margin-bottom: 0
    text-align: center

    +screen-max(550px)
      font-size: 28px

  &__description
    margin-bottom: 0
    margin-top: 20px
    font-size: 24px
    text-align: center

    +screen-max(550px)
      margin-top: 12px
      font-size: 18px

  &-survey
    margin: 72px auto 0
    text-align: center

    +screen-max(550px)
      margin: 64px auto 0

    &__title
      margin-bottom: 16px
      font-size: 24px

      +screen-max(550px)
        margin-bottom: 12px
        font-size: 18px

    &__description
      margin-bottom: 16px
      font-size: 16px

  &__content
    margin: 32px auto 0
    width: 100%
    text-align: center
    display: flex
    align-items: flex-start
    gap: 20px

    &.data-filter-section__content--disabled-filter
      .data-filter-search
        max-width: 1163px
        margin-left: auto
        margin-top: auto
        padding-right: 68px

        +screen-max(1024px)
          padding-right: 0

    &.is-empty
      .data-list
        +screen-min(650px)
          grid-template-columns: 1fr

      .data-empty
        display: block
        width: 100%
        padding: 15px 12px
        margin: 0 auto
        text-align: center
        background-color: #fff
        border-radius: 5px
        border: 1px solid rgba(135, 156, 165, 0.24)

        +screen-min(1023px)
          padding: 21px 15px

        &__title
          margin-bottom: 0
          font-size: 16px

  &__box
    flex: 1

  &__mobile
    display: none

    +screen-max(1024px)
      margin-top: 12px
      display: flex
      flex-direction: column
      gap: 12px

  .data-filter-search
    position: relative
    width: 100%
    margin: 0 auto
    padding-bottom: 12px

    +screen-min(478px)
      padding-bottom: 16px

  .data-empty
    display: none

  .data-item
    &.hidden
      display: none

  .data-action
    margin-top: 16px

  