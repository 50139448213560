.links-carousel
  background-color: $bg-blue-color
  &--light
    background-color: #ffffff

    .container
      &::before,
      &::after
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
    
  .container
    overflow: hidden
    position: relative
    
    &::before,
    &::after
      content: ''
      position: absolute
      width: 112px
      top: 48px
      bottom: 0
      z-index: 2
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,  $bg-blue-color 100%)
     
      +screen-max(1023px)
        display: none

    &::before
      left: -1px
      transform: scale(-1, -1)

    &::after
      right: -1px

  &__header
    margin-bottom: 40px
    text-align: center

    &--controls
      display: flex
      justify-content: space-between
      align-items: center

      +screen-max(1023px)
        .links-carousel__controls
          display: none

    &--link
      display: flex
      justify-content: space-between
      align-items: center

      +screen-max(1023px)
        .link-arrow
          display: none

  &__row
    +screen-max(1023px)
      display: flex
      gap: 0

  &__pagination
    &.swiper-pagination-dots
      display: none

      +screen-max(1023px)
        display: flex

.link-carousel
  display: flex
  flex-direction: column
  padding: 16px
  gap: 16px
  background: #FFFFFF
  border: 1px solid $border-color
  box-shadow: 0px 2px 8px $box-shadow-color
  border-radius: 24px
  text-decoration: none

  &:hover
    border: 1px solid $ui-primary-color

  &__img
    width: 100%
    border-radius: 16px
    overflow: hidden
    display: flex

    img
      width: 100%

  &__tag
    font-weight: 600
    font-size: 16px
    line-height: 1.75
    color: $ui-primary-color

  &__title
    font-weight: 700
    font-size: 24px
    line-height: 1.5
    color: $text-color
    flex: 1
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis

  &-author
    padding-top: 8px
    display: flex
    align-items: center
    gap: 8px

    &__img
      width: 24px
      min-width: 24px
      height: 24px
      border-radius: 100%
      overflow: hidden

    &__name
      font-weight: 600
      font-size: 16px
      line-height: 1.5
      color: $text-secondary-color

