.button
  padding: 0 70px
  font-size: 15px
  line-height: 54px
  color: #fff
  background-color: $theme-primary-color
  border: 0
  +button
  +ripple-effect

  &:hover
    color: #fff
    background-color: darken($theme-primary-color, 5%)

  &-light
    background-color: $default-light-color
    color: $theme-primary-color

    &:hover
      background-color:$theme-light-color
      color: $theme-primary-color

  &-narrow
    padding-left: 18px
    padding-right: 18px

  &.small
    padding: 5px 20px
    font-size: 12px
    line-height: 20px
    text-align: center

  &.medium
    padding: 0 30px
    font-size: 12px
    line-height: 40px

  &-fixed-medium
    min-width: 200px
    padding-left: 18px
    padding-right: 18px

  &.full-width
    width: 100%

  &.success
    background-color: $accent-green-color

    &:hover,
    &:focus
      background-color: darken($accent-green-color, 5%)
      
  &.danger
    background-color: $accent-red-color
    color: #fff

    &:hover,
    &:focus
      color: #fff

  &-outlined
    color: $theme-primary-color
    background-color: $theme-light-color
    border: 1px solid currentColor

    &:hover
      color: darken($theme-primary-color, 5%)
      background-color: $bg-blue-color

    &.success
      color: $accent-green-color
      background-color: transparent
      &:hover,
      &:focus
        color: $accent-green-color
        background-color: transparent

    &.danger
      &:hover,
      &:focus
        color: $accent-red-color
        background-color: transparent

    &.primary
      color: $theme-primary-color

      &:hover,
      &:focus
        background-color: transparent

  &-login
    display: flex
    align-items: center
    justify-content: flex-start
    width: 100%
    max-width: 338px
    padding: 0 16px
    font-size: 16px
    color: $theme-primary-color
    text-decoration: none
    letter-spacing: 0.04em
    background-color: #fff
    box-shadow: 10px 10px 65px rgba(5, 117, 158, 0.15)

    .logo
      margin-right: 14px

    &:hover,
    &:focus
      color: $theme-primary-color
      background-color: $bg-blue-color

  &-link
    display: inline
    padding: 0
    width: auto
    color: $theme-primary-color
    font-weight: inherit
    font-size: inherit
    text-decoration: underline

    &:hover
      color: lighten($theme-primary-color, 5%)

  +screen-max(550px)
    max-width: none
    width: 100%
    height: 40px
    margin: 0
    line-height: 40px
    font-size: 12px
