.cards-list
  &__title
    display: flex
    gap: 32px
    justify-content: center
    align-items: center
    flex-wrap: nowrap
    position: relative
    margin-bottom: 40px
    text-align: center

    &::before,
    &::after
      content: ""
      height: 1px
      width: 100%
      max-width: 80px
      background-color: $border-color

      +screen-max(768px)
        display: none

  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 24px

    +screen-max(1024px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(768px)
      grid-template-columns: repeat(1, 1fr)

  .cards-list-card
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 24px
    background: $bg-blue-color
    border: 1px solid $border-color
    border-radius: 24px
    text-decoration: none

    &:hover
      border-color: $ui-primary-color

    &__title
      margin-bottom: 12px
      color: $text-color

    &__text
      margin-bottom: 0
      font-weight: 400
      font-size: 16px
      line-height: 1.75
      color: $text-tertiary-color
