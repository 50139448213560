.case-list

  &_two-columns
    .case-list
      &__wrap
        +screen-min(1024px)
          grid-template-columns: repeat(2, 1fr)

  &__title
    margin-bottom: 40px
    font-size: 36px
    text-align: center
    +screen-max(768px)
      margin-bottom: 16px

  &__wrap
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 20px
    +screen-max(1024px)
      grid-template-columns: repeat(2, 1fr)
      gap: 24px
    +screen-max(768px)
      grid-template-columns: 1fr
      gap: 10px

