.category-dashboards
  .container
    &::before
      content: ""
      display: block
      position: relative
      top: -44px
      width: 100%
      height: 1px
      background: $border-default-color
      visibility: visible

  &__header
    display: flex
    flex-direction: column
    align-items: center

  &__icon
    margin-bottom: 16px
    width: 56px
    min-width: 56px
    height: 56px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 14px
    overflow: hidden

    img
      display: flex
      width: 28px
      height: 28px

  &__title
    margin-bottom: 16px
    text-align: center

  .category-dashboards__text
    margin-bottom: 24px
    color: $text-tertiary-color
    font-size: 16px
    line-height: 1.5
    text-align: center

  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 24px

    +screen-max(768px)
      grid-template-columns: repeat(1, 1fr)

    .dashboard
      flex: 1
      padding: 8px

    .dashboard__title
      font-size: 16px

    .dashboard-sources__ico
      width: 24px

  &__action
    margin-top: 36px
    display: flex
    justify-content: center
