.swiper-pagination-dots
  margin-top: 24px
  display: flex
  justify-content: center
  position: static

.swiper-pagination-bullet
  margin: 0
  width: 12px
  min-width: 12px
  height: 12px
  display: flex
  background: $bg-blue-color
  border: 1px solid $border-color
  border-radius: 100%
  opacity: 1

  &-active
    background: $ui-primary-color
    border-color: $ui-primary-color
