.testimonials
  position: relative
  overflow: hidden
  background: #ffffff

  .container
    overflow: hidden
    position: relative

    &::before,
    &::after
      content: ''
      position: absolute
      width: 112px
      top: 48px
      bottom: 0
      z-index: 2
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
     
      +screen-max(550px)
        display: none

    &::before
      left: -1px
      transform: scale(-1, -1)

    &::after
      right: -1px

.testimonials-carousel
  position: relative

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 40px
    position: relative
    z-index: 3

  &__controls
    padding-left: 20px

    +screen-max(768px)
      display: none

  .testimonials-carousel-card
    user-select: none

    &__text
      position: relative
      padding: 24px 32px
      margin-bottom: 28px
      font-size: 16px
      background: $theme-light-color
      border: 1px solid rgba(18, 186, 247, 0.12)
      border-radius: 24px

      &::before
        content: ""
        position: absolute
        width: 63px
        height: 20px
        left: 0
        bottom: 0
        background-image: url('~quote-triangle.svg')
        z-index: 2
        transform: translateY(100%)
        background-size: contain
        background-repeat: no-repeat

      b
        font-weight: 600

    &__quote
      position: absolute
      top: 11px
      left: 15px
      font-size: 40px
      line-height: 1
      font-weight: 600
      color: $blue-vivid-plan-color

    &__footer
      padding: 0 8px
      display: flex
      align-items: center

    &__image
      width: 54px
      min-width: 54px
      height: 54px
      border-radius: 50%
      overflow: hidden
      border: 1px solid rgba(18, 186, 247, 0.12)
      &-i
        width: 100%
        height: 100%
        object-fit: cover

    &__content
      padding: 0 0 0 8px

    &__name
      margin-bottom: 0
      font-size: 16px
      line-height: 1.5

      b
        font-weight: 600

    &__highlighted
      display: block
      margin-top: 16px
      font-size: 16px
      color: $accent-green-color
      
      b 
        font-size: 24px
        font-weight: 600

    &__link
      font-size: 16px
