.case-studies-section
  padding: 48px 0

  &.light
    background-color: #fff

  &.no-padding
    padding: 0

  +screen-max(600px)
    padding: 24px 0

.case-studies-heading
  background-color: $theme-light-color
  padding-top: 8px
  padding-bottom: 48px

  &-breadcrumbs
    &-link
      text-decoration: none

  &-content
    display: flex
    flex-direction: column

    +screen-min(1024px)
      gap: 32px
      flex-direction: row

    &-text
      flex: 3
      display: flex
      flex-direction: column

      h1
        padding-top: 1.5rem
        text-align: center

        +screen-min(1024px)
          padding-top: 6rem
          text-align: left

    &-key-items
      display: none
      flex-direction: row
      justify-content: space-between
      gap: 10px
      border-top: 1px solid rgba(135, 156, 165, 0.3)
      margin-top: 50px

      +screen-min(1024px)
        display: flex

    &-key-item
      padding: 15px 0
      min-width: 25%

    &-logo
      flex: 2
      display: flex
      justify-content: center
      align-items: center
      padding: 2rem

      &-company
        aspect-ratio: 16/10
        display: flex
        justify-content: center
        align-items: center
        border-radius: 10px
        width: 100%
        min-width: 320px

        +screen-min(1024px)
          box-shadow: 26px 20px 3px 3px rgba(0, 0, 0, 0.05)

        img
          width: 75%

  .case-studies-key-block
    position: relative

    +screen-min(1024px)
      display: none

    &-items
      margin: -40px auto -160px
      width: 90%
      display: flex
      flex-direction: column
      gap: 10px
      background: #FFF
      border-radius: 5px
      box-shadow: 0px 4px 16px rgba(2, 122, 164, 0.1)
      top: -4rem
      padding: 0.75rem

      +screen-min(768px)
        width: 95%

  .case-studies-key-title
    margin-bottom: 4px

    &.industry
      color: $theme-primary-color

    &.what-we-did
      color: $accent-green-color

    &.outcomes
      color: #69318B

.case-studies-hero
  padding: 0 0 64px
  background-color: $default-light-color
  +screen-max(768px)
    padding: 0 0 40px

  &-wrapper
    position: relative
    padding: 76px 0 135px
    +screen-max(768px)
      padding: 60px 0 80px
      margin-bottom: 40px
    +screen-max(390px)
      padding: 103px 0
      background: $theme-light-color

  &-bg
    position: absolute
    content: ''
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-position: center
    background-size: contain
    +screen-max(390px)
      display: none
  
  &-content
    position: relative
    margin: 0 auto
    max-width: 800px
    text-align: center
  
  .key-metrics-wrapper
    margin-top: -65px
    +screen-max(768px)
      margin-top: 0

    &::before
      background-color: $default-light-color
  
.case-studies-list
  padding: 0 0 64px
  background-color: $default-light-color
  +screen-max(768px)
    padding: 0 0 40px
  +screen-max(390px)
    padding: 0 0 60px

.case-studies-carousel
  position: relative
  padding: 65px 0 70px
  overflow: hidden
  +screen-max(768px)
    padding: 65px 0 55px
  +screen-max(390px)
    padding: 40px 0 55px

  &-bg
    position: absolute
    content: ''
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-position: left top
    background-size: cover
    background-image: url('~integrations/case-new-integrations.svg')

    +screen-min(1600px)
      background-size: contain
      background-position: right top

    &--dark
      background-image: url('~integrations/case-new-carousel.svg')

      +screen-min(1600px)
        background-size: cover

    +screen-max(320px)
      display: none
  
  .container
    +screen-min(1441px)
      max-width: none
      padding: 0 100px

.case-studies-talk
  background-color: $theme-primary-color
  
.case-studies-text
  &-container
    display: flex
    flex-direction: column
    max-width: 800px
    height: 100%
    padding: 0 32px
    margin: 0 auto

    p
      font-size: 18px

    &__top-block
      padding: 8rem 32px 0 32px

      +screen-min(1024px)
        padding: 0 32px

      +screen-max(600px)
        padding-top: 10rem

.case-studies-quote-section
  padding: 0
  +screen-max(600px)
    padding-bottom: 20px

  .case-studies-quote
    background-color: $theme-light-color
    background-attachment: fixed
    background-position: center
    clip-path: polygon(0% 0%, 0% 90%, 50% 100%, 100% 90%, 100% 0%, 50% 10%)

    +screen-min(768px)
      clip-path: polygon(0% 0%, 0% 85%, 50% 100%, 100% 85%, 100% 0%, 50% 15%)

    &-wrapper
      display: flex
      justify-content: center
      align-items: center
      min-height: 15rem

    &-container
      display: flex
      flex-direction: column-reverse
      max-width: 95%
      margin: 6rem 0 3rem 0
      gap: 10px

      +screen-min(768px)
        max-width: 70%

      +screen-min(1024px)
        flex-direction: row

      &-person
        flex-basis: 10rem
        text-align: left
        font-size: 14px

        +screen-min(1024px)
          text-align: center

        img
          width: 88px
          min-width: 88px
          height: 88px
          border-radius: 50%

        &__bold
          font-weight: bold

      &-quotation
        flex: 1
        display: flex
        justify-content: center
        align-items: center

        b
          font-weight: normal

        blockquote
          font-size: 1.4rem
          font-style: italic

      &-beginquote
        font-size: 6.5rem
        color: #17B3E5
        line-height: 0
        vertical-align: middle
        margin-right: 0.8rem
.case-studies-chart
  padding-top: 36px

  +screen-max(600px)
    padding-top: 0

  &-picture
    width: 100%
    display: flex
    justify-content: center

    img
      width: 100%

      +screen-min(1024px)
        max-width: 80%

.case-studies-tech
  display: flex
  flex-direction: column
  gap: 3rem
  align-items: center
  max-width: 1280px

  +screen-min(1024px)
    gap: 1rem
    flex-direction: row
    align-items: initial

    &:before
      content: ""
      border: 0.5px solid rgba(135, 156, 165, 0.3)
      align-self: stretch

  &-block
    flex: 1

    &__start
      order: -1

    h2
      text-align: center

  &-items
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 3rem
    justify-content: space-evenly
    align-items: baseline

    +screen-min(1024px)
      justify-content: center

  &-item
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    &-text
      font-size: 0.875rem

.case-studies-call-to-action
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: $theme-primary-color

  +screen-max(600px)
    padding: 62px 0

  &-header
    font-size: 2rem
    color: #FFF
    margin-bottom: 2.5rem
    text-align: center
