.infinite-slider 
  display: flex

  &__wrapper 
    width: 100% 
    overflow: hidden 

@keyframes slide 
  0% 
    transform: translateX(0)
  
  100% 
    transform: translateX(calc(-1 * var(--totalWidth)))
  
@keyframes slide-left 
  0% 
    transform: translateX(calc(-1 * var(--totalWidth)))
  
  100% 
    transform: translateX(0)
  

