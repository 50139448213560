.explore
  &.home-page
    h2
      font-size: 28px
    
      +screen-min(769px)
        font-size: 36px

  .row
    margin-top: 50px
    gap: 32px 24px

  .card
    padding-top: 75px
    position: relative
    display: flex
    flex-direction: column
    box-shadow: none

    border: 1px solid $theme-primary-muted-color

    .label-tag
      display: flex
      align-self: flex-start
      width: auto
      margin-bottom: 10px
      padding: 2px 15px
      background: $blue-vivid-plan-color
      border-radius: 5px
      color: $default-light-color
      font-size: 14px
      font-weight: 500
      line-height: 1.3
      text-align: center
      letter-spacing: -0.02em

    .logo
      width: 140px
      height: 140px
      position: absolute
      top: -72px
      left: -3px

      &.card__img
        width: 100px
        height: auto
        left: 30px
        max-width: 8rem
        position: absolute
        top: -52px

    h3, .h3 
      margin-bottom: 10px
    
    p
      flex-grow: 1
      margin-bottom: 15px


    .link
      text-decoration: none
      opacity: 0.8
      font-size: 12px
      font-weight: 600

      &::after
        content: ''
        display: inline-block
        width: 5px
        height: 10px
        margin-bottom: -1px
        margin-left: 11px
        background: url('~chevron.svg') no-repeat

  +screen-max(1024px)
    .column
      flex: 0 1 calc(50% - 12px)

  +screen-max(550px)
    .row
      flex-wrap: wrap
      margin-top: 0
      gap: 0

      .column
        flex-basis: 100%

    .card
      padding: 30px

      .logo
        position: relative  
        height: 80px
        width: 80px
        top: auto
        left: -8px
        margin-bottom: 8px

        &.card__img
          width: 80px
          position: static
          margin-bottom: 8px
