.one-side-image
  padding-top: 57px
  padding-bottom: 57px

  +screen-max(768px)
    padding-top: 36px
    padding-bottom: 48px

  .container
    flex-direction: row
    align-items: center
    justify-content: space-between
    gap: 24px

    +screen-max(600px)
      flex-direction: column

    &-reversed
      flex-direction: row-reverse


  &__image-wrapper
    max-width: 400px
    width: 100%
    min-width: 240px

    +screen-max(600px)
      max-width: 100%

  &__image
    flex-grow: 1
    width: 100%

  &__content
    max-width: 700px
        
  &__title
    font-size: 36px
    margin-bottom: 17px

    +screen-max(768px)
      font-size: 28px

  &__text
    font-size: 18px
    margin-bottom: 17px

    +screen-max(500px)
      font-size: 16px

  &-text__line
    display: block

  &__link
    font-weight: 600
    font-size: 15px
    line-height: 2.4
    text-decoration: none
    text-transform: uppercase
    letter-spacing: 0.05em

    &.chevron-right
      &::after
        margin-left: 11px
