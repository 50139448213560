.hero-pricing
  overflow: hidden

  &.section
    padding-top: 0
    padding-bottom: 1100px

    +screen-max(640px)
      padding-bottom: 800px

  .container
    +screen-max(550px)
      padding-left: 0
      padding-right: 0

  &__wrapper
    +screen-max(640px)
      padding-bottom: 210px
      margin-bottom: -210px
      background: $bg-blue-color

  &__box
    position: relative
    padding: 80px 56px 0

    &::before,
    &::after
      content: ""
      position: absolute
      display: flex
      border-radius: 100%
      filter: blur(200px)
      z-index: 0

      +screen-max(640px)
        filter: blur(75px)

    &::before
      bottom: -330px
      left: 0
      width: 486px
      height: 389px
      background: #A9EEB3
      transform: translate3d(0, 0, 0) translate(-70%, 100%)

      +screen-max(640px)
        bottom: -105px
        width: 366px
        height: 366px
        background: #CCF3FF
        transform: translate3d(0, 0, 0) translate(-50%, 100%)

    &::after
      bottom: -177px
      right: 0
      width: 438px
      height: 338px
      background: $accent-cyan-color
      transform: translate3d(0, 0, 0) translate(40%, 100%)

      +screen-max(640px)
        bottom: -90px
        width: 406px
        height: 406px
        background: #FFF4CE
        transform: translate3d(0, 0, 0) translate(50%, 100%)

    +screen-max(1024px)
      padding: 80px 18px 0

    +screen-max(550px)
      padding: 48px 18px 0
      border-radius: 0

  &__content
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    align-items: center
   
  &__title
    text-align: center
    margin-bottom: 12px

  &__text
    max-width: 1000px
    text-align: center
    margin-bottom: 24px
