.customer-stories
  &__title
    text-align: center
    margin-bottom: 16px

  &__text
    color: $text-secondary-color
    text-align: center
    max-width: 1000px
    margin-bottom: 40px
    margin-left: auto
    margin-right: auto

  &__list
    display: flex
    flex-wrap: wrap
    gap: 24px 32px

    +screen-max(550px)
      gap: 16px

  &__actions
    display: flex
    justify-content: center
    align-items: center
    margin-top: 52px

    +screen-max(550px)
      margin-top: 34px


  .customer-story
    position: relative
    flex: 1 45%
    padding: 24px
    border-radius: 32px
    background-clip: padding-box !important
    text-decoration: none
    overflow: hidden

    +screen-max(550px)
      flex: 1 100%

    &::before
      content: ""
      position: absolute
      top: -104px
      right: -57px
      width: 273px
      height: 240px
      background-image: url('~cards-bg/hexagon.svg')
      background-size: contain
      background-repeat: no-repeat

      +screen-max(1024px)
        right: -130px

      +screen-max(550px)
        right: -57px
     
    &--green
      background: linear-gradient(303.32deg, #BEEFC5 0%, #F8FFF9 100%)
      border: 1px solid rgba(88, 169, 100, 0.12)

    &--purple
      background: linear-gradient(303.32deg, #DFC7EE 0%, #FBF5FF 100%)
      border: 1px solid rgba(159, 99, 195, 0.12)

      &::before
        top: -160px
        right: -72px
        width: 280px
        height: 280px
        background-image: url('~cards-bg/circle.svg')

        +screen-max(1024px)
          right: -145px

        +screen-max(550px)
          right: -72px
          
    &--red
      background: linear-gradient(303.32deg, #FBCACA 0%, #FFECEC 100%)
      border: 1px solid rgba(222, 89, 70, 0.12)

      &::before
        top: -144px
        right: -149px
        width: 357px
        height: 264px
        background-image: url('~cards-bg/rectangle.svg')

        +screen-max(1024px)
          right: -231px

        +screen-max(550px)
          right: -149px

    &--yellow
      background: linear-gradient(303.32deg, #EADAB2 0%, #FFF9EA 100%)
      border: 1px solid rgba(228, 156, 48, 0.12)

    &__logo
      height: 72px
      margin-bottom: 20px
      position: relative
      z-index: 2

      +screen-max(550px)
        margin-bottom: 0

      img
        height: 100%

    &__text
      font-family: 'Archivo', sans-serif
      font-size: 20px
      font-weight: 300
      letter-spacing: 0.02em
      line-height: 1.8
      position: relative
      z-index: 2
      color: $text-color

      +screen-max(550px)
        font-size: 16px

      &--highlighted
        font-size: 36px
        font-weight: 600
        line-height: 0
          
        +screen-max(550px)
          font-size: 24px
      &--s
        font-size: 24px

        +screen-max(550px)
          font-size: 18px
