.integration-card
  height: fit-content
  padding: 16px 24px
  display: flex
  align-items: center
  gap: 16px
  border-radius: 16px
  border: 1px solid $border-color
  background: #ffffff
  box-shadow: 0px 2px 8px 0px $box-shadow-color
  text-decoration: none
  transition: border-color 0.2s ease-in-out

  &:hover
    border-color: $ui-primary-color

  &__wrapper
    padding: 8px

  &__box
    &:last-child
      max-width: calc(100% - 72px)

  &__icon
    display: flex
    width: 56px
    min-width: 56px
    height: 56px
    border-radius: 8px
    border: 1px solid $border-color
    background: #ffffff
  
  &__title
    margin-bottom: 2px
    font-size: 18px
    line-height: 1.3
    font-weight: 600
    color: $text-color

  &__text
    font-size: 16px
    line-height: 1.5
    font-weight: 500
    color: $text-disabled-color
