.entry
  display: flex
  background-repeat: no-repeat
  background-size: initial

  h2
    margin-bottom: 30px

  .body
    font-size: 18px

  &.plans
    padding-bottom: 12px

    .interval-toggle-switch
      margin-bottom: 32px

  &.non-profit
    padding-top: 2px
    padding-bottom: 48px

    h2
      margin-bottom: 24px

    p
      margin-bottom: 0

    .body
      align-self: center
      text-align: center

  &.by-railsware
    background-color: #006b8f
    color: #fff

    .container
      width: 100%
      flex-direction: row
      align-items: center
      gap: 24px

      +screen-max(1024px)
        flex-direction: column-reverse

    .entry-img
      max-width: 100%

    .highlighted
      color: #43cde6

  &.custom-solutions-section
    padding-bottom: 12px

  &-xero
    .button
      padding: 0
      max-width: 260px
      width: 100%
      text-align: center

      &.button-xero
        margin-left: 25px

      +screen-max(580px)
        &.button-xero
          margin: 20px 0 0

  +screen-min(1024px)
    .body
      width: 65%

  +screen-max(1024px)
    &.by-railsware
      padding: 50px 0 70px
      text-align: center

  +screen-between(1024px, 1450px)
    &.by-railsware
      padding: 70px 0

  +screen-min(1450px)
    &.by-railsware
      padding: 70px 0 90px
