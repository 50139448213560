.steps
  .container
    max-width: 1000px

  .step
    max-width: 165px
    margin: 0 auto
  
  h3
    margin-bottom: 10px

  +screen-max(767px)
    .step
      max-width: none
    
    h3
      font-size: 26px
