.base-checkbox
  display: flex

  &__input
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 0
    clip: rect(0,0,0,0)

    &:checked
      + .base-checkbox__content
        &::after
          transform: rotate(0)
          opacity: 1

  &--filled
    .base-checkbox__input
      &:checked
        + .base-checkbox__content
          &::before
            background: $ui-primary-color

    .base-checkbox__content
      padding-left: 32px

      &::before
        top: 2px
        width: 19px
        min-width: 19px
        height: 19px
        border-radius: 3px
        border: 1px solid $ui-primary-color
        background: #ffffff
        transition: all .2s ease-in

      &::after
        filter: invert(100%) sepia(8%) saturate(86%) hue-rotate(157deg) brightness(121%) contrast(100%)
        left: 4px

  &__content
    position: relative
    padding-left: 24px
    display: flex
    flex-direction: column
    font-size: 16px
    cursor: pointer

    &::before
      content: ""
      position: absolute
      top: 4px
      left: 0
      width: 16px
      min-width: 16px
      height: 16px
      border: 1px solid $theme-grey-color
      border-radius: 2px
      cursor: pointer
      box-sizing: border-box

    &::after
      content: ""
      position: absolute
      top: 8px
      left: 3px
      width: 10px
      height: 8px
      background-image: url('~checkbox-icon.svg')
      background-size: contain
      transform: rotate(-145deg)
      transition: all .2s ease-in
      opacity: 0

  &__title
    font-weight: 500
    margin-bottom: 4px

  &__text
    font-size: 12px

  &-label
    margin-bottom: 2px
    margin-right: 10px

  &-wrapper
    display: flex
    flex-direction: row
    align-items: center
