=button
  position: relative
  display: inline-flex
  align-items: center
  justify-content: center
  flex-wrap: nowrap
  white-space: nowrap
  overflow: hidden
  text-decoration: none
  text-transform: uppercase
  font-weight: 600
  letter-spacing: 0.05em
  cursor: pointer
  border-radius: 5px
  transition: all 0.2s ease-in-out

  &:focus
    outline: none

  &:focus-visible
    outline: auto

  &:hover
    text-decoration: none

  > svg
    margin-right: 5px
    overflow: visible

  &.disabled, &[disabled]
    opacity: 0.3
    pointer-events: none
    cursor: default
    