.plan-cards
  padding: 0 24px
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-template-rows: 1fr
  column-gap: 16px
  row-gap: 32px

  +screen-max(1280px)
    grid-template-columns: repeat(2, 1fr)

  +screen-max(720px)
    grid-template-columns: repeat(1, 1fr)

    .plan.most-popular
      margin-top: 20px

  +screen-max(550px)
    padding: 0

  .plan-card
    margin-bottom: 0

    &.custom
      height: fit-content

  &.plan-cards--expanded
    grid-template-columns: repeat(4, 1fr)

    +screen-max(1280px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(720px)
      grid-template-columns: repeat(1, 1fr)

    .plan-card
      border-left: none
      border-bottom: none
      border-right: none

  &.plan-cards--collapsed
    .plan-card
      display: none

      +screen-max(720px)
        display: flex

      &.custom
        display: flex
      &.most-popular
        .button
          background-color: $ui-primary-color
          color: #ffffff

.plans-mobile-table
  display: none

  +screen-max(720px)
    display: block
    margin-top: 20px

.plans-mobile-header
  display: flex
  align-content: space-between
  border-top: 2px solid $text-color
  padding-top: 20px
  width: 100%
  font-size: 14px
  position: relative

.plans-mobile-header-text
  flex: 1
  font-weight: 600
  text-align: left
  position: relative
  display: flex
  justify-content: space-between
  align-items: center

  &::after
    display: block
    content: ''
    background: url('~icons/chevron-down.svg')
    background-size: contain
    background-repeat: no-repeat
    color: $text-color
    margin-left: 4px
    width: 32px
    height: 32px
    filter: invert(42%) sepia(54%) saturate(4151%) hue-rotate(168deg) brightness(99%) contrast(101%)
    transition: all 0.25s ease-in-out

.plans-mobile-content
  display: none

.plans-mobile-table__expanded
  .plans-mobile-header-text
    &::after
      transform: rotate(180deg)

  .plans-mobile-content
    padding-top: 12px
    display: flex
    flex-direction: column
    gap: 8px

.plans-mobile-row
  display: flex
  flex-direction: row
  justify-content: space-between
  font-size: 14px
  line-height: 1.7
  gap: 8px
  min-height: 24px

  a
    color: $ui-primary-color
    text-decoration: none

.plans-mobile-row-key
  font-weight: 500
  white-space: nowrap

.plans-mobile-row-val
  display: flex
  flex-direction: column
  color: rgba(3, 3, 39, 0.7)
  text-align: right

  img
    width: 24px
    min-width: 24px

  span
    font-size: 12px
    display: block

  ul
    text-align: right

    li
      line-height: 1.5


[data-display="monthly"]
  [data-content="annual"]
    display: none
[data-display="annual"]
  [data-content="monthly"]
    display: none
