.grid-component
  overflow: hidden

  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 32px

    +screen-max(1023px)
      display: flex
      gap: 0

  &-header
    margin-bottom: 24px
    padding: 24px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center
    border-radius: 24px
    background: $bg-blue-color

    +screen-min(1024px)
      display: none

    &__title
      margin-bottom: 8px

    &__text
      margin-bottom: 0
      font-size: 20px

  &-item
    position: relative
    border: 1px solid $border-color
    border-radius: 20px
    overflow: hidden

    +screen-max(1023px)
      border-radius: 24px

    &--wide
      grid-column: span 2

    &--tall
      grid-row: span 2

    &--purple
      background: linear-gradient(303deg, #DFC7EE 0%, #FBF5FF 100%)

    &--green
      background: linear-gradient(303deg, #BEEFC5 0%, #F8FFF9 100%)

    &--cyan
      background: linear-gradient(303deg, #B2DEEA 0%, #F5FDFF 100%)

    &--red
      background: linear-gradient(303deg, #FBCACA 0%, #FFECEC 100%)

    &--yellow
      background: linear-gradient(303deg, #EADAB2 0%, #FFF9EA 100%)

    &__image
      width: 100%
      height: 100%

      img,
      picture
        width: 100%
        height: 100%
        object-fit: contain
        object-position: bottom
        display: flex

    &__title
      margin-bottom: 16px

    .grid-component-item__text
      margin: 0
      color: $text-color

      +screen-max(1280px)
        font-size: 16px

      +screen-max(1023px)
        font-size: 18px

    
    &--header
      padding: 50px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      text-align: center
      background: $bg-blue-color

      +screen-max(1023px)
        display: none

      .grid-component-item__text
        color: $text-secondary-color
        margin-bottom: 0

    &__box
      padding: 24px 16px
      position: absolute
      bottom: 0
      left: 0
      right: 0
      border-top: 1px solid rgba(18, 186, 247, 0.12)
      background: rgba(255, 255, 255, 0.8)
      backdrop-filter: blur(5px)
      text-align: center

      .grid-component-item__text
        font-weight: 700

  &__pagination
    display: none

    +screen-max(1023px)
      display: flex
  