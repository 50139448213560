$text-color: #030327
$text-secondary-color: #3F3F3F
$text-tertiary-color: #605E5D
$text-disabled-color: #879ca5
$accent-blue-dark-color: #033C52
$box-shadow-color: rgba(0, 155, 205, 0.12)
$text-color-primary-blue: #033652

$ui-primary-color: #009bcd
$bg-blue-color: #F2FCFF

$border-color: rgba(195, 205, 210, 0.4)
$border-default-color: #D7DBE4

$theme-primary-color: #05759E
$theme-primary-muted-color: rgba(135, 156, 165, 0.3)

$theme-light-color: #F6FAFB

$theme-grey-color: #879ca5

$accent-red-color: #de5946
$accent-green-color: #58A964
$accent-cyan-color: #12BAF7
$accent-purple-color: #9F63C3
$accent-yellow-color: #FFA621

$body-divider-color: rgba(135, 156, 165, 0.12)
$default-light-color: #ffffff
