.metrics-calculator
  margin-top: 40px

  &__title
    padding: 24px
    text-align: center
    font-size: 24px
    line-height: 1.5
    font-weight: 700
    background: #ffffff
    border: 1px solid $border-color
    border-radius: 24px 24px 0 0

  &__box
    display: flex
    flex-direction: column
    align-items: center
    padding: 35px 48px
    gap: 32px
    background: $theme-light-color
    border: 1px solid $border-color
    box-shadow: 0px 2px 8px $box-shadow-color
    border-radius: 0 0 24px 24px

    +screen-max(768px)
      padding: 32px 24px


  &__list
    display: flex
    align-items: center
    width: 100%
    gap: 16px

    +screen-max(768px)
      flex-direction: column 

  &__calculation
    width: 100%
    flex: 1
    display: flex
    gap: 16px

    +screen-max(1024px)
      flex-direction: column

    .base-input-label
      flex: 1

    .base-input-wrapper
      margin-bottom: 0
      flex: 1

    input
      -moz-appearance: textfield
      
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button 
        -webkit-appearance: none
        margin: 0
  
  &__result
    min-width: 150px
    display: flex
    align-items: center
    gap: 16px
    font-size: 40px
    font-weight: 700

  &__button
    width: 100%

