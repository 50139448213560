.layout-v2
  font-family: 'Inter', sans-serif

  &--content
    height: 100%
    min-height: calc(100vh - 86px)
    background: #ffffff

  .container
    display: flex
    flex-direction: column
    min-width: 320px
    max-width: 1600px
    height: 100%
    padding: 0 64px
    margin: 0 auto

    +screen-max(700px)
      padding: 0 16px

  .section
    padding-top: 72px
    padding-bottom: 72px

    +screen-max(768px)
      padding-top: 32px
      padding-bottom: 32px

    &--s
      padding: 48px 0

      +screen-max(1023px)
        padding-top: 40px
        padding-bottom: 40px

      +screen-max(768px)
        padding-top: 32px
        padding-bottom: 32px

    &.accent
      background-color: $bg-blue-color

      h2
        color: $accent-blue-dark-color

  h1,
  h2
    font-family: 'Archivo', sans-serif

  h1
    font-size: 48px
    line-height: 1.3

    +screen-max(760px)
      font-size: 32px

  h2
    font-size: 40px
    font-weight: 600
    line-height: 1.2

    +screen-max(760px)
      font-size: 28px

  h3,
  h4,
  h5,
  p,
  button,
  input
    font-family: 'Inter', sans-serif

  h3
    font-size: 28px
    line-height: 1.4

  h4
    font-size: 24px
    line-height: 1.5

  h5
    font-size: 20px
    line-height: 1.4

  p
    font-size: 20px
    line-height: 1.6

    +screen-max(768px)
      font-size: 16px

  .anchor
    display: block
    position: relative
    top: -88px
    visibility: hidden

    +screen-max(1023px)
      top: -62px

  .button
    padding: 0 24px
    height: 48px
    font-size: 14px
    line-height: 48px
    color: #fff
    background-color: $ui-primary-color
    border: 0
    +button-v2
    +ripple-effect

    &:hover
      color: #fff
      background-color: darken($ui-primary-color, 5%)

      .button-success-icon
        color: darken($accent-green-color, 5%)

    &.button-light
      background-color: $default-light-color
      color: $ui-primary-color

      .button-arrow
        filter: invert(40%) sepia(91%) saturate(886%) hue-rotate(159deg) brightness(93%) contrast(105%)

      &:hover
        background-color:$theme-light-color
        color: $ui-primary-color

    &.large
      min-width: 240px
      line-height: 48px
      font-size: 14px
      padding: 0 24px

    &.fat
      height: 64px
      line-height: 64px

      +screen-max(1023px)
        height: 48px
        line-height: 48px

    &.medium
      padding: 0 20px
      height: 42px
      font-size: 14px
      line-height: 42px

    &.full-width
      width: 100%

    &-link
      display: inline
      padding: 0
      width: auto
      color: $ui-primary-color
      font-weight: inherit
      font-size: inherit
      text-decoration: none

      &:hover
        color: lighten($ui-primary-color, 5%)

    &.button-anchor
      color: $ui-primary-color
      background: transparent 
      border-color: transparent
      padding-left: 0
      padding-right: 0
      text-transform: none
      min-width: auto
      font-size: 18px
      letter-spacing: normal

      .button-anchor-arrow
        display: block
        background: url('~icons/chevron-down.svg')
        background-repeat: no-repeat
        background-position: center
        margin-left: 8px
        width: 20px
        height: 20px
        filter: invert(41%) sepia(88%) saturate(918%) hue-rotate(160deg) brightness(93%) contrast(101%)

    &-ico
      img
        margin-left: 4px
        width: 20px
        height: 20px

      .button-arrow
        margin-left: 4px
        width: 20px
        height: 20px
        display: flex
        filter: invert(94%) sepia(54%) saturate(6639%) hue-rotate(185deg) brightness(119%) contrast(109%)

    &.danger
      background-color: $accent-red-color
      color: #fff

      &:hover,
      &:focus
        color: #fff

    &.success
      background-color: $accent-green-color
      color: #fff

      &:hover,
      &:focus
        color: #fff

    &-transparent
      color: #fff
      background: rgba(255, 255, 255, 0.12)
      border: 1px solid #FFFFFF

      &:hover
        color: #fff
        background-color: darken(rgba(255, 255, 255, 0.12), 5%)

    &-outlined
      color: $ui-primary-color
      background-color: #ffffff
      border: 1px solid currentColor

      &:hover
        color: darken($ui-primary-color, 5%)
        background-color: $bg-blue-color

      .button-arrow
        filter: invert(46%) sepia(37%) saturate(2006%) hue-rotate(160deg) brightness(92%) contrast(100%)

      &.success
        color: $accent-green-color
        background-color: transparent
        &:hover,
        &:focus
          color: $accent-green-color
          background-color: transparent

      &.danger
        &:hover,
        &:focus
          color: $accent-red-color
          background-color: transparent

      &.primary
        color: $ui-primary-color

        &:hover,
        &:focus
          background-color: transparent

    .button-success-icon,
    .button-alert-icon
      margin-right: 4px
      width: 20px
      height: 20px
      display: flex
      transition: all 0.2s ease-in-out

    .button-success-icon
      color: $accent-green-color

    .button-alert-icon
      color: $accent-red-color

  .link
    font-weight: 500
    font-size: 16px
    text-decoration: none
    color: $ui-primary-color
    transition: all 0.2s ease-in-out

    &:hover
      color: $theme-primary-color

  .link-arrow
    display: inline-flex
    align-items: center
    text-decoration: none
    font-weight: 500
    font-size: 18px
    color: $ui-primary-color
    transition: all 0.2s ease-in-out

    &:hover
      color: $theme-primary-color

    +screen-max(550px)
      font-size: 16px

    &::after
      content: ""
      width: 20px
      height: 20px
      background-image: url(~link-arrow.svg)
      background-size: contain
      background-repeat: no-repeat
      margin-left: 8px
      cursor: pointer
        
  .highlighted
    color: $ui-primary-color

  .base-input
    padding: 10px 16px
    font-size: 16px
    font-family: 'Inter', sans-serif
    border: 1px solid $border-color

    &::placeholder
      color: $text-disabled-color

    &:focus
      border-color: $ui-primary-color

    &--error
      border-color: $accent-red-color

    &--textarea
      height: 150px

    &-box
      display: flex
      position: relative

    &-icon
      position: absolute
      top: 12px
      right: 12px
      width: 24px
      height: 24px
      cursor: pointer

    &-preview
      min-height: 48px
      display: flex
      align-items: center
      justify-content: space-between
      gap: 8px
      padding: 10px 16px
      background: #FFFFFF
      border: 1px solid $border-color
      border-radius: 12px

      &__content
        display: flex
        align-items: center
        gap: 8px

      &__ico
        width: 24px
        min-width: 24px
        height: 24px
      
      &-tooltip
        position: relative
        margin-left: auto
        display: flex

        &:hover 
          .base-input-preview-tooltip__popup
            display: block

        &__ico
          width: 24px
          min-width: 24px
          height: 24px
          cursor: pointer

        &__popup
          position: absolute
          padding-bottom: 12px
          bottom: 16px
          right: -14px
          display: none
          cursor: default
          z-index: 2

          +screen-min(1400px)
            right: 50%
            transform: translateX(50%)

        &__content
          position: relative
          padding: 10px
          width: 201px
          flex-direction: column
          background: $theme-light-color
          text-align: center
          font-size: 13px
          line-height: 1.2
          border: none
          box-shadow: 0px 1px 8px 0px rgba(3, 60, 82, 0.12)
          border-radius: 5px

          a
            text-decoration: none

          &::before
            content: ""
            background: url('~popup-arrow.svg')
            width: 15px
            height: 6px
            position: absolute
            left: 83%
            bottom: -6px

            +screen-min(1400px)
              left: calc(50% - 10px)

