.analyze-data
  padding-top: 64px
  overflow: hidden

  &-title
    margin-bottom: 16px

  &-text
    max-width: 800px
    margin: 0 auto
    font-size: 18px
    text-align: center

  &-illustration
    position: relative
    display: flex
    justify-content: center
    
    img
      max-height: 350px

  &-logos
    display: flex
    justify-content: center
    margin: 16px 0 24px

    picture
      margin-right: 20px

      &:last-child
        margin-right: 0

+screen-max(768px)
  .analyze-data
    padding-bottom: 40px

    &-title
      font-size: 24px

    &-text
      font-size: 16px

    &-illustration
      img
        max-height: 200px

    &-logos
      flex-direction: column
      align-items: center
      margin-top: 24px

      picture
        margin: 0 0 16px

        &:last-child
          margin: 0

+screen-max(480px)
  .analyze-data
    &-illustration
      img
        max-height: 150px
