.setup-integration
  position: relative
  z-index: 2

  +screen-max(1024px)
    display: none
    
  &.section
    padding-top: 0
    margin-top: -70px

  &__content
    position: relative
    margin: 0 auto
    padding: 48px
    max-width: 800px
    background: #FFFFFF
    border: 1px solid $border-color
    box-shadow: 0px 2px 8px $box-shadow-color
    border-radius: 24px

    +screen-max(500px)
      padding: 20px

  &__title
    margin-bottom: 32px
    text-align: center

  &-separator
    position: relative
    margin-bottom: 21px
    display: flex
    justify-content: center

    &::before
      content: ""
      border-top: 1px solid $border-color
      position: absolute
      left: -48px
      right: -48px
      top: 50%
      width: calc(100% + 96px)
      z-index: 1

    &__circle
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 36px
      min-width: 36px
      height: 36px
      background: #FFFFFF
      border: 1px solid #C3CDD2
      border-radius: 100%
      z-index: 2

    &__arrow
      width: 24px
      min-width: 24px
      height: 24px
      transform: rotate(90deg)
      filter: invert(24%) sepia(33%) saturate(5374%) hue-rotate(177deg) brightness(100%) contrast(96%)
      user-select: none

  &-period
    margin-bottom: 20px
    display: flex
    align-items: center

    +screen-max(500px)
      flex-direction: column
      gap: 8px

    &__title
      min-width: 80px

      +screen-max(500px)
        width: 100%

    &__list
      flex: 1
      display: flex
      align-items: center
      gap: 8px
      flex-wrap: wrap

    &-tab
      flex: 1
      display: flex
      position: relative

      &__input
        position: absolute
        top: 0
        left: 0
        width: 0
        height: 0
        clip: rect(0,0,0,0)

        &:checked
          + .setup-integration-period-tab__content
            color: $ui-primary-color
            border-color: $ui-primary-color
            
      &__content
        flex: 1
        display: flex
        justify-content: center
        align-items: center
        padding: 6px
        min-height: 36px
        background: $theme-light-color
        border: 1px solid $border-color
        border-radius: 8px
        cursor: pointer
        white-space: nowrap

  &-description
    position: absolute
    display: flex
    flex-direction: column
    font-size: 18px
    font-weight: 500
    line-height: 1.3
    font-style: italic

    +screen-max(1250px)
      display: none

    &__title
      color: $ui-primary-color

    &.setup-integration-description--first
      top: 128px
      left: 0
      transform: translateX(-140%)

      .setup-integration-description__arrow
        position: absolute
        top: 121%
        left: 85%

    &.setup-integration-description--second
      text-align: right
      bottom: 252px
      right: 0
      transform: translateX(166%)
      
      .setup-integration-description__arrow
        position: absolute
        top: 121%
        right: 95%
