.base-tabs
  margin-bottom: 20px

.base-tab-list
  display: flex
  flex-wrap: wrap
  margin: -2px -4px

  +screen-max(768px)
    margin: -2px

  .base-tab
    padding: 2px 4px

    +screen-max(768px)
      padding: 2px

    &-content
      min-height: 40px
      padding: 2px 24px
      display: flex
      justify-content: center
      align-items: center
      background-color: transparent
      border-radius: 100px
      font-size: 12px
      line-height: 1.2
      color: $theme-primary-color
      letter-spacing: 0.05em
      font-weight: 600
      box-sizing: border-box
      cursor: pointer
      border: 1px solid $theme-primary-color
      position: relative
      transition: all 0.2s ease-in-out

    &-input
      position: absolute
      clip: rect(0,0,0,0)

      &:focus-visible + .base-tab-content
        outline-offset: 1px
        outline-width: 1px
        outline: 1px solid rgb(0, 95, 204)

      &:checked + .base-tab-content
        background-color: $theme-primary-color
        color: #ffffff

      &:not(:checked) + .base-tab-content
        &:hover
          opacity: .8
