.compare-price
  &__title
    margin-bottom: 16px
    text-align: center

  &__text
    margin-bottom: 40px
    margin-left: auto
    margin-right: auto
    text-align: center
    max-width: 1008px

  &-card
    position: relative
    padding: 32px 20px 20px
    height: auto
    display: flex
    flex-direction: column
    border-radius: 16px
    background: #ffffff
    box-shadow: 0px 8px 16px 0px $box-shadow-color
    text-align: center
    overflow: hidden
    z-index: 1

    +screen-max(1023px)
      width: 385px

    &::before
      content: ""
      position: absolute
      display: block
      top: 0
      right: 0
      width: 166px
      height: 248px
      filter: blur(75px)
      z-index: -1

    &:first-child
      border-top: 5px solid $accent-green-color

      &::before
        background: linear-gradient(303deg, #BEEFC5 0%, #F8FFF9 100%)

    &:nth-child(2)
      border-top: 5px solid $accent-cyan-color

      &::before
        background: linear-gradient(303deg, #B2DEEA 0%, #F5FDFF 100%)

    &:last-child
      border-top: 5px solid $accent-purple-color

      &::before
        background: linear-gradient(303deg, #DFC7EE 0%, #FBF5FF 100%)

    .compare-price-card__title
      margin-bottom: 16px
      font-weight: 700

    .compare-price-card__text
      margin-bottom: 0

    &__block
      padding: 16px 0
      border-bottom: 1px solid $border-color

      &:first-child
        +screen-max(1346px)
          min-height: 292px

        +screen-max(1133px)
          min-height: 369px

        +screen-max(1023px)
          min-height: auto

        +screen-max(416px)
          min-height: 292px

      &:last-child
        border-bottom: none

        .compare-price-card__title
          margin-bottom: 8px

      &--large
        flex: 1
        
        .compare-price-card__title
          margin-bottom: 8px

    &__desc
      margin-top: 8px
      display: flex
      gap: 4px
      justify-content: center
      align-items: center
      color: $text-disabled-color
      font-size: 16px
      line-height: 1.75

      img
        width: 24px

    &__sources
      margin-left: auto
      margin-right: auto
      max-width: 350px
      display: flex
      justify-content: center
      flex-wrap: wrap
      gap: 16px 10px

      &-list
        display: flex
        flex-direction: column
        gap: 16px

      &-row
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 16px 10px
      
    &__source
      width: 61px
      height: 61px
      border-radius: 8px
      border: 1px solid $border-color
      background: #ffffff

    &__price
      margin-bottom: 8px
      font-family: 'Archivo', sans-serif
      font-size: 40px
      font-weight: 600
      line-height: 1.2
      color: $ui-primary-color

      &+ .compare-price-card__text
        font-size: 16px

  &__controls
    display: none
    
    +screen-max(1023px)
      margin-top: 40px
      margin-left: auto
      margin-right: auto
      display: flex

    +screen-max(550px)
      margin-top: 24px

  &__note
    margin-top: 24px
    color: $text-disabled-color
    text-align: center
    font-size: 16px
    line-height: 1.5

    +screen-max(1023px)
      margin-top: 16px
