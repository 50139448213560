.tippy-box[data-animation=fade][data-state=hidden] 
	opacity: 0 
[data-tippy-root] 
	max-width: calc(100vw - 10px) 
.tippy-box 
	max-width: 220px
	font-size: 12px
	color: #53535a
	background-color: #f5f5f9
	border: 1px solid #dadde9
	position: relative 
	border-radius: 4px 
	white-space: normal 
	outline: 0 
	transition-property: transform,visibility,opacity 
.tippy-box[data-inertia][data-state=visible] 
	transition-timing-function: cubic-bezier(.54,1.5,.38,1.11) 
.tippy-content 
	position: relative 
	padding: 5px 9px 
	z-index: 1 
