.accordion
  max-width: 800px
  margin: 0 auto

  &-item
    margin-bottom: 5px
    background-color: #fff
    border-radius: 5px
    box-shadow: 0 12px 65px rgba(0, 82, 111, 0.1)

    p
      padding-right: 20px
      padding-left: calc(3.9% + 15px)

  button
    position: relative
    display: block
    padding: 20px 20px 20px 3.9%
    text-align: left
    width: 100%
    font-weight: bold
    border: none
    background: none

    &:hover,
    &:focus
      cursor: pointer
      color: $theme-primary-color

    h2
      position: relative
      padding-left: 15px
      font-size: 16px

      &:before
        position: absolute
        top: 50%
        left: -4px
        content: ''
        width: 5px
        height: 9px
        background-image: url('~chevron.svg')
        background-repeat: no-repeat
        background-size: 100% 100%
        transform: translateY(-50%)
        transition: transform 0.25s linear

  button[aria-expanded='false']
    &:hover
      background-color: $bg-blue-color

  button[aria-expanded='true']
    color: $theme-primary-color

    h2
      &::before
        transform: rotate(90deg) translateX(-50%)

    + .accordion-content
      opacity: 1
      will-change: opacity, max-height

  &-content
    opacity: 0
    max-height: 0
    overflow: hidden
    will-change: opacity, max-height

    &__text
      font-size: 14px
      line-height: 24px

      ul
        list-style: disc
        padding-left: 15px

      &:last-child
        margin: 0
        padding-bottom: 27px

  &-wrapper
    padding-bottom: 10px

  &-title
    @extend h2
    margin-bottom: 35px
    font-weight: bold

  .button-link
    display: inline
    padding: 0
    width: auto
    font-weight: inherit
    font-size: inherit

.accordion-section
  &--mb
    margin-bottom: 40px

  .accordion
    &-item
      margin-bottom: 8px
      border-radius: 12px
      box-shadow: 0px 2px 8px 0px $box-shadow-color

    &-content
      &__text-wrap
        padding-right: 20px
        padding-left: 60px
        font-size: 16px

        +screen-max(550px)
          padding-left: 20px
          font-size: 14px

      &__text
        margin-bottom: 16px
        font-size: 16px
        line-height: 1.75

        +screen-max(550px)
          font-size: 14px

        &:last-child
          margin-bottom: 0

      a
        color: $ui-primary-color
        text-decoration: none

    button
      display: flex
      align-items: center
      padding: 16px 20px

      &::before
        display: block
        content: ''
        background: url('~icons/chevron-down.svg')
        background-repeat: no-repeat
        background-size: cover
        color: $text-color
        width: 32px
        min-width: 32px
        height: 32px
        filter: invert(42%) sepia(54%) saturate(4151%) hue-rotate(168deg) brightness(99%) contrast(101%)
        transition: 0.25s ease-in-out
        transform: rotate(-90deg)

      h2
        font-family: 'Inter', sans-serif
        font-size: 20px
        padding-left: 8px
        display: flex
        align-items: center

        &::before
          display: none

      &:hover
        color: $ui-primary-color

      &:focus
        color: $text-color

    button[aria-expanded='false']
      &:hover
        background-color: #ffffff

    button[aria-expanded='true']
      color: $text-color

      &::before
        transform: rotate(0)

    .button-link
      text-decoration: none
      color: $ui-primary-color
      padding: 0
      display: inline

      &::before
        display: none


