.dashboards-carousel
  position: relative
  overflow: hidden

  &--small
    .dashboards-carousel-item__img 
      padding-bottom: 25%
  
  .container
    overflow: hidden
    position: relative

  &__header
    margin-bottom: 40px
    display: flex
    gap: 16px
    align-items: center
    justify-content: space-between

    +screen-max(1023px)
      text-align: center

  &__container
    position: relative

    &::before,
    &::after
      content: ''
      position: absolute
      width: 112px
      top: 0
      bottom: 0
      z-index: 2
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)

      +screen-max(1023px)
        display: none

    &::before
      left: -1px
      width: 60px
      transform: scale(-1, -1)

    &::after
      right: -1px

  &__controls
    +screen-max(1023px)
      display: none

  &-item
    &__title
      padding: 8px
      margin-bottom: 16px
      display: flex
      justify-content: center
      align-items: center
      text-align: center
      min-height: 64px
      font-size: 20px
      line-height: 1.6
      font-weight: 700
      background: $bg-blue-color
      border: 1px solid $border-color
      border-radius: 12px

    &__img
      position: relative
      padding-bottom: 50%
      display: flex
      margin-bottom: 24px
      width: 100%
      box-shadow: 0px 4px 16px $box-shadow-color
      border-radius: 16px
      user-select: none

      img
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        height: 100%
        width: 100%
        object-fit: contain
        object-position: center
  
    &__preview
      display: flex
      justify-content: center
      align-items: center
      gap: 8px
      font-size: 16px
      line-height: 1.5
      font-weight: 500

      img
        width: 40px

    &__text
      padding: 0 32px
      text-align: center
      font-size: 14px
      line-height: 1.4
      color: $theme-grey-color

      +screen-max(1023px)
        padding: 0
