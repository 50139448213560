.plan
  @extend .card
  position: relative
  display: flex
  flex-direction: column
  padding: 16px
  font-size: 14px
  border-top: 6px solid $lilac-plan-color
  .plan-name
    color: $lilac-plan-color
  &::before
    color: $lilac-plan-color

  &-price
    font-size: 16px
    line-height: 24px
    font-weight: 400
    strong
      font-size: 32px
      line-height: 44px
      font-weight: 600
  &-name
    margin-bottom: 2px
    font-size: 18px
    font-weight: 500
  p
    color: rgba(0, 0, 0, 0.7)
    margin-bottom: 24px
  .pricing
    margin-bottom: 24px

    .plan-description
      min-height: 4rem

  .button,
  .button-outlined
    margin-top: auto

    &.lowercase
      text-transform: none
      font-size: 15px
  .table td
    padding: 12px 0
    vertical-align: top
    &:last-child
      font-weight: 500
      text-align: right
  .plan-footer
    min-height: 48px
    text-align: center
    color: $text-disabled-color
    .button,
    .button-outlined
      width: 100%
  &.most-popular
    background-color: $bg-blue-color
    &::before
      content: 'Most popular'
      position: absolute
      left: 0
      right: 0
      top: -26px
      line-height: 16px
      font-weight: 500
      padding: 6px 12px 4px
      border-radius: 5px 5px 0 0
      color: #fff

  @each $plan-id, $plan-color in $billing-plan-colors
    &.#{$plan-id}
      border-top-color: $plan-color

      .plan-name
        color: $plan-color
      &::before
        background-color: $plan-color
      @if $plan-id == 'custom'
        .button
          background-color: $plan-color
