.tutorial
  &.section
    padding: 0 0 48px

  .separator
    padding-bottom: 48px
    border-top: 1px solid rgba(5, 117, 158, 0.2)

  &-title
    font-size: 36px
    text-align: center

  .onboarding-list
    width: 100%
    max-width: 800px
    margin: 0 auto 35px

    .onboarding-item
      padding: 16px 32px 16px 66px
      margin-bottom: 5px
      font-family: 'Archivo', sans-serif
      font-weight: 700
      border: 1px solid rgba(5, 117, 158, 0.15)
      border-radius: 5px

      &::before
        top: 50%
        left: 32px
        transform: translateY(-50%)

      .bold
        font-weight: bold

    &.guide
      .onboarding-item
        border-radius: 0
        margin-bottom: 0
        font-weight: 300
        padding-left: 72px
        border-bottom: none

        &:nth-child(even)
          background-color: $theme-light-color
        &:first-child
          border-radius: 5px 5px 0 0
        &:last-child
          border-bottom: 1px solid rgba(5, 117, 158, 0.15)
          border-radius: 0 0 5px 5px
        h5
          font-size: 18px
          font-weight: 700
        &::before
          content: ''
          border: none
          background: url('~icons/checkmark-new.svg')
          width: 24px
          height: 24px
          position: absolute

  &-last-step
    position: relative

    padding: 20px 30px
    margin: 0 auto

    width: 100%
    max-width: 800px

    background-color: white

    border: 1px solid $theme-primary-color
    border-radius: 5px

    text-align: center

    .bold
      font-weight: bold

    p
      margin: 0

    &::before
      content: ' '

      position: absolute
      top: -35px

      height: 30px
      width: 0

      border: 1px dashed #DCECF1

.tutorial
  +screen-max(1024px)
    &.section
      padding: 0 0 40px

    .separator
      padding-bottom: 40px

  +screen-max(768px)
    &-title
      font-size: 24px
