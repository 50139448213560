.categories
  position: relative

  &--dashboards
    .categories__content
      justify-content: flex-start
      gap: 16px

      .category
        height: 88px

      .category__content
        padding-bottom: 0

      .category__box
        padding: 16px

  &__title
    margin-bottom: 24px
    text-align: center
  
  &__content
    display: flex
    flex-wrap: wrap
    justify-content: center
    grid-auto-rows: min-content
    gap: 24px

    +screen-max(1023px)
      gap: 16px

    +screen-max(550px)
      gap: 8px

  .category
    width: calc(25% - 18px)
    height: 104px
    position: relative

    +screen-max(1200px)
      height: 90px

    +screen-max(1023px)
      width: calc(50% - 8px)
      height: 106px

    +screen-max(550px)
      width: 100%
      height: 90px

    &:hover
      .category__content
        border-color: $ui-primary-color
      //   padding-bottom: 0
      //   z-index: 2

      //   +screen-max(1023px)
      //     padding-bottom: 8px
      
      // .category__text
      //   max-height: 300px
      //   padding-bottom: 24px

      //   +screen-max(1200px)
      //     padding-bottom: 16px

    &__content
      position: absolute
      top: 0
      left: 0
      right: 0
      padding-bottom: 8px
      border-radius: 24px
      border: 1px solid $border-color
      background: #ffffff
      color: $text-color
      text-decoration: none
      box-shadow: 0px 2px 8px 0px $box-shadow-color
      box-sizing: border-box
      transition: all .3s linear
      z-index: 0

    &__box
      display: flex
      align-items: center
      gap: 16px
      padding: 24px 24px 16px 

      +screen-max(1200px)
        padding: 16px 16px 8px

      +screen-max(1023px)
        padding: 24px 24px 16px 

      +screen-max(550px)
        padding: 16px 16px 8px

    &__icon
      width: 56px
      min-width: 56px
      height: 56px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 14px
      overflow: hidden

      img
        display: flex
        width: 28px
        height: 28px

    &__title
      font-size: 20px
      line-height: 1.4
      font-weight: 700

      +screen-max(1200px)
        font-size: 16px

      +screen-max(1023px)
        font-size: 20px

    &__text
      margin-top: 4px
      margin-bottom: 0
      font-size: 14px
      color: $text-disabled-color
