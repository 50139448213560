.cookie-consent
  position: fixed
  left: 24px
  bottom: 16px
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 24px 16px 16px
  z-index: 99
  width: 100%
  max-width: 480px
  gap: 16px
  transform: translateY(calc(100% + 16px))
  transition: transform 500ms ease-in-out
  border-radius: 16px
  border: 1px solid $border-color
  background: #ffffff
  box-shadow: 0px 4px 16px 0px $box-shadow-color

  +screen-max(550px)
    left: 0
    right: 0
    bottom: 0
    max-width: 100%
    border-radius: 0
    transform: translateY(100%)

    .button
      width: auto

  &__content
    font-size: 14px

    a
      color: $ui-primary-color


  &.entering,
  &.exited,
  &.exiting
    transform: translateY(calc(100% + 16px))

    +screen-max(550px)
      transform: translateY(100%)

  &.entered, &.visible
    transform: translateY(0%)
