.use-cases
  &.wide
    padding-top: 70px

  .card
    width: 100%
    max-width: 395px

  .card-text
    margin: 0

  .card-title
    margin-bottom: 10px
    letter-spacing: -0.02em

  &-wrapper
    display: flex
    gap: 20px

  .call-to-action
    margin-top: 40px

  .cta-link
    text-decoration: none

    &::after
      content: ''
      display: inline-block
      width: 5px
      height: 10px
      margin-left: 5px
      background: url('~chevron.svg') no-repeat

.use-cases
  +screen-max(1024px)
    &-wrapper
      flex-wrap: wrap
      justify-content: center

    .card
      max-width: calc(50% - 10px)

    &.section
      padding: 40px 0

    &.wide
      .use-cases-wrapper
        align-items: center
        flex-direction: column

      .card
        padding: 24px

  +screen-max(768px)
    &-title
      font-size: 24px
      text-align: center

    .card
      padding: 24px

    .card-text
      font-size: 16px

  +screen-max(550px)
    &-wrapper
      align-items: center
      flex-direction: column

    .card
      max-width: none

    .card-title
      font-size: 18px
