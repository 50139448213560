.hero-template
  &__wrapper
    margin-top: -88px
    max-width: 100%
    overflow: hidden

    +screen-max(1024px)
      margin-top: 0
    
  &.hero-template--small
    .hero-template__row
      padding-top: 39px
      padding-bottom: 48px

      +screen-max(1024px)
        padding-top: 48px
        padding-bottom: 40px

      +screen-max(550px)
        padding-top: 28px
        padding-bottom: 48px

  &.hero-template--full
    background: linear-gradient(256.5deg, rgba(176, 232, 255, 0.48) 18.86%, rgba(211, 242, 255, 0) 61.28%), #FFFFFF

    +screen-max(1024px)
      background: none

    .hero-template__row
      padding-top: 0
      padding-bottom: 0
      background: none
      border: none
      border-radius: 0

      &::before,
      &::after
        display: none

      +screen-max(1024px)
        flex-direction: column

    .hero-template__box--content
      flex: 1
      padding-top: 20px
      padding-bottom: 20px

      +screen-max(550px)
        padding-top: 40px
        padding-bottom: 0
        text-align: left

    .hero-template__box--img
      margin-top: -20px
      margin-bottom: -20px

      +screen-max(1024px)
        margin-top: 0
        margin-bottom: 0
        padding-bottom: 30px
        display: flex
        align-items: center

        .hero-template__img
          max-width: 400px
          display: flex
          justify-content: center

    &.hero-template--blue-green-bg
      overflow: hidden
      .hero-template__row
        overflow: visible
        &::after
          display: block
          background: #D2F8D5
          width: 906px
          height: 788px
          bottom: -550px
          filter: blur(106px)

          +screen-max(1024px)
            width: 483px
            height: 426px
            right: 80%
            left: auto
            bottom: 0
            background: $accent-cyan-color
            opacity: 0.24
            transform: translateX(100%)

  &.hero-template--connector
    background: none

    .hero-template__row
      padding-top: 88px
      padding-right: 0
      gap: 0
      padding-bottom: 160px
      background: none
      border: none
      border-radius: 0
      overflow: visible

      +screen-max(1024px)
        padding-bottom: 40px
        padding-right: 16px

      +screen-max(550px)
        padding-top: 0
        padding-right: 32px

      &::before,
      &::after
        display: none

      &::before
        right: -170px
        bottom: 170px
        display: block
        width: 914px
        height: 610px
        background: $accent-cyan-color
        opacity: 0.24
        filter: blur(200px)

        +screen-max(1024px)
          width: 483px
          height: 426px
          bottom: 0
          right: -60%

      &::after
        display: block
        right: -617px
        top: -10px
        width: 1532px
        height: 900px
        background: none
        background-image: url(~grid-bg.svg)
        filter: none
        opacity: 1

        +screen-max(1024px)
          display: none

      +screen-max(1024px)
        flex-direction: column

    .hero-template__img
      justify-content: flex-end
      max-width: 733px

    .hero-template__img-connector-box
      position: relative
      display: flex
      justify-content: flex-end

    .hero-template__connector-img
      position: absolute
      width: 18%
      max-width: 130px
      right: 8%
      top: 43.3%

      +screen-min(1530px)
        right: 7.4%

    .hero-template__text,
    .hero-template__list
      max-width: 523px

      +screen-max(1024px)
        max-width: 100%

    .hero-template__box--content
      max-width: 600px
      flex: 1
      padding-top: 20px
      padding-bottom: 20px

      +screen-max(1024px)
        max-width: 100%

      +screen-max(550px)
        padding-top: 40px
        padding-bottom: 0
        text-align: left

    .hero-template__box--img
      margin-top: -20px
      margin-bottom: -20px

      +screen-max(1024px)
        display: none

  &.hero-template--large
    .hero-template__row
      padding-top: 72px
      padding-bottom: 72px

      +screen-max(1024px)
        padding-top: 51px
        padding-bottom: 51px

      +screen-max(550px)
        padding-top: 48px
        padding-bottom: 48px

  &.hero-template--large-image
    .hero-template__box--content
      flex: 1.7

  &.hero-template--font-16
    .hero-template__text
      font-size: 16px

  &.hero-template--green-bg
    .hero-template__row
      &::before
        background: #CCF3FF
        right: 0
        bottom: 320px

        +screen-max(1023px)
          right: -448px
          bottom: 368px

        +screen-max(550px)
          filter: blur(100px)
          left: -200px
          right: auto
          bottom: -84px

      &::after
        width: 622px
        height: 622px
        background: #A9EEB3
        right: 0px
        bottom: -302px
        z-index: -2

        +screen-max(1023px)
          right: -448px
          bottom: -254px

        +screen-max(550px)
          width: 406px
          height: 406px
          filter: blur(200px)
          right: auto
          right: -200px
          left: auto
          bottom: -104px

  &.hero-template--red-bg
    .hero-template__row
      &::before
        bottom: -134px

        +screen-max(550px)
          left: -178px
          bottom: -167px
          width: 366px
          height: 366px

      &::after
        right: -60px
        bottom: -287px
        background: linear-gradient(303deg, #FBCACA 0%, #FFECEC 100%)

        +screen-max(1023px)
          right: -299px
          bottom: -357px

        +screen-max(550px)
          left: auto
          bottom: -94px
          right: -97px
          width: 406px
          height: 406px
          filter: blur(200px)

  &.hero-template--image-tablet
    +screen-max(1024px)
      .hero-template__row
        flex-direction: column-reverse

      .hero-template__box--img
        display: flex
        margin-left: auto
        margin-right: auto

        .hero-template__img
          max-width: 264px

    +screen-max(550px)
      .hero-template__box--img
        display: none

  &.hero-template--image-mobile
    +screen-max(1024px)
      .hero-template__row
        flex-direction: column-reverse

      .hero-template__box--img
        display: flex
        margin-left: auto
        margin-right: auto

        .hero-template__img
          max-width: 264px

    +screen-max(550px)
      .hero-template__box--img
        max-width: 140px

  &.hero-template--without-bg
    .hero-template__row
      padding: 34px 0
      background: #ffffff
      border: none

      +screen-max(1024px)
        flex-direction: column
        align-items: center

      +screen-max(550px)
        padding: 48px 0
      
      &::before,
      &::after
        display: none

    .container
      +screen-max(550px)
        padding: 0 16px

    .hero-template__box--content
      flex: 1

    .hero-template__box--img
      display: flex
      max-width: 600px

      +screen-max(1024px)
        max-width: 500px

  &--breadcrumbs
    .hero-template__row
      +screen-max(1024px)
        margin-top: 40px

    .hero-template__box--content
      +screen-min(1025px)
        padding-top: 50px

  &.hero-template--setup-integration
    .hero-template__row
      padding-bottom: 182px

      +screen-max(1024px)
        padding-bottom: 50px

  &.hero-template--homepage
    +screen-max(1024px)
      overflow: hidden

    .hero-template__row
      padding: 50px 0 20px
      border: none
      background: none
      border-radius: 0
      overflow: visible

      +screen-max(1024px)
        padding: 94px 32px 56px

      &::before
        display: none

        +screen-max(1024px)
          display: block
          padding-bottom: 40%
          background: $accent-cyan-color
          opacity: 0.5
          filter: blur(200px)
          z-index: 1
          top: -50px
          left: 331px
          width: 914px
          height: 848px
          opacity: 0.3
        
      &::after
        display: none

    .hero-template__box--content
      max-width: 600px
      padding: 100px 0

      +screen-max(1024px)
        padding: 0
        max-width: 100%

    .hero-template__box--img
      z-index: 3

    .hero-template__img
      justify-content: center

    .hero-template__check-list
      .hero-template-check-item
        font-weight: 400
        color: $text-secondary-color

  &.section
    padding-top: 0
    padding-bottom: 0

  .container
    +screen-max(550px)
      padding-left: 0
      padding-right: 0

  &__wrap
    position: relative
    width: 100%

  &-breadcrumbs
    padding: 0 56px
    width: 100%
    display: flex
    align-items: center
    gap: 4px

    +screen-max(1024px)
      padding: 0

    &__wrap
      position: absolute
      top: 40px
      width: 100%
      z-index: 2
      max-width: 100%

      +screen-max(1024px)
        top: 8px

    &__content
      +screen-max(1024px)
        overflow: auto

        &::-webkit-scrollbar
          display: none

        &::before
          content: ""
          position: absolute
          right: 0
          top: 0
          right: 0
          bottom: 0
          width: 48px
          background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)

    &__item
      text-decoration: none
      font-size: 16px
      line-height: 24px
      font-weight: 500
      color: #879CA5
      display: flex
      gap: 4px
      white-space: nowrap

      +screen-max(550px)
        font-size: 14px
        font-weight: 400

      &:hover
        color: $text-color

      &::after
        display: block
        content: ''
        background: url('~icons/chevron-down.svg')
        background-repeat: no-repeat
        background-size: cover
        width: 24px
        min-width: 24px
        height: 24px
        filter: invert(42%) sepia(54%) saturate(4151%) hue-rotate(168deg) brightness(99%) contrast(101%)
        transition: 0.25s ease-in-out
        transform: rotate(-90deg)

      &--current
        color: $text-color
        cursor: text

        &::after
          display: none

      &-wrap
        +screen-max(1024px)
          &:first-child
            padding-left: 16px

          &:last-child
            padding-right: 16px

        +screen-max(550px)
          &:first-child
            padding-left: 32px

          &:last-child
            padding-right: 32px

  &__icon
    margin-bottom: 40px
    background: #ffffff
    border: 1px solid $box-shadow-color
    filter: drop-shadow(0px 2px 8px $box-shadow-color)
    width: 110px
    display: flex
    border-radius: 12px

    +screen-max(1024px)
      margin-left: auto
      margin-right: auto

    +screen-max(550px)
      margin-bottom: 16px
      width: 64px
      border-radius: 16px

    img
      width: 100%

    &--quickbooks
      width: 230px
      padding: 0 15px

  &-from-to
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 40px

    +screen-max(1024px)
      margin-left: auto
      margin-right: auto

    +screen-max(550px)
      margin-bottom: 16px

    &__link
      min-width: 110px
      width: 110px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 12px
      background: #ffffff
      box-shadow: 0px 2px 8px 0px $box-shadow-color
      overflow: hidden

      +screen-max(550px)
        min-width: 64px
        width: 64px
        border-radius: 16px

      &:hover
        outline: 1px solid $ui-primary-color

      &--quickbooks
        width: 230px
        padding: 0 15px

    .hero-template-from-to__arrow
      width: 24px
      min-width: 24px
      height: 24px
      filter: invert(49%) sepia(66%) saturate(2677%) hue-rotate(162deg) brightness(89%) contrast(101%)

    &__icon,
    img
      display: flex
      width: 100%
      height: 100%

  &__row
    position: relative
    padding: 62px 56px
    background: $bg-blue-color
    border: 1px solid rgba(18, 186, 247, 0.12)
    border-radius: 48px
    overflow: hidden
    display: flex
    gap: 24px 60px
    z-index: 1

    +screen-max(1024px)
      padding: 51px 16px

    +screen-max(550px)
      padding: 48px 32px
      border-radius: 0
    
    &::before,
    &::after
      content: ""
      position: absolute
      display: block
      filter: blur(200px)
      transform: translate3d(0, 0, 0)
      z-index: -1
      border-radius: 620px

    &::before
      width: 622px
      height: 622px
      background: #CCF3FF
      right: -60px
      bottom: -264px

      +screen-max(1023px)
        right: -422px
        bottom: -44px

      +screen-max(550px)
        filter: blur(100px)
        width: 366px
        height: 366px
        right: -200px
        bottom: -73px

    &::after
      width: 634px
      height: 634px
      background: #FFF4CE
      right: 10px
      bottom: -215px

      +screen-max(1023px)
        right: -362px
        bottom: -197px

      +screen-max(550px)
        filter: blur(75px)
        width: 406px
        height: 406px
        right: auto
        left: -200px
        bottom: -113px

  .hero-template__box
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    justify-content: center

    &--content
      flex: 2
      position: static

      +screen-max(1024px)
        text-align: center

    &--img
      position: relative
      flex: 1

      +screen-max(1024px)
        display: none

    .hero-template__img-wrap
      position: relative
      display: flex
      box-shadow: 0px 10px 14px 0px $box-shadow-color
      border-radius: 16px
      overflow: hidden

      &:hover
        &::before
          opacity: 1

        .button
          opacity: 1

      &::before
        content: ""
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        background: rgba(2, 3, 39, 0.24)
        border-radius: 4%
        opacity: 0
        transition: .2s all ease-in

      .button
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        opacity: 0


    .hero-template__img
      width: 100%
      display: flex
   
  &__title
    margin-bottom: 0

    +screen-max(1024px)
      br
        display: none

  &__text
    margin-bottom: 0
    margin-top: 12px

    b
      font-weight: 600

  &__list
    display: flex
    flex-direction: column
    padding-left: 25px
    font-size: 20px
    list-style: disc

    +screen-max(1024px)
      align-items: center

    +screen-max(768px)
      font-size: 16px

    +screen-max(550px)
      align-items: flex-start

  &__actions
    margin-top: 24px
    width: 100%
    display: flex
    gap: 10px

    +screen-max(1024px)
      justify-content: center

    +screen-max(550px)
      flex-direction: column
      .button
        width: 100%

  &-trusted
    margin-top: 64px
    
    &__text
      margin-bottom: 14px
      font-size: 16px

    &__list
      display: flex
      flex-wrap: wrap
      gap: 16px

    &__item
      max-width: 85px

      img
        width: 100%

  .hero-template__rating
    margin-top: 16px
    padding-top: 40px
    display: flex
    align-items: center
    gap: 8px 24px

    +screen-max(1024px)
      justify-content: center

    +screen-max(550px)
      padding-top: 0
      flex-direction: column

      .rating-customer-mark__text
        font-size: 9px

        &--grade
          font-size: 14px

  .hero-template__check-list
    margin-top: 40px
    display: flex
    gap: 16px 24px
    flex-wrap: wrap
    width: 100%

    +screen-max(1024px)
      justify-content: center

    +screen-max(550px)
      margin-top: 24px
      flex-direction: column
      align-items: center

    .hero-template-check-item
      display: flex
      align-items: center
      font-size: 16px
      line-height: 1.5
      font-weight: 500

      &--icon
        &::before
          display: none

      +screen-max(550px)
        font-weight: 400

      &::before
        content: ""
        margin-right: 4px
        width: 24px
        height: 24px
        background-image: url('~icons/check-icon.svg')
        background-size: contain
        background-repeat: no-repeat

      &__icon
        width: 32px
        height: 32px
        margin-right: 4px

  &-sources-block
    margin-top: 60px

    +screen-max(1024px)
      margin: 28px auto 0

    &--separate
      display: flex
      gap: 16px 128px
      border-top: 1px solid $border-color

      .hero-template-sources-used__source--text
        width: auto
        height: auto
        white-space: nowrap

        +screen-max(1024px)
          display: none

      +screen-max(1024px)
        flex-direction: column

        .hero-template-sources-used__title
          width: 100%
          text-align: center

        .hero-template-sources-used__list
          width: 100%
          justify-content: center

      .hero-template-sources-used
        padding-top: 24px
        flex-direction: column
        gap: 12px
        align-items: flex-start


  &-sources-used
    display: flex
    align-items: center
    gap: 8px 16px

    +screen-max(550px)
      flex-direction: column
      justify-content: center

    &__title
      font-size: 16px
      font-weight: 500
      line-height: 1.5

    &__list
      display: flex
      align-items: center
      flex-wrap: wrap
      gap: 8px

    &__source
      display: flex
      width: 32px
      height: 32px

      img
        width: 100%
