.metrics
  &.metrics--carousel
    padding-top: 0
    padding-bottom: 0

    .metrics__wrapper
      position: relative

      &::before,
      &::after
        content: ""
        position: absolute
        left: -1px
        right: -1px
        z-index: 2
        height: 88px

        +screen-max(768px)
          display: none

      &::before
        bottom: -1px
        background: linear-gradient(180deg, rgba(242, 252, 255, 0.0) 0%, rgba(242, 252, 255, 0.8) 70%, #F2FCFF 100%)

      &::after
        top: -1px
        background: linear-gradient(180deg, #F2FCFF 0%, rgba(242, 252, 255, 0.0) 100%)
      
    .metrics__content
      max-height: 750px
      padding: 72px 0

      +screen-max(1200px)
        max-height: 800px

      +screen-max(768px)
        max-height: none

    .metrics__list
      margin: 0 auto
      padding: 0 50px
      max-width: 1200px

      +screen-max(768px)
        padding: 0

  &.metrics--detailed-guide
    .metrics__list
      max-width: 1000px
      margin-left: auto
      margin-right: auto
      flex-direction: column
      gap: 24px 32px

      +screen-max(768px)
        gap: 32px

      .metrics-item
        width: 100%
        
      .metrics-item__title
        margin-bottom: 8px

  &__title
    margin-bottom: 40px
    text-align: center

  &__list
    display: flex
    flex-wrap: wrap
    padding: 0 48px
    gap: 4px 32px

    +screen-max(1023px)
      padding: 0

    +screen-max(768px)
      gap: 32px

  &-item
    padding: 24px
    width: calc(50% - 16px)

    +screen-max(768px)
      padding: 0

    +screen-max(550px)
      width: 100%

    &__box
      margin-bottom: 4px
      padding-bottom: 12px
      display: flex
      align-items: flex-start
      gap: 8px
      border-bottom: 1px solid $border-color

      +screen-max(768px)
        margin-bottom: 16px

    &__img
      width: 32px
      min-width: 32px
      height: auto

    .metrics-item__title
      font-size: 20px
      font-weight: 700
      line-height: 1.6

    .metrics-item__text
      margin: 0
      font-size: 16px
      color: $text-secondary-color

      a
        color: $ui-primary-color
        text-decoration: none

        &:hover
          color: $theme-primary-color

      ul
        margin: 8px 0
        list-style: disc
        padding-left: 23px

      ol
        margin: 8px 0
        padding-left: 23px

  .horizontal-carousel__controls
    +screen-max(768px)
      display: none
