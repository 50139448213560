.data-filter
  display: none

  +screen-min(1023px)
    position: sticky
    top: 88px
    padding: 24px 24px 24px 0
    text-align: left
    width: 289px
    z-index: 2
    display: flex
    flex-direction: column
    gap: 23px

  &::before
    content: ""
    position: absolute
    right: 0
    top: 0
    bottom: 0
    width: 50vw
    background: #f5f9fb
    border-radius: 0px 40px 40px 0px
    z-index: -1

  .base-checkbox__content
    &::before
      background: #ffffff

  &__box
    display: flex
    flex-direction: column
    gap: 14px

  &__title
    font-size: 18px
    letter-spacing: -0.02em
    line-height: 1.2
    font-weight: 600

  &-mobile
    position: relative
    padding: 0 48px 0 16px
    width: 100%
    height: 48px
    font-size: 16px
    color: $text-color
    background: #fff
    border: 1px solid $border-color
    border-radius: 12px
    z-index: 2
    -webkit-appearance: none
    -moz-appearance: none
    text-indent: 1px
    text-overflow: ''

    &:focus
      outline-color: $ui-primary-color

    &::-ms-expand
      display: none

    +screen-min(650px)
      min-height: 50px

    option
      background: #fff

    &__box
      display: block
      width: 100%
      position: relative

      &::before
        content: ""
        position: absolute
        top: 50%
        right: 12px
        width: 26px
        height: 26px
        transform: translateY(-50%)
        background: url('~icons/dropdown-ico.svg') no-repeat center
        z-index: 3

      +screen-min(1023px)
        display: none

  &__tabs
    display: flex
    flex-direction: column

  &-tab
    &--other
      order: 100
    &__input
      position: absolute
      top: 0
      left: 0
      width: 0
      height: 0
      clip: rect(0,0,0,0)

      &:checked
        + .data-filter-tab__content
          background: $ui-primary-color
          color: #ffffff

    &__content
      margin-bottom: 4px
      padding: 10px 24px
      font-size: 16px
      font-weight: 500
      border-radius: 5px
      cursor: pointer
      transition: all .2s ease-in
