.benefits
  .card
    background: $theme-light-color
    border: 1px solid $theme-primary-muted-color
    border-radius: 5px
    box-shadow: none

    margin-bottom: 15px
    padding: 30px

    h3
      margin-bottom: 15px

  .row
    .column
      &:nth-child(1), &:nth-child(4)
        flex: 0 1 55%
      &:nth-child(2), &:nth-child(3)
        flex: 0 1 45%

      &:nth-child(odd)
        .card
          margin-right: 15px


  +screen-max(1024px)
    .column
      margin-bottom: 0

  +screen-max(768px)
    .row
      .column
        &:nth-child(n)
          flex: 0 1 100%
          .card
            margin: 0 0 15px 0
