.advantages
  &__title
    margin-bottom: 16px
    text-align: center

    +screen-max(550px)
      margin-bottom: 24px
      text-align: left

  &__text
    margin-bottom: 40px
    text-align: center
    color: $accent-blue-dark-color

    +screen-max(550px)
      display: none

  &__list
    display: flex
    flex-wrap: wrap
    gap: 24px

  .icon-card
    flex: 0 1 calc(50% - 12px)

    +screen-max(550px)
      flex: 0 1 100%
