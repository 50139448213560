.text-steps
  &__title
    margin-bottom: 32px
    text-align: center

  &__list
    margin: 0 auto
    max-width: 800px
    border: 1px solid $border-color
    border-radius: 16px
    overflow: hidden

  &-item
    padding: 16px 24px
    border-bottom: 1px solid $border-color

    &:last-child
      border-bottom: none

    &:nth-child(2n)
      background: $theme-light-color

    &__title
      margin-bottom: 8px
      font-size: 18px
      line-height: 1.3
      font-weight: 600

    &__box
      display: flex
      gap: 8px

    &__text
      position: relative
      color: $theme-grey-color
      font-size: 16px

    &__icon
      display: inline
      width: 24px
      height: 24px
      filter: invert(68%) sepia(6%) saturate(901%) hue-rotate(153deg) brightness(89%) contrast(85%)
      
