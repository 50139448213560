.instruction
  .instruction-title
    width: 100%
    max-width: 800px
    margin: 0 auto 32px
    font-size: 24px
    text-align: center

  .instruction-steps
    display: grid
    grid-template-columns: 1fr
    row-gap: 32px
    max-width: 1030px
    margin: 0 auto
    list-style: none
    counter-reset: custom-counter

    img
      width: 100%

  .call-to-action
    margin-top: 50px

  .instruction-step
    counter-increment: custom-counter

  .instruction-step-content
    img
      margin-bottom: 20px
      
    .instruction-step-description
      margin-bottom: 11px

  .instruction-step-description
    position: relative
    display: flex
    align-items: center
    padding-left: 37px
    margin-bottom: 20px
    font-family: 'Archivo', sans-serif
    font-size: 16px
    font-weight: bold
    line-height: 20px
    letter-spacing: -0.02em

    &::before
      position: absolute
      left: 0
      display: flex
      align-items: center
      justify-content: center
      min-width: 28px
      height: 28px
      font-weight: 600
      line-height: 16px
      color: $theme-grey-color
      content: counter(custom-counter)
      background-color: $theme-light-color
      border: 1px solid rgba(135, 156, 165, 0.4)
      border-radius: 50%
    
  +screen-min(768px)
    &.instruction--with-space
      padding-bottom: 128px

    .instruction-title
      margin-bottom: 48px
      font-size: 36px

    .instruction-steps
      grid-template-columns: 1fr 1fr
      gap: 40px

    .instruction-step-description
      font-size: 18px

