.hero-security
  background-color: #fff

  .container
    flex-direction: column

  .hero-security-block
    display: flex
    flex-direction: column
    gap: 15px
    margin: 40px 0

    +screen-min(768px)
      flex-direction: row
      gap: 40px

  .hero-security-block-second
    flex-direction: column-reverse

    +screen-min(768px)
      flex-direction: row

  .hero-security-title
    flex: 1
    display: flex

    +screen-min(768px)
      justify-content: center
      align-items: center
      background: rgba(226, 235, 243, 0.3)
      border-radius: 40px

    h2
      font-size: 1.5rem

      +screen-min(768px)
        font-size: 2.25rem
        padding: 3rem

  .hero-security-message
    flex: 1
