.footer
  position: relative
  z-index: 10
  padding: 40px 32px 16px
  color: $text-color
  background: #ffffff
  font-family: 'Inter', sans-serif

  &-wrapper
    display: flex
    max-width: 1280px
    margin: 0 auto

  &-content
    flex: 1 1 auto

  &-navigation
    display: flex
    flex-direction: row
    margin-left: auto

  &-socials
    padding: 16px 0
    margin-top: 16px
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    gap: 8px 24px
    border-top: 1px solid $border-color
    border-bottom: 1px solid $border-color
    flex-wrap: wrap

  &-social-link
    display: flex
    align-items: center
    gap: 4px
    color: $text-tertiary-color
    font-size: 14px
    font-weight: 500
    text-decoration: none
    
    &:hover
      color: $text-tertiary-color
      opacity: 0.8

    +screen-max(1188px)
      span
        display: none

      &:last-child
        width: 100%
        display: flex
        justify-content: center

        img
          display: none

  &-copyright
    padding: 16px 0
    display: flex
    align-items: center
    justify-content: space-between
    gap: 4px 60px

    +screen-max(1188px)
      margin-top: 32px
      justify-content: center
      align-items: center
      flex-direction: column-reverse

    &__box
      text-align: center

  &-logo
    flex: 0 0 auto

    img
      width: 58px

  &-product
    margin-bottom: 8px
    font-size: 12px

    +screen-max(1188px)
      margin-bottom: 4px

    a
      text-decoration: none

  &-company
    font-size: 12px

  &-column
    flex: 1
    display: flex
    flex-direction: column
    padding-right: 32px
    max-width: 300px

    &:first-child
      max-width: 180px

    &:last-child
      max-width: 228px

    &__title
      margin: 0 0 16px
      font-size: 16px
      font-weight: 600
      color: $text-color
      letter-spacing: 0
      opacity: .8

      +screen-max(1188px)
        cursor: pointer
        display: flex
        align-items: center
        user-select: none
        
        &::after
          display: block
          content: ''
          background: url('~icons/chevron-down.svg')
          background-repeat: no-repeat
          color: $text-color
          margin-left: 4px
          width: 24px
          height: 24px
          filter: invert(8%) sepia(14%) saturate(4954%) hue-rotate(210deg) brightness(94%) contrast(113%)
          transition: 0.25s ease-in-out

        &.expanded
          &::after
            transform: rotate(180deg)

    &__expance
      color: $ui-primary-color
      line-height: 1.5
      font-size: 16px
      display: flex
      align-items: center
      cursor: pointer

      &.hidden
        display: none

      &:hover
        opacity: .8

      &::after
        display: block
        content: ''
        background: url('~icons/chevron-down.svg')
        background-repeat: no-repeat
        color: $text-color
        margin-left: 4px
        width: 24px
        height: 24px
        filter: invert(46%) sepia(43%) saturate(6811%) hue-rotate(169deg) brightness(101%) contrast(101%)
        transition: 0.25s ease-in-out

    &__expanced
      &.hidden
        display: none

    &__box
      +screen-max(1188px)
        max-height: 0
        overflow: hidden

    ul
      list-style: none
      flex: 1
      display: flex
      flex-direction: column

      li
        padding: 0 0 16px
        line-height: 1.5

      a
        font-size: 16px
        color: $text-tertiary-color
        text-decoration: none

      a:hover
        opacity: .5

    &:last-child
      padding-right: 0

  .footer-reward__wrap
    padding-bottom: 0

  .footer-reward
    margin-left: auto
    display: flex

  .footer-mobile-reward
    display: none

  +screen-between(1188px, 1271px)
    .footer-column
      &__title,
      a,
      .footer-column__expance
        font-size: 14px

  +screen-max(1188px)
    padding-top: 32px
    padding-bottom: 32px

    .footer-logo
      display: none

    .footer-navigation
      flex-wrap: wrap

    .footer-column
      max-width: 100%
      min-width: 50%
      margin-bottom: 24px

      &:nth-child(2n)
        padding-right: 0
        padding-left: 20px

      &:nth-child(2n-1)
        padding-right: 20px

      a
        font-weight: 400

    .footer-mobile-reward
      display: block

    .footer-reward
      display: none

    .footer-company
      order: 1
      text-align: center

    .footer-product
      display: flex
      flex-direction: column
      text-align: center

  +screen-max(720px)
    .footer-navigation
      flex-direction: column

    .footer-column
      &:nth-child(2n),
      &:nth-child(2n-1)
        padding-right: 0
        padding-left: 0

      &:last-child
        margin-bottom: 0
