.non-profit
  &__content
    text-align: center

  &__title
    font-size: 48px
    margin-bottom: 8px

    +screen-max(1023px)
      font-size: 40px

    +screen-max(1023px)
      font-size: 28px

  a
    text-decoration: none
    color: $ui-primary-color
