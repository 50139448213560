.img-highlights
  position: relative

  &--green
    .img-highlights__title
      color: $accent-green-color
      
    .img-highlights-accordion__progress-bar
      svg path
        stroke: $accent-green-color

  &--red
    .img-highlights__title
      color: $accent-red-color
      
    .img-highlights-accordion__progress-bar
      svg path
        stroke: $accent-red-color

  &--cyan
    .img-highlights__title
      color: $accent-cyan-color
      
    .img-highlights-accordion__progress-bar
      svg path
        stroke: $accent-cyan-color

  &--purple
    .img-highlights__title
      color: $accent-purple-color

    .img-highlights-accordion__progress-bar
      svg path
        stroke: $accent-purple-color

  &--reverse
    .img-highlights__row
      flex-direction: row-reverse

  &--big-image
    .img-highlights__box--img
      flex: 2
      max-width: 726px

  &--adaptive-image
    .img-highlights-img__wrap
      border-radius: 44px
      padding: 25px
      border: 1px solid $border-color

    .img-highlights-accordion__img-mobile
      +screen-max(1023px)
        border-radius: 24px
        padding: 18px
        border: 1px solid $border-color
        display: flex

    .img-highlights-accordion__img-mobile-wrap
      +screen-max(1023px)
        width: 100%
        padding-bottom: 61%
        position: relative

      +screen-max(550px)
        padding-bottom: 80%

      picture
        +screen-max(1023px)
          display: flex
          padding-bottom: 0

      img
        position: absolute
        top: 50%
        left: 50%
        transform: translateY(-50%) translateX(-50%)
        width: 100%
        height: 100%
        object-fit: contain

    .img-highlights-img__box
      width: 100%
      padding-bottom: 81%
      position: relative

    .img-highlights-img__wrap--cyan,
    .img-highlights-accordion__item--cyan .img-highlights-accordion__img-mobile
      background: linear-gradient(135deg, #DFF5FA 0.01%, #58BADE 100%)

    .img-highlights-img__wrap--red,
    .img-highlights-accordion__item--red .img-highlights-accordion__img-mobile
      background: linear-gradient(303deg, #E49292 0%, #EFD1CC 100%)

    .img-highlights-img__wrap--purple,
    .img-highlights-accordion__item--purple .img-highlights-accordion__img-mobile
      background: linear-gradient(303deg, #B97FDC 0%, #E9D7F3 100%)

    .img-highlights-img__wrap--green,
    .img-highlights-accordion__item--green .img-highlights-accordion__img-mobile
      background: linear-gradient(303deg, #93D49D 0%, #D5EFD9 100%)

    .img-highlights-img
      display: flex

      img
        position: absolute
        top: 50%
        left: 50%
        transform: translateY(-50%) translateX(-50%)
        width: 100%
        height: 100%
        object-fit: contain

  &__title
    text-align: center
    
  &__text
    margin-bottom: 16px
    text-align: center
    color: $text-secondary-color

    +screen-max(550px)
      margin-top: 8px

  &__row
    margin-top: 40px
    display: flex
    flex-wrap: wrap
    gap: 32px

    +screen-max(550px)
      margin-top: 24px

  &__box
    flex: 1

    &--img
      position: relative
      +screen-max(1023px)
        display: none

  &-img__wrap
    width: 100%
    opacity: 0
    left: 0
    right: 0
    position: absolute
    z-index: -1

    &.active
      opacity: 1
      position: relative
      z-index: 1

  &-img
    width: 100%
    height: 100%

    img
      width: 100%
      
  &-accordion
    &__item
      margin-bottom: 8px
      
      +screen-max(1023px)
        margin-bottom: 0

      &.img-highlights-accordion__item--border-top
        &:first-child
          button
            border-top: 1px solid $border-color
            border-bottom: none

      &--green
        .img-highlights-accordion__progress-bar
          svg path
            stroke: $accent-green-color

      &--red
        .img-highlights-accordion__progress-bar
          svg path
            stroke: $accent-red-color

      &--cyan
        .img-highlights-accordion__progress-bar
          svg path
            stroke: $accent-cyan-color

      &--purple
        .img-highlights-accordion__progress-bar
          svg path
            stroke: $accent-purple-color

    button
      position: relative
      display: block
      padding: 24px
      text-align: left
      width: 100%
      font-weight: 700
      border: none
      background: none
      border-radius: 5px 5px 0 0
      border-bottom: 1px solid $border-color

      +screen-max(1023px)
        padding: 20px 16px 16px

      &:hover,
      &:focus
        cursor: pointer

    .img-highlights-accordion__title
      position: relative
      padding-right: 48px
      font-size: 20px

      &:before
        position: absolute
        top: 50%
        right: 0
        content: ''
        background: url('~icons/chevron-down.svg')
        background-repeat: no-repeat
        color: $text-color
        margin-left: 4px
        width: 32px
        height: 32px
        background-repeat: no-repeat
        background-size: cover
        transform: translateY(-50%)
        transition: transform 0.25s linear

    button[aria-expanded='false']
      &:hover
        .img-highlights-accordion__title
          color: $text-color
          &::before
            filter: none
        
      + .img-highlights-accordion__content
        max-height: 0 !important

      .img-highlights-accordion__title
        color: $theme-grey-color

        &::before
          filter: invert(76%) sepia(6%) saturate(959%) hue-rotate(153deg) brightness(81%) contrast(80%)

    button[aria-expanded='true']
      padding-bottom: 16px
      background: $theme-light-color
      border: none

      .img-highlights-accordion__title
        &::before
          transform: rotate(180deg) translateY(50%)

      + .img-highlights-accordion__content
        opacity: 1
        will-change: opacity, max-height

    &__content
      opacity: 0
      max-height: 0
      overflow: hidden
      will-change: opacity, max-height
      background: $theme-light-color
      box-sizing: border-box
      
      p
        +screen-max(1023px)
          font-size: 14px

    &__box
      position: relative
      padding: 0 24px 24px
      border-radius: 0 0 5px 5px
      overflow: hidden

      +screen-max(1023px)
        padding: 0 16px 16px

      p
        font-size: 16px

    &__progress-bar
      position: absolute
      left: 0
      bottom: 0
      width: 100%
      height: 5px

      svg
        position: absolute

        path
          &:first-child
            opacity: .2

    &__img-mobile
      display: none

      +screen-max(1023px)
        position: relative
        display: block
        margin: 16px auto 0
        max-width: 500px
        width: 100%

        picture
          display: block
          width: 100%
          padding-bottom: 81%

        img
          position: absolute
          top: 50%
          left: 50%
          transform: translateY(-50%) translateX(-50%)
          width: 100%
          height: 100%
          object-fit: contain
