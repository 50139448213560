.pricing-table-popular
  position: relative
  display: grid
  grid-template-columns: 350px 1fr 1fr 1fr 1fr 1fr
  z-index: 1

  +screen-max(1280px)
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr

  +screen-max(720px)
    display: none

  &__cell
    +screen-max(1280px)
      &:first-child
        display: none
    &--popular
      background-color: $accent-green-color
      font-size: 12px
      line-height: 1.3
      font-weight: 400
      padding: 6px 12px 4px
      border-radius: 5px 5px 0 0
      color: #fff
      text-align: center

.pricing-table
  position: relative
  margin-bottom: 58px
  display: flex
  flex-direction: column
  border-collapse: collapse
  width: 100%
  border-radius: 12px
  border: 1px solid $ui-primary-color
  z-index: 1

  +screen-max(1280px)
    border-color: $border-color
    border-radius: 0

  &-header
    text-align: center
    margin-top: 64px
    margin-bottom: 32px

  &> .pricing-table-row
    &:nth-child(2)
      +screen-min(1281px)
        margin-top: -50px // prevent sticky header border collapsing

  &-row
    display: grid
    grid-template-columns: 350px 1fr 1fr 1fr 1fr 1fr
    grid-template-rows: auto

    &.pricing-table-row--full-width
      grid-template-columns: 350px 1fr

      +screen-max(1280px)
        grid-template-columns: 1fr

    &.pricing-table-row--header
      position: sticky
      z-index: 1
      top: 44px

      +screen-min(1024px)
        top: 76px

      +screen-min(1281px)
        margin-bottom: 50px  // prevent sticky header border collapsing

      +screen-max(1280px)
        grid-template-columns: repeat(5, 1fr)
        grid-template-rows: 0 auto

      .pricing-table-cell
        &:first-child
          font-size: 20px
          font-weight: 700
          display: flex
          justify-content: center
          color: $text-color

    &:nth-child(2n):not(&-row:last-child)
      .pricing-table-cell
        background: rgba(247, 250, 251)

    &.pricing-table-row--last
      .pricing-table-cell
        border-bottom: none

        &:first-child
          border-radius: 0 0 0 12px

          +screen-max(1280px)
            border-bottom: 1px solid $border-color
            border-radius: 0

        &:last-child
          border-radius: 0 0 12px 0

  &> .pricing-table-row
    &:first-child
      .pricing-table-cell
        &:first-child
          border-radius: 12px 0 0 0
        &:last-child
          border-radius: 0 12px 0 0

  .pricing-table-box:not(.pricing-table-box--fourths)
    .pricing-table-row
      &:nth-child(2n)
        .pricing-table-cell
          background: #ffffff

      &:nth-child(2n - 1)
        .pricing-table-cell
          background: rgba(247, 250, 251)

    +screen-max(1280px)
      &.pricing-table-box--first,
      &.pricing-table-box--second,
      &.pricing-table-box--third
        .pricing-table-row
          &:nth-child(2n - 1):not(&-row:last-child)
            .pricing-table-cell
              background: rgba(247, 250, 251)

          &:nth-child(2n):not(&-row:last-child)
            .pricing-table-cell
              background: #ffffff

      .pricing-table-row
        &:nth-child(2n - 1):not(&-row:last-child)
          .pricing-table-cell
            background: #ffffff

        &:nth-child(2n):not(&-row:last-child)
          .pricing-table-cell
            background: rgba(247, 250, 251)

  .pricing-table-toggle
    padding: 48px 16px 16px
    flex: 1
    font-size: 20px
    font-weight: 700
    position: relative
    display: flex
    align-items: center
    border-bottom: 1px solid $border-color
    cursor: pointer
    user-select: none
    background: #ffffff

    +screen-max(720px)
      display: none

    &::before
      display: block
      content: ''
      background: url('~icons/chevron-down.svg')
      background-repeat: no-repeat
      margin-right: 4px
      width: 24px
      height: 24px
      filter: invert(42%) sepia(54%) saturate(4151%) hue-rotate(168deg) brightness(99%) contrast(101%)
      transition: 0.25s ease-in-out

    &.expanded
      &::before
        transform: rotate(-90deg)

    &--last
      &.expanded
        border-radius: 0 0 12px 12px
        border-bottom: 0

  .pricing-table-box
    overflow: hidden

    &--sixth
      .pricing-table-cell
        min-height: 80px

  .pricing-table-cell
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    text-align: center
    padding: 16px
    background: #fff
    border-bottom: 1px solid #879CA53D
    border-right: 1px solid #879CA53D

    &:first-child
      justify-content: flex-start
      align-items: flex-start
      text-align: left

      +screen-min(1281px)
        padding-left: 44px

      +screen-max(1280px)
        align-items: center

    +screen-max(1280px)
      text-align: center
      padding: 16px 8px

      li
        text-align: center

    a
      color: $ui-primary-color
      text-decoration: none

    .button
      color: #ffffff
      padding: 9px 5px
      font-size: 12px
      min-height: 42px
      line-height: 1.2
      white-space: normal

    .button-outlined
      color: $ui-primary-color

    &__check
      filter: invert(48%) sepia(51%) saturate(7277%) hue-rotate(171deg) brightness(103%) contrast(101%)

    &-icons
      &__sources
        margin-bottom: 6px
        display: flex
        flex-wrap: wrap
        border-radius: 4px
        gap: 6px
        max-width: 232px

      &__source
        width: 32px
        height: 32px
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        border-radius: 4px

        &:hover
          background-color: $bg-blue-color

        img
          width: 100%
          max-width: 20px

    ul
      li
        margin-bottom: 4px

    &__desc
      font-size: 14px

    &--sources
      span
        font-size: 14px

    &.pricing-table-cell--plan
      padding: 0

      &:last-child
        padding-right: 0

    &__sources
      display: flex
      flex-wrap: wrap
      gap: 8px
      justify-content: center
      align-items: center

      &-expand
        margin-top: 12px
        display: flex
        align-items: center

        &--hidden
          display: none

        &:hover
          &::before
            filter: invert(32%) sepia(94%) saturate(704%) hue-rotate(158deg) brightness(93%) contrast(96%)

        &::before
          content: ""
          display: block
          background: url('~icons/chevron-down.svg')
          background-repeat: no-repeat
          background-position: center
          margin-left: 4px
          width: 32px
          height: 32px
          filter: invert(41%) sepia(88%) saturate(918%) hue-rotate(160deg) brightness(93%) contrast(101%)
          transition: all 0.2s ease-in-out

    &__source
      display: flex
      align-items: center
      gap: 4px
      padding-right: 16px

      &--hidden
        display: none

      img
        width: 32px
        min-width: 32px
        height: 32px

    &__info
      display: flex
      align-items: flex-start

      img
        width: 18px
        height: 18px
        margin-right: 8px
        margin-top: 2px

      span
        line-height: 1.43
        color: $theme-grey-color

    &:last-child
      .pricing-table-cell__info
        display: none

    &:nth-child(1)
      color: rgba(0, 0, 0, 0.7)

      h5
        font-size: 16px
        font-weight: 600
        display: flex
        align-items: center
        margin-bottom: 4px
        color: #000

        img
          width: 26px
          height: 26px
          margin-right: 4px

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5)
      flex: 1
      display: flex
      flex-direction: column
      justify-content: center

    &:last-child
      border-right: 0
      padding-right: 17px

      +screen-max(1280px)
        padding-right: 9px

    @each $plan-id, $plan-color in $billing-plan-colors
      &.#{$plan-id}
        .pricing-table-plan__name
          color: $plan-color

        .pricing-table-plan
          &::before
            @if $plan-color == $dark-plan-color
              background: linear-gradient(303deg, #C5C5C5 0%, #FFFFFF 100%)

            @if $plan-color == $orange-plan-color
              background: linear-gradient(315deg, #EADAB2 0%, #FFF9EA 100%)

            @if $plan-color == $red-plan-color
              background: linear-gradient(315deg, #FBCACA 0%, #FFECEC 100%)

            @if $plan-color == $green-plan-color
              background: linear-gradient(315deg, #BEEFC5 0%, #F8FFF9 100%)

            @if $plan-color == $blue-vivid-plan-color
              background: linear-gradient(315deg, #B2DEEA 0%, #F5FDFF 100%)

    .pricing-table-cell__tooltip-title
      height: 100%
      display: inline-flex
      align-items: center
      gap: 8px

      h5
        margin-bottom: 0

      img
        width: 24px
        min-width: 24px
        height: 24px
        cursor: pointer

  &-plan
    position: relative
    padding: 16px
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    z-index: 1

    +screen-max(1280px)
      padding: 16px 8px

    &::before
      content: ""
      z-index: -1
      position: absolute
      top: 0
      right: 0
      width: 126px
      height: 110px
      border-radius: 166px
      background: linear-gradient(315deg, #DFC7EE 0%, #FBF5FF 100%)
      filter: blur(75px)
      transform: translate3d(0, 0, 0) translate(50%, -50%)

    &__name
      margin-bottom: 0
      font-weight: 600

    &__description
      flex: 1
      margin-top: 6px
      color: $text-tertiary-color
      font-size: 13px
      line-height: 1.5

    &__price
      margin-top: 4px
      margin-bottom: 0
      font-family: 'Archivo', sans-serif
      font-size: 24px
      font-weight: 600
      line-height: 1

      span
        font-size: 20px
        font-weight: 500
        line-height: 32px

    &__action
      margin-top: 8px

  p
    font-size: 12px

  +screen-max(1280px)
    &-row
      grid-template-columns: repeat(5, 1fr)
      grid-template-rows: auto auto

    &-row:first-child &-cell
      border-bottom-color: #879CA53D

    &-cell
      border-right: 0

      h5
        img
          display: none

      &:nth-child(1)
        grid-column-end: span 5
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        border-right: 0

      &__desc
        text-align: center

    &-plan__name
      font-size: 20px

  +screen-max(767px)
    &-row
      .button
        font-size: 11px

  +screen-max(720px)
    &,
    &-header
      display: none
