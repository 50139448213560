.plans-section
  position: relative
  z-index: 2
  padding: 56px 0

  +screen-max(550px)
    padding: 32px 0

  &.plans-section--table
    margin-top: -1100px
    padding: 0
    padding-bottom: 58px

    +screen-max(1024px)
      padding-bottom: 32px
    
    +screen-max(640px)
      margin-top: -800px

    .container
      +screen-max(850px)
        padding: 0 16px

  &__title
    margin-bottom: 34px
    text-align: center

  .interval-toggle-switch
    margin-bottom: 50px
    display: flex
    width: fit-content
    align-self: center
    padding: 2px
    background-color: $ui-primary-color
    border-radius: 100px
    color: #fff

    +screen-max(1280px)
      margin-bottom: 40px

    +screen-max(550px)
      width: 100%
      margin-bottom: 32px

    li
      display: flex
      justify-content: center
      align-items: center
      padding: 6px 27px
      min-width: 150px
      height: 44px
      font-size: 18px
      line-height: 1.7
      font-weight: 500
      border-radius: 50px
      cursor: pointer
      text-align: center

      +screen-max(550px)
        flex: 1
        min-width: auto
        font-size: 16px
        padding: 6px 15px
        white-space: nowrap

      &.active
        color: $ui-primary-color
        background: #fff
        cursor: default

