.onboarding-wrapper
  max-width: 320px
  height: 100%

  &.completed
    .onboarding-description
      max-width: 180px

.onboarding-progress
  display: flex
  align-items: center
  padding-bottom: 25px

  .onboarding-description
    max-width: 150px
    padding-left: 25px
    margin: 0
    font-size: 18px

.onboarding-title
  margin-bottom: 30px

ol.onboarding-list
  list-style: none
  counter-reset: custom-counter

  .onboarding-item
    position: relative
    padding-left: 34px
    margin-bottom: 15px
    line-height: 24px
    counter-increment: custom-counter

    &:last-child
      margin-bottom: 0

    &::before
      position: absolute
      left: 0
      box-sizing: border-box
      display: flex
      align-items: center
      justify-content: center
      width: 24px
      height: 24px
      font-size: 15px
      font-weight: 500
      color: #879ca5
      text-align: center
      content: counter(custom-counter)
      background-color: $theme-light-color
      border: 1px solid #cad4d9
      border-radius: 50%

    &.is-checked
      &::before
        content: ''
        background-color: unset
        background-image: url('~icon-checked.svg')
        background-repeat: no-repeat
        background-size: 100% 100%
        border: 0

  .onboarding-item-signup
    margin-bottom: 30px

.onboarding-call
  &-title
    margin: 20px 0 15px

  &-wrapper
    display: flex
    align-items: center

  &-img
    display: block
    width: 36px
    height: 36px
    margin-right: 10px
    border-radius: 50%

  &-link
    font-size: 14px
    font-weight: 500
