.icon-card
  padding: 24px
  display: flex
  flex-direction: column
  align-items: flex-start
  background: #FFFFFF
  border: 1px solid $border-color
  box-shadow: 0px 2px 8px $box-shadow-color
  border-radius: 24px

  &__box
    width: 100%
    gap: 0 8px
    flex-direction: row-reverse
    align-items: center
    justify-content: space-between

    +screen-max(550px)
      display: flex
      gap: 0 10px

  &__icon
    margin-bottom: 12px
    min-width: 64px
    width: 64px
    height: 64px

    +screen-max(550px)
      min-width: 48px
      width: 48px
      height: 48px

    &--border
      border-radius: 12px
      border: 1px solid $border-color

  &__number
    margin-bottom: 12px
    width: 40px
    min-width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 100%
    background: $accent-cyan-color
    color: #ffffff
    font-size: 20px
    font-weight: 700

    +screen-max(550px)
      min-width: 48px
      width: 48px
      height: 48px
    
  &-from-to
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 12px

    +screen-max(550px)
      gap: 4px

    &__icon
      min-width: 64px
      width: 64px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 12px
      background: #ffffff
      border: 1px solid $border-color
      overflow: hidden

      +screen-max(550px)
        min-width: 48px
        width: 48px

      +screen-max(420px)
        min-width: 42px
        width: 42px

      img
        display: flex
        width: 100%
        height: 100%

    .icon-card-from-to__arrow
      width: 24px
      min-width: 24px
      height: 24px
      filter: invert(49%) sepia(66%) saturate(2677%) hue-rotate(162deg) brightness(89%) contrast(101%)

  .gradient-icon--cyan,
  .gradient-icon--purple,
  .gradient-icon--red,
  .gradient-icon--green
    border-radius: 12px

    img
      width: 32px

      +screen-max(550px)
        width: 24px

  .icon-card__title
    margin-bottom: 12px
    font-size: 24px
    font-weight: 700
    line-height: 1.2

  &__label
    margin-bottom: 12px
    padding: 4px 8px
    border-radius: 12px
    background: $accent-cyan-color
    color: #ffffff
    font-size: 14px
    line-height: 1.7
    font-weight: 500
    display: inline-block

    +screen-max(550px)
      font-size: 12px

  .icon-card__text
    flex: 1
    margin-bottom: 0
    font-size: 16px
    color: $text-tertiary-color

    b
      font-weight: 600
      color: $text-color

    +screen-max(768px)
      font-size: 14px

  .icon-card__link
    margin-top: 21px
    font-size: 16px
