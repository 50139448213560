.sources-slider
  &__title
    text-align: center
    margin-bottom: 16px

    +screen-max(768px)
      margin-bottom: 8px
  
  &__text
    text-align: center
    margin: 0 auto 30px
    max-width: 1000px
    color: $text-secondary-color
      
    +screen-max(768px)
      margin-bottom: 12px

  .sources-slider__container
    padding: 0

  &__wrapper,
  &__wrapper-reverse
    width: 100%
    overflow: hidden
    position: relative

    &::before,
    &::after
      content: ''
      position: absolute
      top: 0
      bottom: 0
      width: 152px
      z-index: 2
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.13%)

      +screen-max(768px)
        width: 43px

    &::before
      left: -35px

    &::after
      right: -35px
      transform: scale(-1, -1)

  &__actions
    display: flex
    justify-content: center
    margin-top: 42px

    +screen-max(550px)
      margin-top: 22px

  &__list
    transition-timing-function: linear
    padding: 10px 0

    +screen-max(768px)
      padding: 4px 0

  &-item 
    padding: 0 8px
    width: auto
    display: flex
    align-items: center

    +screen-max(768px)
      padding: 0 6px

    &__wrapper
      position: relative
      width: 100%
      padding: 12px 16px
      display: flex
      align-items: center
      gap: 4px
      text-decoration: none
      color: $text-color
      border: 1px solid $border-color
      border-radius: 32px
      white-space: nowrap

      +screen-max(768px)
        border-radius: 100%
        padding: 0

      &::before
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        box-shadow: 0px 2px 8px $box-shadow-color
        border-radius: 32px

      &:hover
        background: $bg-blue-color
        border: 1px solid $ui-primary-color
        color: $text-color

        &::before
          box-shadow: 0px 4px 8px rgba(0, 155, 205, 0.24)

    &__icon
      min-width: 40px
      width: 40px
      height: 40px

    &__title
      font-weight: 600
      margin: 0

      +screen-max(768px)
        display: none

