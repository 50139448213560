.plans-grid
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-template-rows: 1fr
  column-gap: 20px
  row-gap: 20px
  margin-top: 20px
  margin-bottom: 64px

  +screen-max(1280px)
    grid-template-columns: repeat(2, 1fr)

  +screen-max(640px)
    grid-template-columns: repeat(1, 1fr)
    margin-bottom: 0

    .plan.most-popular
      margin-top: 20px

  .column
    margin-bottom: 0

    &.custom-column
      height: fit-content
