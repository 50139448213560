.two-column-block
  background: $theme-light-color
  
  &.light
    background: #ffffff

  &__title
    text-align: center
    margin-bottom: 24px

  &__content
    display: flex
    gap: 56px
    justify-content: space-between

    +screen-max(768px)
      flex-direction: column

  &__box
    flex: 1
    color: $text-secondary-color
    font-size: 16px
    line-height: 1.5
