.card
  position: relative
  padding: 15px
  background: #fff
  border-radius: 5px
  box-shadow: 0 2px 8px #e1ebf1

  +screen-min(375px)
    padding: 20px

  +screen-min(680px)
    padding: 30px

  .column &
    height: 100%

.card-blue
  background-color: $theme-light-color
  border: 1px solid #e7ebee
  box-shadow: none

.card-header
  display: flex
  align-items: center
  justify-content: space-between
