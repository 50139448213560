.signup-quote
  &__content
    padding: 48px 48px 48px 56px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    gap: 40px
    border-radius: 40px
    border: 1px solid rgba(18, 186, 247, 0.12)
    background: $bg-blue-color
    overflow: hidden
    position: relative
    z-index: 1

    +screen-max(1023px)
      flex-direction: column
      padding: 72px 32px

    +screen-max(550px)
      padding: 24px 16px
      gap: 28px

    &::before,
    &:after
      content: ""
      position: absolute
      display: block
      filter: blur(200px)
      transform: translate3d(0, 0, 0)
      z-index: -1
      border-radius: 620px

    &::before
      width: 620px
      height: 483px
      background: #FFF4CE
      left: -20px
      bottom: -364px

      +screen-max(1023px)
        bottom: -266px
        left: -220px

      +screen-max(550px)
        width: 386px
        height: 301px
        left: -50px
        bottom: -44px

    &::after
      width: 619px
      height: 620px
      background: #CCF3FF
      right: -82px
      top: -100px

      +screen-max(1023px)
        top: auto
        right: -402px
        bottom: -185px

      +screen-max(550px)
        display: none

  &__box--quote
    &::before
      content: ""
      position: absolute
      top: 20px
      left: 32px
      display: block
      width: 217px
      height: 188px
      background: url(~quote-ico-bg.svg) no-repeat center
      background-size: contain

      +screen-max(1023px)
        display: none

    +screen-max(1023px)
      text-align: center
      max-width: 100%

      &::before
        display: none

  &__ico
    margin-bottom: 16px
    width: 32px
    height: 32px
    background: url(~quote-ico.svg) no-repeat center

    +screen-max(1023px)
      margin: 0 auto 16px

  .signup-quote__text
    font-family: 'Archivo', sans-serif
    position: relative
    color: $accent-blue-dark-color
    font-size: 32px
    line-height: 1.5
    font-weight: 400
    margin-bottom: 16px
    z-index: 2

    +screen-max(550px)
      font-size: 18px

    b
      font-weight: 600

  .signup-quote__title
    color: $accent-blue-dark-color

    +screen-max(1023px)
      text-align: center

  &-author
    display: flex
    align-items: center
    padding: 16px 0
    gap: 8px 16px

    +screen-max(1023px)
      display: flex
      justify-content: center
      flex-direction: column
    
    &__img-wrap
      position: relative

    &__img
      width: 64px
      min-width: 64px
      height: 64px
      border-radius: 50%
      border: 1px solid rgba(88, 169, 100, 0.12)

    &__name
      margin-bottom: 4px
      font-size: 20px
      font-weight: 600
      line-height: 1.2

      +screen-max(550px)
        font-size: 16px

    &__position
      font-size: 16px
      line-height: 1.2
    