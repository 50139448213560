$header-z-index: 99999

.header
  position: sticky
  top: 0
  z-index: $header-z-index
  display: flex
  height: 88px
  background-color: #ffffff
  transition: all .2s ease-in

  &--transparent
    background: transparent

    +screen-max(1024px)
      background-color: #ffffff

  .container
    width: 100%
    flex-direction: row
    align-items: center

  .logo
    width: 164px
    height: 40px
    text-indent: -9999px
    background: url('~coupler.svg') no-repeat center
    background-repeat: no-repeat
    background-size: contain

  .hamburger
    &-wrapper
      display: none

    &.open .line
      background-color: $theme-primary-color

  +screen-max(1023px)
    justify-content: space-between
    height: 62px

    .hamburger-wrapper
      display: flex
      margin-left: auto
