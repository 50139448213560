.call-to-action
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  gap: 20px 10px

  +screen-max(768px)
    flex-direction: column
    gap: 20px 0
