.hero-destinations
  position: relative
  margin-top: 40px
  padding-top: 40px
  padding-bottom: 350px
  background-image: url('~geometric-bg-destinations.svg')
  background-repeat: no-repeat
  background-position: top
  background-size: auto
  overflow: hidden

  +screen-max(768px)
    padding-bottom: 250px

  +screen-max(550px)
    margin-top: 0
    padding-bottom: 200px

  .highlighted
    +screen-max(768px)
      color: $text-color

  &-title
    font-size: 48px
    text-align: center

    +screen-max(768px)
      font-size: 36px

    +screen-max(550px)
      font-size: 24px

  &-text
    margin: 0 auto 80px
    font-size: 24px
    text-align: center
    max-width: 1068px

    +screen-max(550px)
      font-size: 18px

  &-illustration
    position: absolute
    bottom: -70px
    left: 50%
    transform: translateX(-50%)

    +screen-max(1024px)
      height: 350px
      width: auto
      bottom: 20px

    +screen-max(768px)
      height: 250px

    +screen-max(550px)
      height: 200px

+screen-max(1024px)
  .hero-features
    &-illustration
      img
        max-width: 768px

+screen-max(850px)
  .hero-features
    padding: 10px 0 40px

    &-illustration
      position: static
      padding-bottom: 35px
      transform: unset

      img
        width: 100%
        max-width: unset

+screen-max(768px)
  .hero-features
    background-image: url('~features/features-hero-mobile-bg.png')
    background-size: contain

    &-illustration
      display: none

    &-title
      margin-bottom: 12px
      font-size: 24px

    &-text
      margin-bottom: 16px
      font-size: 18px
