.doc-page
  max-width: 1000px
  width: 100%
  margin: 50px auto
  padding: 50px
  box-sizing: border-box
  background: #fff
  overflow: hidden

  +screen-max(850px)
    width: calc(100% - 10px)
    margin: 0 5px 50px 5px
    padding: 30px 10px


  table
    overflow-x: auto
    display: block

  h1, h2, h3, h4, h5, h6
    font-weight: 600

    span
      font-weight: 600

  h1
    text-align: center !important
    font-size: 26pt !important

    span
      text-align: center !important
      font-size: 26pt !important
