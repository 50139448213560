.popular-sources
  &__title
    margin-bottom: 24px
    text-align: center
  
  &__list
    display: grid
    grid-template-columns: repeat(4, 1fr)

    +screen-max(1200px)
      grid-template-columns: repeat(3, 1fr)

    +screen-max(1024px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(768px)
      grid-template-columns: repeat(1, 1fr)

  .integration-card
    height: 100%
    
    .integration-card__text
      color: $text-disabled-color
      font-size: 14px
      font-weight: 400

  &__action
    margin-top: 28px
    display: flex
    justify-content: center
