.similar-sources-carousel
  background: #ffffff
  margin-bottom: -10px

  &.similar-sources-carousel--simple-cards
    .similar-sources-carousel__list
      max-height: none

    .swiper-slide
      height: auto

    .integration-card
      &:hover
        border: 1px solid $border-color

      .integration-card__box
        max-width: 100%

      .integration-card__title
        margin-bottom: 12px
        white-space: normal
        font-weight: 700
        font-size: 24px
        line-height: 1.5

      .integration-card__text
        white-space: normal
        font-size: 16px
        line-height: 1.75
        color: $text-tertiary-color

  &.similar-sources-carousel--horizontal
    .similar-sources-carousel__list
      display: grid
      grid-template-columns: 1fr 1fr 1fr

      +screen-max(1023px)
        grid-template-columns: 1fr 1fr
      
      +screen-max(749px)
        grid-template-columns: 1fr

    .similar-sources-carousel__controls
      opacity: 0
      z-index: -1

  .container
    overflow: hidden

  &__header
    margin-bottom: 32px
    display: flex
    align-items: center
    justify-content: space-between
    gap: 20px

    +screen-max(700px)
      margin-bottom: 24px

  &__title
    +screen-max(700px)
      text-align: center

  &__controls
    +screen-max(700px)
      display: none

    &--disable
      display: none

  &__slider
    position: relative
    padding-bottom: 10px

    &::before
      content: ""
      position: absolute
      width: 112px
      top: 0
      right: -64px
      bottom: 0
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
      z-index: 2

      +screen-max(700px)
        width: 35px
        right: -16px

  &__list
    margin: -8px
    max-height: 220px

    .integration-card__title,
    .integration-card__text
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
