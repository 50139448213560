.text-block
  &--full-width
    .text-block__content
      max-width: 100%

  &--border-top
    .section
      border-top: 1px solid $border-color

  &__content
    text-align: center
    max-width: 1000px
    margin: 0 auto

    h2
      margin-bottom: 16px

    p
      font-size: 16px

      b
        font-weight: 600
