body,
input,
button,
textarea
  font-family: 'Inter', sans-serif
  font-size: 16px
  font-weight: 400
  line-height: 1.5
  color: $text-color

button
  color: currentColor

h1,
h2,
h3,
h4,
h5,
.h2,
.h3
  font-family: 'Archivo', sans-serif
  line-height: 1.2
  font-weight: 700

h1,
.title-size-xl
  margin-bottom: 20px
  font-size: 56px
  font-weight: 700

  sup
    font-size: 46%

  &.centered
    max-width: 700px

h1,
h2,
.h2
  &.centered
    margin: 0 auto 50px
    text-align: center

h2,
.h2,
.title-size-l
  font-size: 40px

  &.increased
    font-size: 48px

h3,
.h3,
.title-size-m
  font-size: 24px

  &.centered
    margin-left: auto
    margin-right: auto
    text-align: center

h4,
.title-size-s
  margin-bottom: 20px
  font-size: 24px
  font-weight: 700

h5
  font-size: 20px
  font-weight: 400

h6
  font-size: 14px
  font-weight: 700
  text-transform: uppercase
  letter-spacing: 2px

strong
  font-weight: 500

a
  color: $theme-primary-color

  &:hover
    color: lighten($theme-primary-color, 5%)

  &.danger
    color: $accent-red-color

    &:hover
      color: lighten($accent-red-color, 5%)

  &.chevron-right
    
    &::after
      display: inline-block
      width: 10px
      height: 10px
      margin-left: 4px
      content: ''
      background-image: url('~chevron.svg')
      background-repeat: no-repeat
      background-size: contain

p
  margin-bottom: 15px

  &.increased
    font-size: 24px

small
  font-size: 14px

.increased
  font-size: 18px

.highlighted
  color: $theme-primary-color

.muted
  color: $text-disabled-color

@media screen and (max-width: 1024px)
  h1,
  .title-size-xl
    font-size: 48px


@media screen and (max-width: 950px)
  h2,
  .title-size-l
    &.increased
      font-size: 36px

@media screen and (max-width: 768px)
  body,
  input,
  button,
  textarea
    font-size: 14px

  h1,
  .title-size-xl
    font-size: 32px

  h2,
  .title-size-l
    font-size: 24px

    &.increased
      font-size: 32px

@media screen and (max-width: 475px)
  p
    &.increased
      font-size: 20px


.danger
  color: rgba(222, 89, 70, 1)

.success
  color: rgba(37, 167, 58, 1)
