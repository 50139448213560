.hero-features
  position: relative
  padding: 62px 0 300px
  background-image: url('~features/features-hero-bg.png')
  background-repeat: no-repeat
  background-position: center
  background-size: cover

  &-title
    font-size: 48px
    text-align: center

  &-text
    max-width: 800px
    margin: 0 auto 32px
    font-size: 24px
    text-align: center

  &-illustration
    position: absolute
    top: 55%
    left: 50%
    transform: translateX(-50%)

    img
      max-width: 975px

+screen-max(1024px)
  .hero-features
    &-illustration
      img
        max-width: 768px

+screen-max(850px)
  .hero-features
    padding: 40px 0 40px

    &-illustration
      position: static
      padding-bottom: 35px
      transform: unset

      img
        width: 100%
        max-width: unset

+screen-max(768px)
  .hero-features
    background-image: url('~features/features-hero-mobile-bg.png')
    background-size: contain

    &-illustration
      display: none

    &-title
      margin-bottom: 12px
      font-size: 24px

    &-text
      margin-bottom: 16px
      font-size: 18px
