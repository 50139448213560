.collapsable
  h5
    position: relative
    cursor: pointer
    padding-left: 23px

    &::before
      position: absolute
      top: 50%
      left: 0
      content: ''
      width: 5px
      height: 9px
      background-image: url('~chevron.svg')
      background-repeat: no-repeat
      background-size: 100% 100%
      transform: rotate(90deg) translateX(-50%) translateY(-50%)
      transition: transform 0.25s linear

  .content
    overflow-y: hidden
    padding-left: 23px
    font-size: 13px
    max-height: 400px
    transition: max-height 0.25s ease-out

  &.collapsed
    h5
      &:before
        transform: rotate(0) translateX(0%) translateY(-50%)

    .content
      transition: max-height 0.25s ease-out
      max-height: 0
