.compare
  &.section
    padding-bottom: 0

  &__title
    margin-bottom: 40px
    text-align: center

  &-table
    margin-left: auto
    margin-right: auto
    max-width: 850px
    display: flex
    flex-direction: column
    border-collapse: collapse
    width: 100%
    border-radius: 12px
    border: 1px solid $border-color

    &__row
      display: grid
      grid-template-columns: 1fr 180px 180px
      grid-template-rows: auto

      +screen-max(1023px)
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: auto auto

      &:first-child
        .compare-table__cell
          height: 60px
          
          &::before
            border-top: 2px solid $ui-primary-color
          
          +screen-max(1023px)
            height: 71px

            &:first-child
              display: none
            
      &:last-child
        .compare-table__cell
          border-bottom: none

          +screen-max(1023px)
            &:first-child
              border-bottom: 1px solid $border-color

          &::before
            border-bottom: 2px solid $ui-primary-color

      &:nth-child(2n)
        background-color: $bg-blue-color

    &__cell
      padding: 10px 16px
      min-height: 56px
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      border-bottom: 1px solid $border-color

      +screen-max(1023px)
        min-height: 64px
        border-left: 1px solid $border-color
        
        &:first-child
          min-height: 56px
          grid-column-end: span 4
          width: 100%
          border-left: none

        &:nth-child(2)
          border-left: none

      &:nth-child(2)
        position: relative

        &::before
          content: ""
          position: absolute
          left: 0
          right: 0
          top: -1px
          bottom: -1px
          border-left: 2px solid $ui-primary-color
          border-right: 2px solid $ui-primary-color
          z-index: 1

          +screen-max(1023px)
            display: none

    &__title
      position: relative
      z-index: 2
      width: 100%
      font-size: 16px
      font-weight: 600
      line-height: 1.5 

      +screen-max(1023px)
        text-align: center

    &__name
      position: relative
      z-index: 2
      font-size: 16px
      font-weight: 600
      color: $text-color

      &--brand
        font-size: 20px
        color: $accent-cyan-color

        +screen-max(1023px)
          font-size: 16px

    &__text
      text-align: center
      position: relative
      z-index: 2
      font-size: 16px
      line-height: 1.1

      +screen-max(1023px)
        text-align: center

      b
        font-size: 18px
        font-weight: 600

      &--small
        font-size: 12px

        b
          font-size: inherit

    &__rating
      position: relative
      z-index: 2
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      gap: 12px

      .rating-customer-mark
        &:last-child
          .rating-customer-mark__img
            height: 21px
            margin-right: 2px

            +screen-max(380px)
              height: 16px

        +screen-max(380px)
          .rating-customer-mark__img
            height: 16px
        
