.features-list
  padding: 250px 0 60px

  +screen-max(1024px)
    padding-top: 150px

  +screen-max(850px)
    padding-top: 60px

  +screen-max(768px)
    padding: 40px 0

  &.home-page
    padding: 30px 0 58px

    +screen-max(1024px)
      padding: 48px 0

    +screen-max(850px)
      padding: 48px 0

    +screen-max(768px)
      padding: 40px 0

    .features-list__title
      +screen-max(768px)
        font-size: 28px

    .features-item
      padding-top: 70px

    .features-item__title
      font-size: 36px

      +screen-max(1024px)
        font-size: 24px
        letter-spacing: -0.02em

    .features-item__separator
      margin: 16px 0

      +screen-max(768px)
        margin: 16px 0
        
  &.space-top
    padding: 72px 0 58px

  &__title
    max-width: 800px
    margin: 0 auto
    font-size: 48px
    text-align: center

    +screen-max(768px)
      font-size: 24px

.features-item
  display: flex
  align-items: center
  padding-top: 100px
  justify-content: space-between

  &:nth-child(even)
    flex-direction: row-reverse

    +screen-max(768px)
      flex-direction: column

    .features-item__info
      margin-left: 24px
      margin-right: 0

      +screen-max(768px)
        margin-left: 0
  
  +screen-max(768px)
    flex-direction: column
    padding-top: 60px

  em
    +screen-max(768px)
      font-size: 14px

  &__list
    list-style: disc
    padding-left: 23px
    
    .features-item__item
      .features-item__text
        margin-bottom: 0

      &:last-child
        margin-bottom: 15px

  &__info
    width: 100%
    max-width: 600px
    margin-right: 24px

    +screen-max(768px)
      margin: 0 0 30px

  &__counter
    font-size: 18px
    color: $theme-primary-color

    +screen-max(768px)
      position: relative
      display: block
      overflow: hidden

      &::after
        position: absolute
        top: 50%
        width: 100%
        margin-left: 8px
        content: ''
        border-top: 1px solid $theme-primary-color
        opacity: 0.2
        transform: translateY(-50%)

  &-video
    position: relative
    display: flex

    &:hover
      .features-item-video__ico
        transform: translate(-50%, -50%) scale(1.1)

    &__wrap
      &> div
        width: 100% !important
        height: auto !important

    &__ico
      content: ""
      position: absolute
      top: 50%
      left: 50%
      width: 18%
      height: auto
      transform: translate(-50%, -50%)
      transition: .3s transform ease-in-out

  &__title
    margin: 16px 0

    +screen-max(768px)
      font-size: 18px

  &-title__line
    display: block

    +screen-max(1024px)
      display: inline

  &__text
    font-size: 18px

    +screen-max(768px)
      font-size: 16px

  &__separator
    margin: 24px 0
    border-top: 1px solid $theme-grey-color
    opacity: 0.2

    +screen-max(768px)
      margin: 20px 0

  &__link
    font-weight: 600
    font-size: 15px
    line-height: 2.4
    text-decoration: none
    letter-spacing: 0.05em
    text-transform: uppercase

    &.lowercase
      text-transform: none
      font-size: 18px

    &.chevron-right
      &::after
        margin-left: 11px

  &-quote
    display: flex
    align-items: center

    &__img-wrap
      min-width: 88px
      width: 88px
      height: 88px
      margin-right: 24px
      border-radius: 100%
      position: relative
      overflow: hidden

    &__img
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      width: 100%
      height: 100%
      object-fit: cover

    &__text
      padding-top: 8px
      font-style: italic
      margin-bottom: 4px

    &__quote-symb
      position: relative
      top: 2px
      left: -4px
      padding-right: 2px
      font-size: 40px
      font-weight: 700
      color: $blue-vivid-plan-color
      line-height: 0.01

    &__name
      margin-bottom: 0

.feature-label
  background: rgba(226, 235, 243, .3)
  padding: 100px 50px 100px 50px
  border-radius: 40px

  &__wrap
    column-gap: 50px 
    align-items: center
    
    .column
      flex: 1

    +screen-max(768px)
      flex-direction: column-reverse
      margin-bottom: 0

      .column
        margin-bottom: 24px
        width: 100%

  &--premium
    padding-top: 122px
    padding-bottom: 122px

    +screen-max(768px)
      padding: 24px 16px
      border-radius: 20px

  &__title
    margin-bottom: 24px
    text-align: center

    +screen-max(768px)
      margin-bottom: 12px
      font-size: 18px

  &__text
    margin-bottom: 0
    font-size: 24px
    text-align: center
    letter-spacing: -0.02em

    +screen-max(768px)
      font-size: 18px
