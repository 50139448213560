.check-list
  &__title
    font-size: 18px
    margin-bottom: 8px

    +screen-max(600px)
      font-size: 16px

  &__item
    display: flex
    align-items: center
    margin-bottom: 4px
    position: relative

    &:before
      content: ""
      width: 16px
      height: 16px
      margin-right: 8px
      background: url('~ic-drop-down-checkmark.svg') no-repeat center
      background-size: contain

  &__text
    font-size: 18px
    opacity: .7
    margin: 0 

    +screen-max(600px)
      font-size: 16px
