.splash
  &, & + .key-metrics-wrapper::before
    background-color: $bg-blue-color

  .call-to-action
    justify-content: left

  .container
    justify-content: space-between
    display: flex
    flex-direction: row

  .content
    flex-basis: 45%
    justify-content: center
    display: flex
    flex-direction: column

  .hero-wrapper
    display: flex
    flex-basis: 55%
    align-items: center
    justify-content: center
    padding: 25px

  .security-hero-wrapper
    display: flex
    flex-basis: 45%
    align-items: center
    justify-content: center
    padding: 25px

    .security-hero-image
      flex-grow: 1
      width: 100%

    +screen-min(769px)
      margin-top: -80px

  .hero-image
    flex-grow: 1

    img
      width: 100%

  h1
    font-family: 'Archivo', sans-serif
    font-style: normal
    font-weight: bold
    max-width: 500px

  p
    max-width: 660px
    margin-bottom: 35px

  .security-container
    padding: 40px 32px 0 32px

    .content
      flex-basis: 55%

      h1
        max-width: unset

    .security-clients-block
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      flex-wrap: nowrap
      gap: 4px
      margin: 56px 0

      svg
        +screen-max(350px)
          width: 53px

      +screen-min(769px)
        max-width: 410px

      +screen-max(768px)
        justify-content: center
        width: 100%

  .list-of-clients-block
    display: flex
    flex-direction: row
    margin: 56px 0
    gap: 10px

    +screen-max(768px)
      flex-direction: column
      gap: 30px

    .list-of-clients-title
      flex: 1

    .companies-list
      flex: 2
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      flex-wrap: wrap
      gap: 5px

      .companie-item
        flex: 1

        svg
          width: 100%
          height: auto
          // margin: auto

  +screen-max(768px)
    max-height: none
    height: auto

    background-color: $theme-light-color

    .call-to-action
      justify-content: center

    .container
      flex-direction: column-reverse

    .hero-wrapper
      padding: 25px 50px

    .content
      text-align: center

    h1
      margin: 0 auto 15px

  +screen-max(380px)
    p
      margin-bottom: 50px

  +screen-max(350px)
    p
      margin-bottom: 30px

  @media (max-height: 500px) and (orientation: landscape)
    height: unset
    max-height: unset

    .hero-image
      position: static

.landing-how-it-work-title
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 30px

.key-metrics-wrapper
  position: relative
  display: flex
  justify-content: center
  padding: 0 16px

  &::before, &::after
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 50%
    z-index: -1

  &::after
    bottom: 0
    background-color: #fff

  +screen-max(768px)
    padding-top: 40px
    background-color: #fff

.key-metrics-block
  display: flex
  flex-direction: row
  justify-content: center
  gap: 24px
  width: 1000px
  padding: 27px 24px
  background-color: #fff
  box-shadow: 0px 2px 8px rgba(40, 40, 40, 0.14)
  border-radius: 5px

  +screen-max(1000px)
    width: 100%
    left: 2.5%

  +screen-max(768px)
    position: static
    flex-direction: column
    left: 0
    margin: 0 auto
    height: 100%
    margin-bottom: 24px

  .key-metrics-item
    display: flex
    flex: 1
    flex-direction: column
    padding-left: 12px
    height: 100%

    .key-metrics-item-header
      font-family: 'Archivo', sans-serif

    &:nth-child(3n+1)
      border-left: 2px solid $theme-primary-color

      .key-metrics-item-header
        color: $theme-primary-color

    &:nth-child(3n+2)
      border-left: 2px solid #69318B

      .key-metrics-item-header
        color: #69318B

    &:nth-child(3n+3)
      border-left: 2px solid $accent-green-color

      .key-metrics-item-header
        color: $accent-green-color

    .key-metrics-item-title
      font-size: 3rem
      line-height: 1.2
      font-style: normal
      font-weight: 700

    .key-metrics-item-subtitle
      font-size: 1.5rem
