html,
body
  height: 100%

body
  width: 100%
  background: $theme-light-color

  &.scroll-disabled
    position: fixed
    overflow: hidden

div[tabindex]
  outline: 0

.main
  position: relative
  flex: 1
  min-height: calc(100vh - 88px)
  padding: 0
  box-sizing: border-box

.wrapper
  position: relative
  display: flex
  flex-direction: column

.container
  display: flex
  flex-direction: column
  min-width: 320px
  max-width: 1280px
  height: 100%
  padding: 0 32px
  margin: 0 auto

  +screen-max(700px)
    padding: 0 16px

  &.narrow
    max-width: 800px

hr
  margin: 10px 0
  border-color: $body-divider-color
  border-top: 0
