.list
  .description
    font-size: 24px
    text-align: center
  
  ul 
    max-width: 900px
    margin: 0 auto 40px

    li
      margin-bottom: 15px
      line-height: 28px

      &::before
        display: inline-block
        margin-right: 19px
        color: #3185b2
        font-size: 30px
        vertical-align: text-bottom
        font-weight: bold
        content: '\2022'
