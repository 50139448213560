.explainer
  padding: 72px 0

  &.sticky
    padding-bottom: 0

  &.light
    background-color: white

  .container
    flex-direction: row
    align-items: center
    justify-content: space-between

    &-center
      justify-content: space-around

      .explainer
        &-message
          margin: 0

    &-reversed
      flex-direction: row-reverse

      .explainer-message
        margin-right: 0
        margin-left: 50px

  &.explainer--integration
    &.dynamic
      .explainer-message
        z-index: 1

        &::before
          left: 80px
          top: 0
          z-index: -1
          
        &::after
          right: 80px

    .chevron-right
      font-size: 12px
      font-weight: 600
      text-transform: uppercase
      text-decoration: none
      opacity: .8

      &::after
        margin-left: 11px
        transform: translateY(5%)

  &-message
    max-width: 600px
    margin-right: 50px

    &.center
      text-align: center

  &-title
    margin-bottom: 20px
    font-size: 36px

  &-text
    margin: 0

  &-illustration
    display: flex
    justify-content: space-between

  &-box
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 0 10px
    min-width: 170px
    font-family: 'Archivo', sans-serif
    font-size: 14px
    font-weight: 500
    .explainer-icon
      position: relative
      padding: 30px
      background-color: #fff
      border-radius: 20px
      margin-bottom: 10px
      img
        display: block
      &::after
        position: absolute
        top: 50%
        right: -30px
        width: 12px
        height: 10px
        content: ''
        background-image: url('~double-arrow.svg')
        background-repeat: no-repeat
        background-size: 100% 100%
        transform: translateY(-50%)

    &:last-child .explainer-icon::after
      content: none

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

      .explainer-img
        &::after
          content: none

    .legacy &
      min-width: 130px
      .explainer-img
        margin-bottom: 0

  &-img
    margin-bottom: 10px
    position: relative

    .legacy &::after
      position: absolute
      top: 50%
      background-repeat: no-repeat
      background-size: 100% 100%
      transform: translateY(-50%)
      right: -10px
      width: 4px
      height: 7px
      content: ''
      background-image: url('~chevron.svg')
      filter: brightness(0) saturate(100%) invert(7%) sepia(29%) saturate(3015%) hue-rotate(216deg) brightness(92%) contrast(112%)

  &-description
    font-weight: 500

  &.dynamic
    overflow: hidden
    .explainer-message
      position: relative
      &::before, &::after
        position: absolute
        display: block
        content: ''
        background: #E2EBF3
        border-radius: 5px
      &::before
        width: 26px
        height: 26px
        left: -60px
        top: -20px
      &::after
        width: 30px
        height: 30px
        right: 220px
        bottom: -40px

    .explainer-illustration
      position: relative
      &::after
        position: absolute
        display: block
        content: ''
        width: 700px
        height: 500px
        left: 40px
        top: -75px
        z-index: -1
        background: rgba(226, 235, 243, .3)
        border-radius: 40px

      .explainer-image
        flex-grow: 1
        max-width: 100%

        img
          width: 100%

      &-picture
        &::after
          top: -30px
          left: 100px

    .container-reversed
      .explainer-illustration-picture
        &::after
          right: 100px
          left: auto

  &--video
    .container
      gap: 20px 60px

      +screen-max(1024px)
        flex-direction: column-reverse
      
      .explainer-message
        flex: 1

        +screen-max(580px)
          text-align: left
        
        &::before
          left: 26px
          top: -16px

        &::after
          right: 112px

      .chevron-right
        font-size: 12px
        font-weight: 600
        text-decoration: none
        text-transform: uppercase

        &::after
          margin-left: 11px

    .explainer-video
      position: relative
      padding-bottom: 68%
      overflow: hidden

      &__wrap
        flex: 1

        +screen-max(1024px)
          width: 100%
          max-width: 450px

      .explainer-video__video
        position: absolute
        top: 50%
        left: -1px
        right: -1px
        transform: translateY(-50%)
        width: calc(100% + 2px)

.explainer
  +screen-max(1024px)
    padding: 40px 0

    .container
      flex-direction: column

      &-reversed
        .explainer-message
          margin-left: 0

    &-message
      text-align: center
      margin-bottom: 24px
      margin-right: 0

    &.dynamic
      .explainer-illustration::after,
      .explainer-message::before,
      .explainer-message::after
        display: none


  +screen-max(768px)
    &-title
      font-size: 24px

    &-text
      font-size: 16px

    &-illustration
      flex-direction: column

    &-box
      margin: 0

      .explainer-icon
        &::after
          content: none

      .explainer-img
        img
          width: 171px

        &::after
          content: none

      &:last-child
        .explainer-description
          margin-bottom: 0

          &::after
            content: none

    &-description
      position: relative
      margin-bottom: 46px
      font-size: 16px
      font-weight: 700

      &::after
        position: absolute
        right: 50%
        bottom: -30px
        width: 5px
        height: 9px
        content: ''
        background-image: url('~chevron.svg')
        filter: brightness(0) saturate(100%) invert(7%) sepia(29%) saturate(3015%) hue-rotate(216deg) brightness(92%) contrast(112%)
        background-repeat: no-repeat
        background-size: 100% 100%
        transform: rotate(90deg)




