.help-center
  &.help-center--small
    .help-center__text
      font-size: 16px

    .help-center__img
      +screen-max(1023px)
        transform: translateY(0)

  &.help-center--small-text-block
    .help-center__content
      max-width: 50%

      +screen-max(1023px)
        max-width: 58%

      +screen-max(550px)
        max-width: 100%

  &.help-center--wide
    .help-center__wrapper
      max-width: 100%

    .help-center__content
      max-width: calc(100% - 500px)

      +screen-max(1023px)
        max-width: 100%

    .help-center__img
      max-width: 481px

      +screen-max(1023px)
        position: static
        max-width: 330px
        width: 80%
        order: -1
        margin: 0 auto
        transform: translateY(0)

  &.help-center--partner
    +screen-min(1024px)
      &.section
        padding-top: 110px
        padding-bottom: 110px

    .help-center__wrapper
      width: 100%
      max-width: 1070px

      .help-center__title
        +screen-max(1023px)
          text-align: center

      .help-center__content
        +screen-max(1023px)
          text-align: center

      .help-center__text
        margin-bottom: 0

      .help-center__img
        width: 50%

        +screen-max(1023px)
          width: 100%
          max-width: 450px
          padding-right: 0
          margin-top: 24px
          order: 1

  &__list
    display: flex
    flex-direction: column
    gap: 16px

  &-item
    &__title
      margin-bottom: 8px
      font-size: 16px
      font-weight: 600
      line-height: 1.5

    .help-center-item__text
      margin-bottom: 0
      font-size: 16px
      font-weight: 400
      line-height: 1.5

      a
        text-decoration: none
        color: $ui-primary-color

  &.section
    padding-top: 58px
    padding-bottom: 58px

    +screen-max(550px)
      padding-top: 32px
      padding-bottom: 32px

  &__wrapper
    max-width: 1072px
    margin: 0 auto
    position: relative

    +screen-max(1023px)
      display: flex
      flex-wrap: wrap

  &__title
    max-width: 50%
    margin-bottom: 20px

    +screen-max(1023px)
      max-width: 100%
      width: 100%

  &__content
    max-width: 58%

    +screen-max(550px)
      max-width: 100%

  &__text
    color: $text-tertiary-color

    .help-center__text--highlighted
      font-size: 24px
      color: $ui-primary-color
      font-weight: 700

  &__link
    margin-top: 20px

  &__img
    width: 100%
    max-width: 50%
    position: absolute
    top: 50%
    right: 0
    transform: translateY(-50%)

    +screen-max(1023px)
      position: static
      max-width: 42%
      transform: translateY(-28px)

    +screen-max(550px)
      max-width: 100%
      order: -1
      transform: none
      margin-bottom: 15px
      padding-right: 25px
