.steps-block
  &__content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    padding: 56px 104px
    padding-bottom: 0
    gap: 32px
    background-color: #FFFFFF
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 58.24%), linear-gradient(136deg, #FFFFFF -12.9%, rgba(255, 255, 255, 0) 54.15%), radial-gradient(60.64% 161.1% at -14.03% 158.35%, rgba(247, 247, 212, 0.3) 0%, rgba(246, 244, 191, 0) 100%), linear-gradient(72.3deg, rgba(255, 255, 255, 0.86) 0.09%, rgba(255, 255, 255, 0) 53.65%), linear-gradient(301.59deg, #B7EBFF 19.74%, #C8FDDB 101.77%)
    border: 1px solid $border-color
    border-radius: 32px
    flex: none
    order: 0
    align-self: stretch
    flex-grow: 0

    +screen-max(1400px)
      padding-right: 0

    +screen-max(1024px)
      padding: 56px 60px

    +screen-max(770px)
      padding: 32px 16px

  &__title
    color: $accent-blue-dark-color

  &__list
    padding-bottom: 56px
    display: flex
    flex-direction: column
    gap: 32px
    max-width: 50%

    +screen-max(1400px)
      max-width: 45%

    +screen-max(1024px)
      max-width: 100%
      padding-bottom: 0

  &__box
    width: 100%
    display: flex
    justify-content: space-between
    gap: 16px

  &__image
    margin-top: -55px
    display: flex

    +screen-max(1400px)
      margin-top: 0

    +screen-max(1024px)
      display: none

  &-item
    padding-left: 64px
    position: relative

    +screen-max(770px)
      padding-left: 52px

    &::before
      content: ""
      width: 1px
      height: calc(100% - 24px)
      position: absolute
      top: 60px
      left: 20px
      background-color: #C3CDD2

      +screen-max(1024px)
        top: 45px

      +screen-max(770px)
        top: 42px

    &:last-child
      &::before
        display: none

    &__title
      font-weight: 600
      font-size: 24px
      line-height: 1.33
      color: $accent-blue-dark-color

      +screen-max(770px)
        font-size: 20px

    &__text
      font-size: 14px
      line-height: 1.4
      color: $theme-grey-color

    &__num
      position: absolute
      left: 0
      top: 12px
      height: 40px
      width: 40px
      min-width: 40px
      display: flex
      justify-content: center
      align-items: center
      background-color: #FFFFFF
      border-radius: 100%
      border: 1px solid #C3CDD2
      font-weight: 500
      font-size: 20px
      line-height: 1.6
      text-align: center
      user-select: none

      +screen-max(1024px)
        top: -2px

      +screen-max(770px)
        top: -5px
