.data-filter-section__content--disabled-filter
  .metric-dimension-separator
    max-width: 1163px
    margin: 0 auto
    padding-right: 68px

    &::before
      right: 68px
  
.metric-dimension
  +screen-min(1023px)
    padding: 32px 68px 150px 0
    max-height: 560px

  .horizontal-carousel__controls
    display: none

    +screen-min(1023px)
      display: flex

  &-separator
    +screen-min(1023px)
      position: relative

      &::before
        content: ""
        position: absolute
        left: 0
        top: 0
        right: 0
        border-top: 1px solid $border-color
        z-index: 5

  &__wrapper
    position: relative

    +screen-min(1023px)
      &::before,
      &::after
        content: ""
        position: absolute
        left: 0
        right: 0

      &::before
        top: 0
        height: 40px
        background: linear-gradient(0deg, rgba(246, 250, 251, 0) 0%, rgba(246, 250, 251, 1) 100%)

      &::after
        content: ""
        position: absolute
        bottom: 0
        height: 185px
        background: linear-gradient(180deg, rgba(246, 250, 251, 0) 0%, rgba(246, 250, 251, 1) 100%)

    &.no-scroll
      .metric-dimension
        padding-bottom: 0

  &__list
    display: grid
    grid-template-columns: 1fr
    grid-gap: 10px

    +screen-min(650px)
      grid-template-columns: repeat(2, minmax(0, 1fr))
      gap: 16px 20px

    +screen-min(1200px)
      grid-template-columns: repeat(3, minmax(0, 1fr))
      max-width: 1095px
      margin: 0 auto

  &-item
    padding: 12px 16px
    display: flex
    justify-content: space-between
    align-items: center
    gap: 4px
    border: 1px solid $border-color
    border-radius: 12px
    background: #ffffff
    text-align: left

    &__title
      margin-bottom: 4px
      font-size: 16px
      font-weight: 600
      line-height: 1.5

    &__text
      font-size: 12px
      line-height: 1.3

    &__type
      color: $theme-grey-color
      font-size: 12px
      line-height: 1.3
      text-align: right
      min-width: 59px

  &__text
    margin-top: 32px
    font-family: 'Inter', sans-serif
    font-size: 28px
    font-weight: 600
    line-height: 1.4
    color: $accent-blue-dark-color
    text-align: center

    +screen-max(1022px)
      display: none
