.addon-card
  position: relative
  display: grid
  grid-template-columns: 40% 1fr
  border-width: 1px 1px 1px 5px
  border-style: solid
  box-shadow: 0px 2px 8px $box-shadow-color
  border-radius: 16px
  overflow: hidden

  +screen-max(1024px)
    grid-template-columns: 45% 1fr

  +screen-max(768px)
    display: flex
    flex-direction: column
    border-width: 5px 1px 1px 1px

  +screen-max(720px)
    border-width: 5px 0 0 0
    box-shadow: 0px 8px 16px rgba(0, 155, 205, 0.12)

  &--cyan
    border-color: $accent-cyan-color
    .addon-card__box
      &:last-child
        background: rgba(18, 186, 247, 0.04)
    .addon-card-list__item
      img
        filter: invert(42%) sepia(14%) saturate(6686%) hue-rotate(165deg) brightness(98%) contrast(101%)

  &--yellow
    border-color: $accent-yellow-color
    .addon-card__box
      &:last-child
        background: rgba(228, 156, 48, 0.04)
    .addon-card-list__item
      img
        filter: invert(78%) sepia(49%) saturate(2639%) hue-rotate(340deg) brightness(100%) contrast(102%)

  &--green
    border-color: $accent-green-color
    .addon-card__box
      &:last-child
        background: rgba(88, 169, 100, 0.08)
    .addon-card-list__item
      img
        filter: invert(57%) sepia(18%) saturate(1028%) hue-rotate(77deg) brightness(99%) contrast(90%)

  &__box
    padding: 32px 48px
    display: flex
    height: 100%
    flex-direction: column
    justify-content: center

    +screen-max(1024px)
      padding: 32px

    +screen-max(768px)
      padding: 32px 20px 24px

    &:last-child
      border-left: 1px solid $border-default-color

      +screen-max(1024px)
        padding: 78px 32px

      +screen-max(768px)
        padding: 24px 20px
        border-left: none
        border-top: 1px solid $border-default-color

  &__title
    font-size: 24px
    line-height: 1.5
    font-weight: 700

  &__desc
    margin-top: 16px
    font-size: 16px
    color: $text-tertiary-color

  .addon-card__link
    margin-top: 16px
    max-width: 256px

    +screen-min(768px)
      line-height: 32px
      height: 32px
      font-size: 11px
      border-radius: 8px

    +screen-max(768px)
      max-width: 100%

  &-list
    &__title
      margin-bottom: 12px
      font-size: 16px
      font-weight: 600

    &__list
      display: flex
      flex-direction: column
      gap: 12px

    &__item
      display: flex
      align-items: flex-start
      gap: 4px
      font-size: 16px
      line-height: 1.5

      img
        width: 24px
        min-width: 24px

  &-price
    position: absolute
    bottom: 24px
    right: 32px
    font-family: 'Archivo', sans-serif
    font-size: 16px
    line-height: 24px

    +screen-max(768px)
      margin-top: 24px
      position: static
      line-height: 1

    &__currency,
    &__num
      font-size: 48px
      font-weight: 600

      +screen-max(768px)
        font-size: 40px

