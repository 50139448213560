.tutorial-video
  background: #ffffff
  margin-top: 0
  padding-bottom: 56px

  @media screen and (max-width: 1024px)
    margin-top: 0

  .video-container
    display: flex
    align-items: center
    justify-content: space-between

    iframe
      border-radius: 8px
      width: 764px
      height: 430px

      @media screen and (max-width: 919px)
        width: 470px
        height: 264px

      @media screen and (max-width: 540px)
        width: 100%
