.trusted
  padding: 32px 0

  &__title
    margin-bottom: 16px
    font-size: 24px
    text-align: center

    +screen-max(768px)
      font-size: 16px

  .companies-list
    display: flex
    align-items: center
    flex-wrap: wrap
    justify-content: center
    gap: 0 30px

    +screen-max(768px)
      gap: 0

  .companie-item
    svg
      width: 120px
      height: auto

      +screen-max(768px)
        width: 90px
        height: auto
