.rating-customer-mark
  display: flex
  align-items: center

  &__img
    height: 24px

    +screen-max(580px)
      margin-bottom: 4px

  &__stars
    margin-left: 8px
    margin-right: 4px
    display: flex
    align-items: center
    gap: 2px

    img
      width: 20px
      height: 20px

  .rating-customer-mark__text
    margin-left: 4px
    margin-bottom: 0
    font-size: 12px
    letter-spacing: 0.05em

    &--grade
      font-size: 18px
      font-weight: 600
