.image-cards
  h2
    margin-bottom: 28px

    +screen-max(768px)
      margin-bottom: 16px

  .base-tabs
    margin-bottom: 40px

    +screen-max(768px)
      margin-bottom: 20px

  &__content
    margin-bottom: 72px

    +screen-max(768px)
      margin-bottom: 50px

  &__contact
    h3
      margin-bottom: 24px

      +screen-max(768px)
        margin-bottom: 16px

  .row
    display: grid
    grid-template-columns: repeat(3, 1fr)
    column-gap: 24px
    row-gap: 40px

    +screen-max(850px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(550px)
      grid-template-columns: repeat(1, 1fr)
      row-gap: 24px

  &-load-btn
    display: flex
    margin-top: 20px

    &--hidden
      display: none
     
.image-card
  display: flex
  flex-direction: column
  justify-content: flex-start

  &--excess
    display: none
      
  &--hidden
    display: none !important

  &__img
    position: relative
    padding-bottom: 57.32%
    border: 1px solid rgba(135, 156, 165, 0.3)
    border-radius: 5px
    overflow: hidden

    img
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover

  a
    font-size: 12px
    font-weight: 600
    text-decoration: none
    text-transform: uppercase

    &.chevron-right
      &::after
        margin-left: 11px
