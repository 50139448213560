.gradient-icon
  &--cyan,
  &--purple,
  &--red,
  &--green
    display: flex
    justify-content: center
    align-items: center

    img
      width: 100%

  &--cyan
    background: linear-gradient(303deg, #B2DEEA 0%, #F5FDFF 100%)

    img
      filter: invert(17%) sepia(12%) saturate(5986%) hue-rotate(163deg) brightness(99%) contrast(98%)

  &--purple
    background: linear-gradient(303deg, #DFC7EE 0%, #FBF5FF 100%)

    img
      filter: invert(22%) sepia(24%) saturate(4054%) hue-rotate(253deg) brightness(95%) contrast(92%)

  &--red
    background: linear-gradient(303deg, #FBCACA 0%, #FFECEC 100%)

    img
      filter: invert(15%) sepia(21%) saturate(6192%) hue-rotate(345deg) brightness(100%) contrast(96%)

  &--green
    background: linear-gradient(303deg, #BEEFC5 0%, #F8FFF9 100%)

    img
      filter: invert(32%) sepia(30%) saturate(644%) hue-rotate(77deg) brightness(97%) contrast(95%)
