.sign-up-section
  background: $theme-primary-color

  .container
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding-top: 80px
    padding-bottom: 80px
    gap: 40px 24px

    +screen-max(768px)
      flex-direction: column-reverse
      padding-top: 40px
      padding-bottom: 40px

  &__box--quote
    position: relative
    max-width: 700px

    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      display: block
      width: 221px
      height: 134px
      background: url(~quote-icon.svg) no-repeat center
      background-size: contain
      transform: translateX(-40%)
      opacity: .3

    +screen-max(768px)
      max-width: 100%

      &::before
        display: none

  &__quote
    height: 44px
    width: auto

    +screen-max(768px)
      height: 26px

  &__text
    position: relative
    color: #fff
    font-size: 32px
    font-style: italic
    margin-bottom: 24px
    z-index: 2

    +screen-max(1024px)
      font-size: 28px

    +screen-max(768px)
      font-size: 24px

  &-author
    display: flex
    align-items: center
    padding-top: 24px
    gap: 16px
    
    &__img-wrap
      position: relative

      &::before
        content: ""
        position: absolute
        display: flex
        left: 0
        right: 0
        width: 100%
        top: -24px
        border-top: 1px solid #fff
        opacity: .3

    &__img
      width: 80px
      height: 80px
      border-radius: 50%
      border: 1px solid #fff

    &__name
      margin-bottom: 8px
      color: #fff
      font-size: 22px
      font-weight: 500
      line-height: 1.2

      +screen-max(1024px)
        font-size: 20px

    &__position
      color: #fff
      font-size: 20px
      opacity: .8
      line-height: 1.2

      +screen-max(1024px)
        font-size: 16px

    