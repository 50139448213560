.destinations-carousel
  background: #ffffff
  margin-bottom: -10px
  .container
    overflow: hidden

  &.destinations-carousel--horizontal
    .destinations-carousel__list
      display: grid
      grid-template-columns: 1fr 1fr 1fr

      +screen-max(1023px)
        grid-template-columns: 1fr 1fr
      
      +screen-max(749px)
        grid-template-columns: 1fr

    .destinations-carousel__controls
      opacity: 0
      z-index: -1

  &__header
    margin-bottom: 32px
    display: flex
    align-items: center
    justify-content: space-between
    gap: 20px

    +screen-max(700px)
      margin-bottom: 24px

  &__title
    +screen-max(700px)
      text-align: center

  &__controls
    +screen-max(700px)
      display: none

  &__slider
    position: relative
    padding-bottom: 10px

    &::before
      content: ""
      position: absolute
      width: 112px
      top: 0
      right: -64px
      bottom: 0
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
      z-index: 2

      +screen-max(700px)
        width: 35px
        right: -16px

  &__list
    margin: -8px
    max-height: 318px

  .base-tabs
    position: relative
    padding-bottom: 40px
    margin: 0 -16px 32px -16px
    width: calc(100% + 32px)
    overflow: hidden

    +screen-max(700px)
      margin-bottom: 24px

      &::after
        content: ""
        position: absolute
        width: 35px
        top: 0
        right: 0
        bottom: 0
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
        z-index: 2

  .base-tab-list
    position: absolute
    left: 0
    right: 0
    top: 0
    padding: 0 16px
    margin: -0 -4px
    display: block
    white-space: nowrap
    overflow-y: auto
    z-index: 1

    &::-webkit-scrollbar
      display: none

    .base-tab
      padding: 0 4px
      display: inline-block

      &-content
        padding: 2px 16px
        font-size: 16px
        font-weight: 400
        color: $ui-primary-color
        border: 1px solid $ui-primary-color

      &-input
        &:checked + .base-tab-content
          background-color: $ui-primary-color
