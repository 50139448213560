.icon-cards
  &.icon-cards--tablet-one-column
    .icon-cards__row
      +screen-max(1024px)
        grid-template-columns: 1fr

      &__box
        +screen-max(1024px)
          display: flex

      &__icon
        +screen-max(1024px)
          width: 48px

  &.icon-cards--title-center
    .icon-cards__title
      text-align: center

  &.icon-cards--title-desktop-center
    +screen-min(551px)
      .icon-cards__title
        text-align: center

  &.icon-cards--columns
    &-2
      .icon-cards__row
        +screen-min(1025px)
          grid-template-columns: repeat(2, 1fr)
          
    &-3
      .icon-cards__row
        +screen-min(1025px)
          grid-template-columns: repeat(3, 1fr)

  &.icon-cards--title-dark
    .icon-cards__title
      color: $text-color

  &__title
    color: $accent-blue-dark-color

  &__text
    margin-top: 16px
    color: $text-tertiary-color
    font-size: 20px
    line-height: 1.6

  &__row
    margin-top: 40px
    display: grid
    flex-wrap: wrap
    gap: 24px
    grid-template-columns: repeat(4, 1fr)

    +screen-max(1024px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(768px)
      gap: 16px
      margin-top: 24px

    +screen-max(550px)
      grid-template-columns: 1fr

  &__action
    margin-top: 40px
    display: flex
    justify-content: center

    +screen-max(550px)
      margin-top: 24px
    