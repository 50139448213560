.usage-list-container
  padding-bottom: 60px

  .usage-list-wrapper
    display: grid
    grid-template-columns: 1fr

  .usage-list-title-wrapper
    padding: 60px 0 32px
    background: none

    &::before
      content: none

    .usage-list-title-nb
      white-space: none

  .usage-list-title-wrapper h2
    font-size: 24px
    line-height: 32px
    text-align: left

  .usage-list
    display: grid
    grid-template-columns: 1fr
    row-gap: 32px
    max-width: initial
    padding-top: 0
    list-style: none
    list-style-position: inside
    counter-reset: custom-counter

  .usage-list-item
    counter-increment: custom-counter

    &::before
      color: $theme-primary-color
      content: '0' counter(custom-counter) '.'

  .usage-list-item h3,
  .usage-list-item::before
    display: inline
    font-family: 'Archivo', sans-serif
    font-size: 18px
    font-weight: bold
    line-height: 24px
    letter-spacing: -0.02em

  .usage-list-item p
    margin: 12px 0 0
    font-size: 18px

  +screen-min(478px)
    padding-bottom: 116px

  +screen-min(768px)
    .usage-list-title-wrapper h2
      font-size: 36px
      line-height: 46px
      text-align: center

    .usage-list
      grid-template-columns: 1fr 1fr
      gap: 8% 40px
      max-width: 636px
      margin: 0 auto
  
  +screen-min(980px)
    .usage-list-wrapper
      grid-template-columns: 1fr 1.5fr
      column-gap: 5%
    
    .usage-list-title-wrapper
      position: relative
      height: max-content
      padding: 60px 32px 116px 0
      border-bottom-right-radius: 40px

      &::before
        position: absolute
        top: 0
        left: -9999px
        display: inline
        width: 9999px
        height: 100%
        content: ''

    .usage-list-title-wrapper, 
    .usage-list-title-wrapper::before
      background-color: rgba(226, 235, 243, 0.3)

    .usage-list-title-wrapper h2
      width: 100%
      max-width: 500px
      text-align: left

    .usage-list
      padding-top: 60px

  +screen-min(1120px)
    .usage-list-title-wrapper
      .usage-list-title-nb
        white-space: nowrap
