.customer-reviews
  background: #fff
  overflow: hidden

  .container
    position: relative
    padding-top: 65px
    padding-bottom: 56px

  &--bg
    .container
      &::before
        content: ""
        display: flex
        position: absolute
        width: 863px
        height: 277px
        left: 555px
        top: 0px
        background: #E2EBF3
        opacity: 0.3
        border-radius: 40px

  &__title
    margin-bottom: 24px
    font-size: 36px

  &__list
    display: flex
    gap: 9px 21px
    z-index: 2

    +screen-max(1024px)
      flex-direction: column

  .customer-review
    height: auto
    padding: 24px
    background: #fff
    box-shadow: 0px 4px 8px rgba(5, 117, 158, 0.1)
    border-radius: 5px
    flex: 1
    display: flex
    flex-direction: column
    justify-content: space-between

    +screen-max(1024px)
      box-shadow: 0px 2px 8px rgba(40, 40, 40, 0.14)

    &__text
      padding-top: 8px
      font-style: italic
      margin-bottom: 20px
      font-size: 18px

    &__quote
      position: relative
      top: 2px
      left: -4px
      padding-right: 2px
      font-size: 56px
      font-weight: 700
      color: $blue-vivid-plan-color
      line-height: 0.01

    &__box
      display: flex
      justify-content: space-between

      +screen-max(580px)
        flex-direction: column
        gap: 12px

    &-rating
      display: flex
      align-items: center

      +screen-max(580px)
        flex-direction: column
        gap: 4px
        align-items: flex-start

      &__img
        margin-right: 12px

        +screen-max(580px)
          margin-bottom: 4px

      &__stars
        display: flex
        align-items: center

      &__text
        margin-left: 4px
        margin-right: 4px
        margin-bottom: 0
        font-size: 12px
        letter-spacing: 0.05em

        &--grade
          font-size: 18px
          font-weight: 600

    &__name
      font-size: 18px
      font-weight: 500

    &__position
      font-size: 14px
