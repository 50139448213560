.ratings
  &.ratings--smaller
    padding-top: 48px
    padding-bottom: 48px

    +screen-max(550px)
      padding-top: 32px
      padding-bottom: 32px

  .container
    display: flex
    flex-direction: column
    gap: 32px

  &__title
    text-align: center
    margin-bottom: 0

  &__list
    display: flex
    align-items: center
    justify-content: center

    +screen-max(768px)
      flex-wrap: wrap
      gap: 15px

  &__actions
    display: flex
    justify-content: center
    align-items: center

    +screen-max(550px)
      display: none

  .rating
    display: flex
    flex-direction: column
    align-items: center
    flex: 1
    max-width: 164px
    box-sizing: border-box

    +screen-max(1024px)
      max-width: 125px

    +screen-max(768px)
      flex: 0 50%
      max-width: 45%

    .rating__img
      width: 100%

      +screen-max(768px)
        max-width: 177px

    &--reward
      max-width: 456px

      +screen-max(768px)
        max-width: 100%
        order: -1
        flex: 0 100%

      .rating__img
        width: 100%
        display: flex
        justify-content: center
        +screen-max(768px)
          max-width: 340px

    &-stars
      margin-bottom: 4px
      display: flex
      gap: 2px

      img
        width: 18px
        height: 18px

        +screen-max(1024px)
          width: 13px
          height: 13px

        +screen-max(768px)
          width: 18px
          height: 18px

    .rating-text
      font-size: 12px
      line-height: 1.5
      text-align: center
      letter-spacing: 0.05em

      +screen-max(1024px)
        font-size: 12px

      &__grade
        font-weight: 600
        font-size: 18px
        line-height: 1

        +screen-max(768px)
          font-size: 20px

  &-customers
    display: flex
    gap: 32px
    z-index: 2

    +screen-max(1024px)
        flex-direction: column

  .rating-customer
    height: auto
    flex: 1
    display: flex
    flex-direction: column
    justify-content: space-between

    +screen-max(1024px)
      &:last-child
        display: none
      
    &__text
      position: relative
      padding: 24px 32px
      margin-bottom: 23px
      font-size: 16px
      background: $theme-light-color
      border: 1px solid rgba(18, 186, 247, 0.12)
      border-radius: 24px

      &::before
        content: ""
        position: absolute
        width: 63px
        height: 20px
        left: 0
        bottom: 0
        background-image: url('~quote-triangle.svg')
        z-index: 2
        transform: translateY(100%)
        background-size: contain
        background-repeat: no-repeat

      b
        font-weight: 600

    &__quote
      position: absolute
      top: 11px
      left: 15px
      font-size: 40px
      line-height: 1
      font-weight: 600
      color: $blue-vivid-plan-color

    &__box
      display: grid
      grid-template-columns: 1fr auto
      gap: 4px

      +screen-max(580px)
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 8px

    &__name
      font-size: 16px
      font-weight: 500

    &__position
      font-size: 16px
