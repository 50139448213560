.integrations-list
  padding-top: 16px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 10px

  +screen-min(650px)
    grid-template-columns: repeat(2, minmax(0, 1fr))
    gap: 16px 20px

  +screen-min(1200px)
    padding-top: 30px
    border-top: 1px solid #E7EBEE
    grid-template-columns: repeat(3, minmax(0, 1fr))


.integrations-item
  background-color: #fff
  position: relative
  border: 1px solid $border-color
  border-radius: 12px

  &:hover
    border-color: $ui-primary-color
    background: $bg-blue-color

    .integrations-item-type__text
      color: $ui-primary-color

  &.hidden
    display: none

.integrations-item-wrapper
  position: relative
  padding: 4px 16px
  display: flex
  align-items: center
  text-decoration: none
  cursor: pointer
  height: 100%
  color: #000

.integrations-item-title
  margin: 0
  font-size: 16px
  font-weight: 600
  text-align: left
  flex: 1

.integrations-item-icon
  width: 40px
  height: 40px
  margin-right: 8px

  +screen-min(650px)
    width: 56px
    height: 56px

  img
    width: 100%

.integrations-item-type
  text-align: right
  margin-left: 12px
  position: relative

  &:hover
    .integrations-item-premium
      +screen-min(1023px)
        display: block

  &__text
    display: flex
    font-size: 11px
    font-weight: 400
    letter-spacing: -0.02em
    color: $theme-grey-color
    border-radius: 2px 2px 0 2px
    cursor: pointer

.integrations-item-premium
  position: absolute
  padding-bottom: 12px
  bottom: 16px
  right: -14px
  display: none
  cursor: default
  z-index: 2

  +screen-min(1400px)
    right: 50%
    transform: translateX(50%)

  &__content
    position: relative
    padding: 10px
    width: 201px
    flex-direction: column
    background: $theme-light-color
    text-align: center
    font-size: 13px
    line-height: 1.2
    border: none
    box-shadow: 0px 1px 8px 0px rgba(3, 60, 82, 0.12)
    border-radius: 5px

    &::before
      content: ""
      background: url('~popup-arrow.svg')
      width: 15px
      height: 6px
      position: absolute
      left: 79%
      bottom: -6px

      +screen-min(1400px)
        left: calc(50% - 10px)

  .button-link
    display: block
    color: $ui-primary-color
    margin-left: auto
    margin-right: auto
