@use "sass:math"

$ribbon-banner-right: 10px
$ribbon-banner-height: 22px

.hero-integrations-template
  background-image: url('~geometric-bg.svg')
  background-repeat: no-repeat
  background-position: right
  padding-top: 40px
  position: relative
  overflow: hidden

  .hero-template-breadcrumbs
    padding-left: 0

  &.home-page
    background-image: none

    .container 
      .hero-wrapper
        max-width: 536px

    .hero-integrations-template-text
      +screen-max(580px)
        text-align: center

    .call-to-action
      margin-bottom: 0
      gap: 26px

    .button
      padding-left: 12px
      padding-right: 12px

      &.lowercase
        text-transform: none
        font-size: 18px

  &.automate-marketing-reporting
    background-image: none

    .hero-integrations-template-title
      +screen-min(1024px)
        font-size: 56px

      +screen-max(580px)
        font-size: 32px
        text-align: center

  &.sources
    background-image: url('~geometric-bg-sources.svg')
    background-position: left

    .container 
      margin-top: -45px
      padding-top: 45px
      padding-bottom: 60px
      .hero-wrapper
        max-width: 374px   

      +screen-max(580px)
        .hero-integrations-template-title
          text-align: left

        .hero-integrations-template-text
          text-align: left

        .hero-image
          max-width: 180px
          margin-left: auto
          margin-right: auto
          display: block

  &.separate-benefits
    background-image: url('~geometric-bg-sources.svg')
    background-position: left top

    +screen-max(580px)
      background-image: none

    .container
      margin-top: -45px
      padding-top: 60px
      padding-bottom: 0

      .hero-wrapper
        +screen-max(1024px)
          margin-bottom: 12px

      .call-to-action
        margin-bottom: 32px
        gap: 8px 10px

        +screen-min(550px)
          flex-direction: row

      .hero-wrapper
        max-width: 495px

      .hero-integrations-template-title
        .highlighted
          +screen-max(580px)
            display: block

    .hero-integrations-template-benefits
      max-width: 1280px
      min-width: 320px
      padding: 0 32px
      justify-content: flex-start
      margin: 0 auto
      padding-bottom: 60px

      +screen-max(1024px)
        justify-content: center

  .container
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding-top: 40px
    padding-bottom: 40px

    &-center
      justify-content: space-around

      .hero-integrations-template
        &-message
          margin: 0

    &-reversed
      flex-direction: row-reverse

      .hero-integrations-template
        &-message
          margin-right: 0
          margin-left: 15px

    .hero-wrapper
      max-width: 448px
      width: 100%

      .hero-image
        flex-grow: 1
        width: 100%

  a
    text-decoration: none

    .hero-integrations-template-importer
      border: 1px solid transparent

    &:hover
      .hero-integrations-template-importer
        border: 1px solid $theme-primary-color
    
  &-message
    max-width: 661px
    margin-right: 15px

  &-title
    font-size: 48px

  &-text
    font-size: 18px

  &-trial
    &-box
      display: flex
      align-items: center 
    
    &-ico
      width: 24px
      height: 24px
      margin-right: 8px

    &-text
      opacity: .8
      margin-bottom: 0

  &-clients
    display: flex
    margin-top: 60px
    flex-wrap: wrap
    align-items: center

  &-client
    height: 64px
    width: auto

    img
      height: 100%

  .call-to-action
    justify-content: flex-start
    margin-bottom: 60px

  &-benefits
    display: flex
    align-items: center
    gap: 13px 48px

    +screen-max(580px)
      flex-direction: column
      align-items: flex-start

  &-benefit
    position: relative
    font-size: 18px
    padding-left: 28px

    &:before
      content: ""
      position: absolute
      left: 0
      top: 53%
      bottom: 0
      width: 20px
      height: 20px
      background: url('~ic-drop-down-checkmark-filled.svg') no-repeat center
      background-size: contain
      transform: translateY(-50%)

    span
      font-size: 20px
      font-weight: 600

  &-illustration
    display: flex
    align-items: center

    &--list
      +screen-max(1024px)
        flex-direction: column
        gap: 11px

        .hero-integrations-template-arrow
          transform: rotate(90deg)
          width: 10px

  &-arrow
    width: 10px
    height: 18px
    margin: 0 20px
    background-image: url('~chevron.svg')
    background-repeat: no-repeat
    background-size: contain

  &-importer
    position: relative
    display: flex
    padding: 30px
    background-color: #fff
    border-radius: 22px
    box-shadow: 5px 5px 66px #E1EBF1

    +screen-max(1200px)
      padding: 20px

      img
        width: 120px
        height: 120px
    
  &-importers
    display: flex
    flex-direction: column
    gap: 13px

    +screen-max(1024px)
      flex-direction: row

    +screen-max(550px)
      gap: 11px

    .hero-integrations-template-importer
      padding: 15px
      border-radius: 7px
      box-shadow: 0px 1px 6px rgba(2, 122, 164, 0.1)

      +screen-max(1200px)
        padding: 15px
        
        img
          width: 60px
          height: 60px

      +screen-max(550px)
        padding: 10px

        img
          width: 46px
          height: 46px

  .highlighted
    white-space: nowrap

  .integrations-label
    &-container
      position: absolute
      top: -9px
      right: -15px

      &--premium
        .integrations-label-ribbon
          background: #17B3E5

          &::after
            border-top-color: #13708D !important

    &-ribbon
      position: relative
      width: 125px
      height: $ribbon-banner-height
      background: #17B3E5
      color: #fff
      line-height: 1.2
      padding: 0 math.div($ribbon-banner-height, 4) * 3
      font-size: math.div($ribbon-banner-height, 4) * 3.1
      text-align: center
      border-radius: 4px 4px 0 4px

      &:after
        right: 0
        top: $ribbon-banner-height
        content: ''
        position: absolute
        width: 0
        height: 0
        border-top: 12px solid #13708D
        border-right: 5 + $ribbon-banner-right solid transparent

.hero-integrations-template
  +screen-max(1024px)
    background-image: none

    &.home-page
      .button
        padding-left: 76px
        padding-right: 76px

      .call-to-action
        margin-bottom: 34px

    .container
      padding-top: 10px
      padding-bottom: 20px
      flex-direction: column-reverse

      .hero-wrapper
        margin-bottom: 40px

    &-illustration
      margin-bottom: 40px

    &-importer
      padding: 30px

    &-message
      text-align: center
      margin-right: 0

    &-trial
      &-box
        display: none

    &-clients
      justify-content: center

    .call-to-action
      justify-content: center
      margin-bottom: 40px


    .integrations-label
      &-ribbon
        height: $ribbon-banner-height * 0.8
        width: 100px
        padding: 0 math.div($ribbon-banner-height, 4) * 2.7
        font-size: math.div($ribbon-banner-height, 4) * 2.7

        &:after
          top: $ribbon-banner-height * 0.8
          border-top: 10px solid #13708D

  +screen-max(768px)
    &-title
      font-size: 36px

    &-client
      height: 40px

  +screen-max(550px)
    &-message
      text-align: center
    &-importer
      padding: 15px
      border-radius: 12px
      img
        width: 90px
        height: 90px

    &-arrow
      width: 6px
      height: 11px
      margin: 0 7px

    &-title
      margin-bottom: 12px
      font-size: 24px

    &-text
      margin-bottom: 16px

    &-clients
      justify-content: flex-start

    .integrations-label
      &-container
        right: -12px
      &-ribbon
        height: $ribbon-banner-height * 0.7
        width: 70px
        padding: 0 math.div($ribbon-banner-height, 4) * 2.1
        font-size: math.div($ribbon-banner-height, 4) * 2.1
        border-radius: 3px 3px 0 3px

        &:after
          top: $ribbon-banner-height * 0.7
          border-top: 7px solid #165F21
          border-right: 2 + $ribbon-banner-right solid transparent
