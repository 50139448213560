.numbered-list
  &__item
    margin-bottom: 40px

    +screen-max(768px)
      margin-bottom: 16px
      
    &:last-child
      margin-bottom: 0

  &__title
    font-family: 'Archivo', sans-serif
    font-style: normal
    font-weight: bold
    margin-bottom: 10px

  &__text
    margin-bottom: 0

  
  &__section
    .column
      +screen-max(1024px)
        flex: 0 1 100%
        padding-left: 0
        padding-right: 0
        margin-bottom: 0

    .numbered-list__img
      padding: 0 60px
      position: relative
      max-width: 600px
      margin-left: auto
      margin-right: auto
      margin-bottom: 16px

      +screen-min(1025px)
        margin-bottom: 0

      img
        position: relative
        z-index: 2

        +screen-min(1025px)
          top: -35px

      +screen-min(1025px)
        margin-right: 50px

      &::before
        content: ""
        position: absolute
        left: 0
        right: 0
        top: 15%
        width: 100%
        padding-bottom: 60%
        background: rgba(226, 235, 243, .3)
        border-radius: 40px

        +screen-min(1025px)
          top: 10%
          padding-bottom: 77%

    .row-reverse
      .numbered-list__img
        +screen-min(1025px)
          margin-right: 0
          margin-left: 50px

      .numbered-list
        &__item
          &:last-child
            margin-bottom: 16px
