.destinations-section
  &__title
    margin-bottom: 40px

    +screen-max(768px)
      text-align: center
      margin-bottom: 24px

  &__list
    display: flex
    flex-wrap: wrap
    gap: 24px

    +screen-max(768px)
      gap: 16px
    
  .icon-card
    flex: 0 1 calc(33% - 16px)

    +screen-max(1023px)
      flex: 0 1 calc(50% - 12px)

    +screen-max(768px)
      flex: 0 1 100%

    &__box
      +screen-max(768px)
        display: flex

    &__icon
      +screen-max(768px)
        width: 48px
