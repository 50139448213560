.hero-platform
  &.section
    padding-top: 0
    padding-bottom: 0

  .container
    +screen-max(550px)
      padding-left: 0
      padding-right: 0

  &__box
    position: relative
    padding: 80px 32px 48px
    background: $bg-blue-color
    border: 1px solid rgba(18, 186, 247, 0.12)
    border-radius: 48px
    overflow: hidden

    +screen-max(550px)
      padding: 48px 32px
      border-radius: 0

    &::before
      content: ""
      position: absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      background-image: url('hero-platform-bg.jpg')
      background-repeat: no-repeat
      background-size: cover
      background-position: center bottom

      +screen-max(1024px)
        background-position: center 80px

      +screen-max(550px)
        background-image: url('hero-platform-bg-m.jpg')

  &__content
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    align-items: center
   
  &__title
    text-align: center
    margin-bottom: 12px

  &__text
    max-width: 860px
    text-align: center
    margin-bottom: 24px

  &__actions
    width: 100%
    display: flex
    justify-content: center

    +screen-max(550px)
      .button
        width: 100%

  &__touchpoints
    margin-top: 110px
    display: flex
    flex-wrap: wrap
    gap: 8px 16px

    +screen-max(1024px)
      margin-top: 70px

    +screen-max(550px)
      margin-top: 40px

  &-touchpoint
    position: relative
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    padding: 16px 16px 56px
    background: #FFFFFF
    border: 1px solid $border-color
    box-shadow: 0px 2px 8px $box-shadow-color
    border-radius: 24px
    color: $text-color
    text-align: center
    text-decoration: none

    +screen-max(1024px)
      flex: 0 calc(50% - 8px)

    +screen-max(550px)
      padding: 8px 8px 36px
      flex: 0 100%

    &::before
      content: ''
      position: absolute
      bottom: 16px
      left: 50%
      background: url('~icons/chevron-down.svg')
      background-repeat: no-repeat
      background-size: contain
      width: 32px
      height: 32px
      transform: translateX(-50%)
      transition: 0.25s ease-in-out

      +screen-max(550px)
        width: 24px
        height: 24px
        bottom: 8px

    &:hover
      color: $text-color
      border: 1px solid $ui-primary-color

    &--green
      &::before
        filter: invert(54%) sepia(42%) saturate(465%) hue-rotate(78deg) brightness(99%) contrast(90%)

      .hero-platform-touchpoint__title
        color: $accent-green-color

    &--red
      &::before
        filter: invert(58%) sepia(82%) saturate(3453%) hue-rotate(330deg) brightness(89%) contrast(94%)

      .hero-platform-touchpoint__title
        color: $accent-red-color

    &--cyan
      &::before
        filter: invert(76%) sepia(56%) saturate(4945%) hue-rotate(160deg) brightness(96%) contrast(102%)
      
      .hero-platform-touchpoint__title
        color: $accent-cyan-color

    &--purple
      &::before
        filter: invert(45%) sepia(71%) saturate(401%) hue-rotate(234deg) brightness(89%) contrast(95%)
      
      .hero-platform-touchpoint__title
        color: $accent-purple-color

    &__title
      margin-bottom: 8px
      font-size: 20px
      font-weight: 700

      +screen-max(550px)
        font-size: 16px
        font-weight: 600

    &__text
      font-size: 16px
      font-weight: 500
