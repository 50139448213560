.quote
  &.section
    padding-top: 40px

    +screen-max(1023px)
      padding-top: 32px
      padding-bottom: 32px

  &__content
    position: relative
    display: flex
    gap: 48px
    max-width: 1100px
    margin: 0 auto

  &__box--quote
    width: 100%

    &::before
      content: ""
      position: absolute
      top: 67px
      left: 32px
      display: block
      width: 200px
      height: 175px
      background: url(~quote-ico-bg.svg) no-repeat center
      background-size: contain

      +screen-max(1023px)
        display: none

  &__box--image
    +screen-max(1023px)
      display: none

  &__ico
    margin-bottom: 16px
    width: 32px
    height: 32px
    background: url(~quote-ico.svg) no-repeat center

  .quote__img
    img
      max-width: none
      max-height: 400px

  .quote__text
    font-family: 'Archivo', sans-serif
    position: relative
    color: $accent-blue-dark-color
    font-size: 32px
    line-height: 1.5
    font-weight: 400
    margin-bottom: 24px
    z-index: 2

    +screen-max(550px)
      line-height: 1.6
      font-size: 24px

    b
      font-weight: 600

    &--smaller
      font-size: 24px
      line-height: 1.6

      +screen-max(550px)
        font-size: 20px

  .quote__title
    color: $accent-blue-dark-color

    +screen-max(1023px)
      text-align: center

  &-author
    display: flex
    align-items: center
    gap: 16px

    &__img-wrap
      display: none

      +screen-max(1023px)
        display: block
        width: 122px

      +screen-max(550px)
        width: 72px

    &__img
      width: 100%

    &__name
      margin-bottom: 4px
      font-size: 20px
      font-weight: 600
      line-height: 1.2

      +screen-max(550px)
        font-size: 16px

    &__position
      font-size: 16px
      line-height: 1.5

  &__link
    margin-top: 24px
    padding: 12px 0
