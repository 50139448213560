.arrow-cards
  &.arrow-cards--without-arrows
    .arrow-cards__row
      +screen-max(1024px)
        flex-direction: column
        align-items: center
        
    .arrow-card
      max-width: 280px

      +screen-max(1024px)
        max-width: 100%
        flex: 0 1 100%

        .arrow-card__text
          text-align: center

    .arrow-card__img
      &::before
        display: none

      +screen-max(1024px)
        padding: 0 13.3%

  &.arrow-cards--smaller-padding
    padding-top: 40px
    padding-bottom: 40px

    +screen-max(550px)
      padding-top: 32px
      padding-bottom: 32px

  &.arrow-cards--v2
    +screen-max(1024px)
      .arrow-cards__row
        flex-direction: column
        align-items: center
        gap: 110px

    .arrow-card
      position: relative
      max-width: 100%

      +screen-max(1024px)
        max-width: 384px

      &:nth-child(2)
        .arrow-card__img
          &::before
            background-image: url(~card-arrow-straight.svg)

    .arrow-card__title
      margin-bottom: 4px
      text-align: center
      font-size: 28px
      font-weight: 700
      color: $ui-primary-color

      +screen-max(550px)
        font-size: 20px

    .arrow-card__text
      text-align: center
      font-size: 20px
      line-height: 1.6
      font-weight: 400

      +screen-max(550px)
        font-size: 16px

    .arrow-card__img
      margin-bottom: 0
      padding: 0 20px

      +screen-max(1024px)
        position: static

      +screen-max(550px)
        display: flex
        justify-content: center

        img
          max-width: 228px

      &::before
        width: 46px
        height: 33px
        right: -40px
        background-image: url(~card-arrow-straight.svg)

        +screen-max(1024px)
          display: block
          right: 50%
          top: calc(100% + 36px)
          transform: translate(50%, 0) rotate(90deg)

        +screen-max(550px)
          top: calc(100% + 45px)
          width: 33px
          height: 24px

  &__title
    text-align: center

    +screen-max(768px)
      margin-bottom: 8px
  
  &__text
    text-align: center
    margin: 16px auto 0
    max-width: 1000px
    color: $text-secondary-color

  &__row
    margin-top: 32px
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 32px

    +screen-max(1024px)
      padding: 0 88px

    +screen-max(768px)
      padding: 0 32px

    +screen-max(550px)
      padding: 0

  &__actions
    margin-top: 32px
    display: flex
    justify-content: center
    align-items: center
    gap: 26px 32px 

    +screen-max(550px)
      flex-direction: column

      .button
        width: 100%

  .arrow-card
    display: flex
    flex: 1
    flex-direction: column
    max-width: 344px

    +screen-max(1024px)
      flex: 0 1 calc(50% - 16px)

    +screen-max(550px)
      flex: 0 1 100%

    &:nth-child(2)
      .arrow-card__img
        &::before
          background-image: url(~card-arrow-upside.svg)

    &:last-child
      .arrow-card__img
        &::before
          display: none

    &--green
      .arrow-card__img
        img
          border: 1px solid rgba(88, 169, 100, 0.12)

      .arrow-card__title
        color: $accent-green-color

    &--pink
      .arrow-card__img
        img
          border: 1px solid rgba(222, 89, 70, 0.12)

      .arrow-card__title
        color: $accent-red-color

    &--blue
      .arrow-card__img
        img
          border: 1px solid rgba(18, 186, 247, 0.12)

      .arrow-card__title
        color: $accent-cyan-color

    &--purple
      .arrow-card__img
        img
          border: 1px solid rgba(159, 99, 195, 0.12)

      .arrow-card__title
        color: $accent-purple-color

    &__img
      position: relative
      margin-bottom: 16px

      +screen-max(550px)
        padding: 0 13.3%

      &::before
        content: ""
        position: absolute
        width: 72px
        height: 17px
        right: -52px
        top: 50%
        transform: translateY(-50%)
        background-image: url(~card-arrow.svg)
        background-size: contain
        background-repeat: no-repeat
        z-index: 2

        +screen-max(1024px)
          display: none

      img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 32px

        +screen-max(550px)
          border-radius: 20px

    &__title
      margin-bottom: 4px
      font-size: 16px
      font-weight: 500
      line-height: 1.5

      +screen-max(550px)
        text-align: center

    &__text
      font-weight: 600
      line-height: 1.4
      margin-bottom: 0

      +screen-max(768px)
        font-size: 20px

      +screen-max(550px)
        text-align: center

