.addon-cards
  &-section
    .container
      +screen-max(850px)
        padding: 0 16px

  &__title
    margin-bottom: 16px
    text-align: center
    color: $text-color-primary-blue

    +screen-max(768px)
      margin-bottom: 24px
  
  &__list
    display: flex
    flex-direction: column
    gap: 16px
    padding: 0 24px

    +screen-max(768px)
      gap: 24px

    +screen-max(550px)
      padding: 0
