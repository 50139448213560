.search
  flex: 1
  position: relative
  display: flex
  align-items: center
  position: relative

  &__ico
    position: absolute
    left: 16px
    top: 50%
    width: 24px
    height: 24px
    transform: translateY(-50%)
    cursor: text

  &__input
    border: 1px solid $border-color
    border-radius: 12px 0 0 12px
    height: 48px
    padding: 0 16px 0 48px
    flex: 1
    width: 100%
    
    &:hover
      border-color: $ui-primary-color

    &::placeholder
      font-weight: normal
      color: $theme-grey-color

    &:focus
      border-color: $ui-primary-color !important
      outline: none

    &::-ms-clear 
    &::-ms-reveal 
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration 
      display: none 
      width: 0 
      height: 0 
  
  &__submit
    padding: 12px 24px
    background: none
    border: none
    height: 48px
    color: #ffffff
    font-size: 14px
    font-weight: 600
    background: $ui-primary-color
    border-radius: 0px 12px 12px 0px
    cursor: pointer
    text-transform: uppercase

    &:hover
      color: #fff
      background-color: darken($ui-primary-color, 5%)
