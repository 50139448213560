.horizontal-carousel
  overflow: hidden

  &::-webkit-scrollbar
    display: none

  &__wrapper
    &.no-scroll
      &::before,
      &::after
        display: none        

  .horizontal-carousel__controls
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
    flex-direction: column
    z-index: 2

    &--hidden
      display: none

    .arrows-controller__item
      transform: rotate(90deg)
