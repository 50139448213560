.clients 
  width: 100%
  margin-left: auto
  margin-right: auto

  &.section
    padding-top: 40px
    padding-bottom: 40px

    +screen-max(550px)
      padding-top: 32px
      padding-bottom: 32px

  .container
    padding: 0

  &__title
    text-align: center
    margin-bottom: 32px
  
  &__slider
    position: relative
    width: 100%
    overflow: hidden

    +screen-min(1025px)
      margin-left: auto
      margin-right: auto

    &::before,
    &::after
      content: ''
      position: absolute
      top: 0
      bottom: 0
      width: 152px
      z-index: 2
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.13%)

      +screen-max(768px)
        width: 43px

      +screen-min(1025px)
        display: none

    &::before
      left: -35px

    &::after
      right: -35px
      transform: scale(-1, -1)

  &__list
    transition-timing-function: linear

    +screen-min(1025px)
      position: relative
      justify-content: center
      min-height: 64px

  .clients__box
    display: flex

    +screen-min(1025px)
      position: absolute
      top: 0
      left: 50%
      max-width: 900px
      gap: 64px
      transform: translateX(-50%)
      opacity: 0
      transition: opacity .5s ease

      &--active
        opacity: 1

  .clients__item 
    min-width: 192px
    width: 192px
    display: flex
    justify-content: center

    +screen-min(1025px)
      min-width: 128px
      width: 128px

    +screen-max(768px)
      min-width: 110px
      width: 110px

    img
      width: 100%
      max-width: 128px
      
      +screen-max(768px)
        min-width: 87px
        width: 87px

