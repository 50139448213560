.detailed-guide
  padding-bottom: 0
  &__title
    margin-bottom: 32px

  &__text
    margin-bottom: 24px

  &__link
    color: $text-color

  .detailed-guide-item
    padding-bottom: 24px

    &__title
      font-weight: 700
      font-size: 18px
      margin-bottom: 8px


    &__content
      margin-bottom: 24px
      ul
        list-style-type: disc
        padding-left: 24px
        margin-bottom: 24px

      &--after-img
        margin-top: 24px

    .detailed-guide-item__img
      display: block
      margin: 0 auto
      max-width: 600px
      width: 100%

      &--bigger
        max-width: 700px

      &--biggest
        max-width: 100%

    &__video
      display: flex
      justify-content: center

    iframe
      margin-left: auto
      margin-right: auto
      border-radius: 8px
      width: 600px
      height: 335px

      @media screen and (max-width: 919px)
        width: 470px
        height: 264px

      @media screen and (max-width: 540px)
        width: 100%
