.keep-data-safe
  &__content
    position: relative

  &__box
    width: 100%
    max-width: 50%

    +screen-max(1024px)
      text-align: center
      max-width: 100%

  &__images
    position: absolute
    right: 0
    top: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    gap: 12px 36px
    width: 624px
    max-width: 50%

    +screen-max(1024px)
      position: static
      margin-bottom: 32px
      width: 100%
      max-width: 100%

    +screen-max(550px)
      margin-bottom: 23px


  .container
    .keep-data-safe__image
      width: 100%
      max-width: 96px

      +screen-max(550px)
        max-width: 53px

  &__title
    margin-bottom: 16px

    +screen-max(550px)
      margin-bottom: 8px

  &__text
    margin-bottom: 28px

    +screen-max(1024px)
      margin-bottom: 32px

    +screen-max(550px)
      margin-bottom: 23px
