.signup
  width: 332px
  min-width: 332px
  padding: 32px 24px
  border-radius: 12px
  border: 1px solid rgba(18, 186, 247, 0.12)
  background:#FFF
  box-shadow: 0px 12px 16px 0px $box-shadow-color

  +screen-max(1024px)
    min-width: none
    max-width: 544px
    width: 100%

  &__content
    +screen-max(1024px)
      max-width: 400px
      margin-left: auto
      margin-right: auto

  &__title
    margin-bottom: 20px
    text-align: center
    color: $text-color
    font-weight: 700

  .button.medium
    height: 40px
    margin-bottom: 12px
    gap: 8px
    font-size: 11px

    &.google-signup
      background-color: #4285F4

    &.microsoft-signup
      background-color: #2D2E2E
  
  .signup__text
    margin-bottom: 0
    font-size: 10px
    text-align: center

    a
      text-decoration: none
      color: $ui-primary-color

