.sign-up-form
  max-width: 387px
  width: 100%
  padding: 32px 32px 25px
  background: #fff
  box-shadow: 0px 4px 16px rgba(2, 122, 164, 0.1)
  border-radius: 5px

  +screen-max(768px)
    max-width: 100%

  &__title
    margin-bottom: 24px
    text-align: center
    color: $theme-primary-color

  &__btn
    margin-bottom: 8px
    position: relative

    .button
      padding-left: 40px
      padding-right: 40px 
      width: 100%
      max-width: 100%

      +screen-max(380px)
        font-size: 10px
        padding-right: 15px

    &::before
      content: ""
      position: absolute
      width: 28px
      height: 28px
      left: 6px
      top: 50%
      transform: translateY(-50%)
      background-size: contain
      background-repeat: no-repeat
      z-index: 2

  .button-google
    .button
      background-color: #4285F4

    &::before
      background-image: url("~sign-up-icons/google.svg")

  .button-microsoft
    .button
      background-color: #2D2E2E

    &::before
      background-image: url("~sign-up-icons/microsoft.svg")

  .button-xero
    .button
      background-color: #1AB4D7

    &::before
      background-image: url("~sign-up-icons/xero.svg")

  &-separator
    margin-top: 16px
    margin-bottom: 16px
    display: flex
    align-items: center
    gap: 20px

    &__line
      height: 1px
      flex: 1
      display: block
      background: #879CA5
      opacity: .4

    &__text
      font-size: 14px
      color: #879CA5

  &__box
    margin-bottom: 8px

  &__input
    width: 100%
    padding: 10px 12px
    box-sizing: border-box
    margin-bottom: 16px
    border: 1px solid rgba(135, 156, 165, 0.3)
    border-radius: 5px

    &:focus
      border-color: $theme-primary-color

  &__text
    margin-top: 8px
    margin-bottom: 0
    font-size: 12px
    text-align: center

