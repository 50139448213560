.install
  picture
    text-align: center

    img
      width: 100%

  &.with-description
    align-items: center

    img
      width: 100%

    .description,
    .subtitle
      max-width: 725px

  +screen-max(768px)
    picture
      img
        width: auto
        max-height: 100vh
        height: auto

    .call-to-action
      margin-top: 35px

    &.with-description
      .call-to-action
        margin-top: 0
