.homepage-video
  position: relative
  display: flex
  justify-content: center
  cursor: pointer
  user-select: none
  
  &__video
    width: 100%
    max-width: 854px

  &__play
    display: none
    position: absolute
    top: 50%
    left: 53%
    width: 64px
    height: 64px
    justify-content: center
    align-items: center
    transform: translate(-53%, -53%)
    user-select: none

    &:hover
      img
        transform: scale(1.2)

    img
      width: 100%
      transition: transform 0.2s ease-in
