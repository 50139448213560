.contact-us
  min-height: calc(100vh - 86px)
  display: flex
  align-items: center

  &__wrapper
    display: flex
    gap: 32px
    justify-content: space-between

    +screen-max(1024px)
      flex-direction: column

  &__box
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center

    &--form
      max-width: 592px

      +screen-max(1024px)
        max-width: 100%

  &__title
    margin-bottom: 16px

    +screen-max(768px)
      font-size: 32px

  &__text
    margin-bottom: 16px
    font-size: 16px
    line-height: 1.5
    color: $text-tertiary-color

    a
      text-decoration: none
      color: $ui-primary-color

  &-services
    padding: 16px 0

    &__title
      margin-bottom: 24px
      font-size: 20px
      line-height: 1.4
      font-weight: 700

      +screen-max(768px)
        margin-bottom: 16px

    &__list
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 24px 32px

      +screen-max(768px)
        grid-template-columns: repeat(1, 1fr)
        gap: 4px

    &-item
      display: flex
      align-items: center
      gap: 16px

      +screen-max(768px)
        gap: 8px

      &__icon
        width: 56px
        min-width: 56px
        height: 56px
        border-radius: 14px

        +screen-max(768px)
          width: 40px
          min-width: 40px
          height: 40px
          border-radius: 8px

        img
          width: 28px
          height: 28px

          +screen-max(768px)
            width: 20px
            height: 20px

      &__text
        margin-bottom: 0
        font-size: 16px
        font-weight: 500

        +screen-max(768px)
          font-size: 14px
          font-weight: 400

  &-form
    padding: 40px 24px
    background: $theme-light-color
    border-radius: 16px
    
    +screen-max(768px)
      padding: 16px

    &__label
      margin-bottom: 8px
      padding: 8px 0
      font-weight: 500
      line-height: 1.5

    &__checkboxes
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 16px 24px

      +screen-max(768px)
        grid-template-columns: repeat(1, 1fr)

    &__submit
      margin-top: 40px

      .button
        width: 100%

    &__terms
      margin-top: 20px
      font-size: 12px
      text-align: center

      a
        text-decoration: none
        color: $ui-primary-color
