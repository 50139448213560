.flow
  .column
    flex-direction: row

    border-top: 1px solid $theme-primary-muted-color
    border-bottom: 1px solid $theme-primary-muted-color

    &:first-child
      border-left: 1px solid $theme-primary-muted-color
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px

    &:last-child
      border-right: 1px solid $theme-primary-muted-color
      border-top-right-radius: 5px
      border-bottom-right-radius: 5px

      .step
        padding-right: 25px

    .arrow-bottom
      display: none

    .arrow-right
      display: block

      width: 75px
      height: 100%

  .step
    padding: 25px 0 25px 25px

    flex-grow: 1

    h2
      font-size: 24px
      margin-bottom: 15px

    .progress
      display: flex
      margin-bottom: 15px

      &-icon
        opacity: 0.2
        margin-right: 10px

      &-icon-completed
        opacity: 1

  +screen-max(1024px)
    .column
      flex: 0 33.33%

      &:last-child
        .step
          padding-right: 15px

    .step
      padding: 15px 0 15px 15px

      h2
        font-size: 18px

  +screen-max(768px)
    .column
      flex: 0 100%
      flex-direction: column

      margin: 0
      border: 0

      border-left: 1px solid $theme-primary-muted-color
      border-right: 1px solid $theme-primary-muted-color

      &:first-child
        border-top: 1px solid $theme-primary-muted-color
        border-radius: 5px 5px 0 0

      &:last-child
        border-bottom: 1px solid $theme-primary-muted-color
        border-radius: 0 0 5px 5px

      .arrow-bottom
        display: block

        width: 100%
        height: 36px

      .arrow-right
        display: none

      .step
        padding: 25px 15px 0 15px

        .progress
          &-icon
            height: 36px
            width: 36px
