.cta
  &.cta--small
    .cta__wrapper
      padding-top: 32px
      padding-bottom: 32px

      &::before
        top: -102px

    .cta__title
      margin-bottom: 16px
      font-size: 28px

  &.cta--mt
    margin-top: 40px

  &.cta--horizontal
    &.section
      padding-top: 32px

    .cta__wrapper
      padding: 56px

      +screen-max(1023px)
        padding: 32px 16px

    .cta__content
      max-width: 100%
      width: 100%
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      gap: 16px 32px

      +screen-max(1023px)
        flex-direction: column

    .cta__actions
      width: auto

      +screen-max(550px)
        width: 100%

    .cta__title
      margin-bottom: 0
      text-align: left

      +screen-max(1023px)
        text-align: center

  &.section
    padding-top: 0
    padding-bottom: 40px

    +screen-max(550px)
      padding-bottom: 32px

  .container
    +screen-max(550px)
      padding-left: 0
      padding-right: 0

  &__wrapper
    position: relative
    overflow: hidden
    padding: 56px 16px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: linear-gradient(273.89deg, #097219 0%, #64B36F 100%)
    border: 1px solid $accent-green-color
    border-radius: 40px

    +screen-max(550px)
      border-radius: 0

    &::before
      content: ""
      position: absolute
      top: -69px
      right: -334px
      width: 1326px
      height: 1326px
      background-image: url('~cta-bg.svg')
      background-size: contain
      background-repeat: no-repeat

      +screen-max(1024px)
        right: -580px

      +screen-max(550px)
        width: 726px
        height: 726px
        top: -20px
        right: -305px

  &__content
    position: relative
    max-width: 766px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    z-index: 2

  &__logo
    margin-bottom: 24px
    width: 56px
    height: 56px

    +screen-max(550px)
      width: 40px
      height: 40px
  
  &__title
    color: #ffffff
    text-align: center
    margin-bottom: 24px

    &-ico
      img
        width: 40px
        height: 40px
        transform: translateY(8%)

        +screen-max(550px)
          width: 30px
          height: 30px

    +screen-max(1024px)
      br
        display: none

    +screen-max(550px)
      br
        display: block

  &__actions
    display: flex
    justify-content: center
    gap: 10px 16px
    width: 100%

    +screen-max(550px)
      flex-direction: column

      .button
        width: 100%
