.plan-simple-cards
  .container
    width: 100%

  &__title
    max-width: 833px
    margin: 0 auto 40px
    font-size: 48px
    text-align: center

    +screen-max(1024px)
      font-size: 36px

    +screen-max(768px)
      margin-bottom: 32px
      font-size: 28px

  &.entry 
    .interval-toggle-switch
      +screen-max(768px)
        margin-bottom: 12px

  .plans-grid
    width: 100%
    grid-template-columns: repeat(3, 1fr)
    margin-bottom: 0

    +screen-max(1024px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(768px)
      grid-template-columns: repeat(1, 1fr)
      gap: 16px

  .most-popular
    +screen-max(768px)
      margin-top: 26px

  .pricing
    margin-bottom: 12px

  .plan-table
    width: 100%
    border-collapse: collapse
    margin-bottom: 40px

    &__row
      border-bottom: 1px solid rgba(135, 156, 165, 0.12)

      &:last-child
        border: none

      &--support
        height: 88px

    &__sources
      max-width: 232px
      margin-left: auto

    &-sources__title
      margin-bottom: 8px

    .pricing-table-cell__info

      img
        margin-left: 8px

      span
        text-align: left
        font-weight: 400

    &__column
      padding: 12px 0
      vertical-align: top

      &:last-child
        font-weight: 500
        text-align: right

