.plan-card
  border-radius: 16px
  background: #ffffff
  box-shadow: 0px 8px 16px 0px $box-shadow-color
  position: relative
  display: flex
  flex-direction: column
  border-top: 5px solid $lilac-plan-color

  +screen-min(721px)
    border: 1px solid $lilac-plan-color
    border-top: 5px solid $lilac-plan-color

  &.custom
    grid-column: 1 / -1

    .plan-card__content
      justify-content: space-between

    .plan-card__desc
      +screen-max(640px)
        display: block

  .plan-card__content
    position: relative
    padding: 32px 20px 20px
    display: flex
    flex-direction: column
    flex: 1
    overflow: hidden
    z-index: 1

    &::before
      content: ""
      z-index: -1
      position: absolute
      top: 0
      right: 0
      width: 166px
      height: 144px
      border-radius: 166px
      background: linear-gradient(315deg, #DFC7EE 0%, #FBF5FF 100%)
      filter: blur(75px)
      transform: translate3d(0, 0, 0)

  .plan-card__name
    margin-bottom: 4px
    font-size: 18px
    font-weight: 600
    color: $lilac-plan-color

  .plan-card__price
    margin-bottom: 24px
    display: flex
    flex-direction: column
    font-size: 16px
    line-height: 1.75
    color: $text-tertiary-color
    font-weight: 400

    strong
      color: $text-color
      font-family: 'Archivo', sans-serif
      font-size: 40px
      line-height: 1.2
      font-weight: 600

  .plan-card__desc
    flex: 1
    margin-bottom: 24px
    font-size: 16px

    +screen-max(640px)
      display: none

  .plan-card__list
    margin-bottom: 24px

  .plan-card-item
    padding: 8px 0
    display: flex
    justify-content: space-between
    gap: 16px
    border-bottom: 1px solid $border-color
    font-size: 16px
    line-height: 1.5

    &-key
      font-weight: 600

    &-val
      text-align: right


  .plan-card__action
    +screen-max(640px)
      width: 100%

  .button-outlined
    background: transparent

  .table td
    padding: 12px 0
    vertical-align: top
    &:last-child
      font-weight: 500
      text-align: right

  &.most-popular
    &::before
      content: 'Most popular'
      position: absolute
      left: 0
      right: 0
      top: -16px
      font-size: 16px
      line-height: 1.5
      font-weight: 500
      padding: 4px 19px
      border-radius: 16px 16px 0 0
      color: #ffffff
      z-index: 2

  @each $plan-id, $plan-color in $billing-plan-colors
    &.#{$plan-id}
      border-top-color: $plan-color

      .plan-card__content
        @if $plan-id == 'custom'
          align-items: center
          gap: 48px
          flex-direction: row

          +screen-max(640px)
            gap: 0
            flex-direction: column

        &::before
          @if $plan-color == $dark-plan-color
             background: linear-gradient(303deg, #C5C5C5 0%, #FFFFFF 100%)

          @if $plan-color == $orange-plan-color
            background: linear-gradient(315deg, #EADAB2 0%, #FFF9EA 100%)

          @if $plan-color == $red-plan-color
            background: linear-gradient(315deg, #FBCACA 0%, #FFECEC 100%)

          @if $plan-color == $green-plan-color
            background: linear-gradient(315deg, #BEEFC5 0%, #F8FFF9 100%)

          @if $plan-color == $blue-vivid-plan-color
            background: linear-gradient(315deg, #B2DEEA 0%, #F5FDFF 100%)

      .plan-card__name
        color: $plan-color
      &::before
        background-color: $plan-color
      @if $plan-id == 'custom'
        .button
          width: 315px

          +screen-max(1280px)
            width: 200px

          +screen-max(640px)
            width: 100%
