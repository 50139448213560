.how-to
  &__title
    margin-bottom: 40px
    text-align: center

  &__list
    display: flex
    flex-wrap: wrap
    gap: 32px
    max-width: 1024px
    margin-left: auto
    margin-right: auto

    +screen-max(1023px)
      max-width: 500px

  &-item
    width: calc(50% - 16px)
    border-radius: 24px
    border: 1px solid rgba(18, 186, 247, 0.12)
    overflow: hidden

    +screen-max(1023px)
      width: 100%

    &__content
      height: 100%
      background: linear-gradient(315deg, #B2DEEA 0%, #F5FDFF 100%, #F5FDFF 100%)
      display: flex
      flex-direction: column

    &__img-box
      position: relative
      width: 100%
      z-index: 1
      padding-bottom: 50%

      &--small
        +screen-max(550px)
          padding-bottom: 73%

        .how-to-item__img
          top: 0
          left: 50%
          height: 100%
          width: auto
          transform: translateX(-50%)
          object-fit: cover

          +screen-max(550px)
            height: 118%


    &__img
      position: absolute
      width: calc(100% - 60px)
      left: 30px
      right: 30px
      top: 32px

      &--smaller
        top: 48px

    &__content-box
      flex: 1
      position: relative
      padding: 24px 16px
      border-top: 1px solid rgba(18, 186, 247, 0.12)
      background: rgba(255, 255, 255, 0.80)
      backdrop-filter: blur(5px)
      text-align: center
      z-index: 2

    &__num
      margin-bottom: 4px
      color: $ui-primary-color
      font-size: 16px
      font-weight: 500

    &__text
      margin: 0
      font-size: 18px
      font-weight: 600
      line-height: 1.3

